import { Component, Input, OnDestroy } from '@angular/core';
import { InternalUser, ReportTypes } from '@mya/models';
import { DateTimeService } from '../../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-reports-grid',
  templateUrl: './reports-grid.component.html',
  styleUrls: ['./reports-grid.component.scss'],
})
export class ReportsGridComponent implements OnDestroy {
  @Input() reportType: ReportTypes | null = null;
  @Input() dateRange: [Date, Date];
  @Input() mentor: string | null = null;
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate];
  }

  get ReportTypes() {
    return ReportTypes;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

export enum HistorySortOptions {
    AllNotes = 0,
    MyNotes = 1,
    SystemNotes = 2,
    ConsultantNotes = 3
}

export const HistorySortOptionsLookup: Record<HistorySortOptions, string> = {
    [HistorySortOptions.AllNotes]: "All Notes",
    [HistorySortOptions.MyNotes]: "My Notes",
    [HistorySortOptions.SystemNotes]: "System Notes",
    [HistorySortOptions.ConsultantNotes]: "Consultant Notes",
};


import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { GetPlanPricingsResult, PlanPricing } from '@mya/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanPricingService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private readonly planPricing = new BehaviorSubject<PlanPricing[]>([]);
  public readonly PlanPricing$: Observable<PlanPricing[]> = this.planPricing;

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.planPricing}api/planPricing`
  }

  public fetchPlanPricings() {
    this.subscriptions.push(this.http.get<GetPlanPricingsResult>(this.baseApiUrl)
      .subscribe((response) => {
        this.planPricing.next(response.planPricings);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

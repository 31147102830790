import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'mya-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'mentor';

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.body.insertAdjacentHTML("beforeend", `<style>:root {--widget-button-bg: #2962ff !important;--widget-button-text: #FFFFFF !important;}</style>`);
    }, 1000);
  }
}

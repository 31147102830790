import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CancelCurrentPlanByAccountRequest } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { Validators, FormBuilder } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { LoaderService } from '../../services/loader.service';
import { ModalConstant } from '../../common/constants/modal.constant';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-cancel-plan-modal',
  templateUrl: './cancel-plan-modal.component.html',
  styleUrls: ['./cancel-plan-modal.component.scss'],
})
export class CancelPlanModalComponent implements OnDestroy {
  @Input() accountId: string | null = null;
  @Output() reloadAccount = new EventEmitter();
  subscriptions: Subs[] = [];
  elementId = ModalConstant.CANCEL_PLAN_MODAL;

  cancelPlanForm = this.formBuilder.group({
    comments: [null as string | null, [Validators.required]],
    confirm: [false, [Validators.requiredTrue]],
  });

  constructor(private modalService: ModalService,
    private accountService: AccountService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder) { }

  confirmCancelPlan() {
    if (this.accountId) {
      const request: CancelCurrentPlanByAccountRequest = {
        accountId: this.accountId,
        cancelComments: this.cancelPlanForm.controls.comments.value as string
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.accountService.cancelCurrentPlanByAccount(request, loaderIdentifier).subscribe(() => {
        toastr.error('Plan Cancelled.');
        this.modalService.hide(ModalConstant.CANCEL_PLAN_MODAL);
        this.loaderService.hide(loaderIdentifier);
        this.reloadAccount.emit();
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

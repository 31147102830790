import { Injectable } from '@angular/core';
declare const bootstrap: any;

@Injectable({
  providedIn: 'root'
})
export class OffcanvasService {

  public initialize(elementId: string) {
    if (document.getElementById(elementId) && !bootstrap.Offcanvas.getInstance(document.getElementById(elementId)))
      new bootstrap.Offcanvas(document.getElementById(elementId));
  }

  public show(elementId: string) {
    const offcanvas = bootstrap.Offcanvas.getInstance(document.getElementById(elementId));
    offcanvas.show();
  }

  public hide(elementId: string) {
    const offcanvas = bootstrap.Offcanvas.getInstance(document.getElementById(elementId));
    offcanvas.hide();
  }
}

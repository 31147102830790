<div class="d-flex flex-wrap overflow-y-auto available-slots-container">
    <ng-container *ngFor="let availableSlot of availableSlots; trackBy: trackByFn">
        <input type="radio" class="btn-check" autocomplete="off" [id]="availableSlot" [value]="availableSlot"
            name="slot" (change)="valueChanged($event)" [checked]="selectedSlot?.getTime() === availableSlot.getTime()">
        <label class="btn btn-outline-primary rounded-pill font-medium m-1 position-relative" [for]="availableSlot">
            {{availableSlot | date:'h:mm'}}

            <ng-container *ngIf="lowestDate && availableSlot.isEqualDate(lowestDate) !== 0">
                <span
                    class="next-week-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                    +7
                </span>
            </ng-container>
        </label>
    </ng-container>
</div>
<form [formGroup]="scheduleBlockerForm" (ngSubmit)="saveChanges()">
    <div class="mya-block-scheduler">

        <div class="row overflow-y-auto mya-block-scheduler-container">
            <ng-container *ngIf="!readonly && weeklyInterval === 1">
                <div class="col-12 mb-2 align-self-center"
                    [ngClass]="{'text-danger': appointmentBlocks.length > blockSessionsAllowed}">
                    <h6>{{remainingBlocks}}</h6>
                </div>
            </ng-container>

            <div class="col-4 mb-2 align-self-center" [ngClass]="isTimeZoneSame ? 'd-none' : 'd-block'">
                <h4>Time zone</h4>
            </div>
            <div class="col-8 mb-2 text-center" [ngClass]="isTimeZoneSame ? 'd-none' : 'd-block'">
                <div class="form-check form-check-inline">
                    <input class="form-check-input primary check-outline outline-primary" type="radio" id="mentee"
                        name="timeZone" formControlName="timeZone" value="mentee"
                        [attr.disabled]="isUpdateMode ? '' : null">
                    <label class="form-check-label" for="mentee">Mentee</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input primary check-outline outline-primary" type="radio" id="mentor"
                        name="timeZone" formControlName="timeZone" value="mentor"
                        [attr.disabled]="isUpdateMode ? '' : null">
                    <label class="form-check-label" for="mentor">Mentor</label>
                </div>
            </div>
            <ng-container *ngIf="!readonly">

                <div class="col-12" *ngIf="selectedDay && selectedTime">
                    <div class="mb-3 box bg-info">
                        <div class="d-flex justify-content-between">
                            <h5 class="mb-0 capitalize">
                                {{selectedDay}}
                            </h5>
                            <h5 class="mb-0">
                                {{selectedTime}} ({{selectedTimezone}})
                            </h5>
                        </div>
                        <h5 class="mb-0 mt-2" *ngIf="weeklyInterval > 1 && selectedDate">
                            Start Date: {{selectedDate}}
                        </h5>
                    </div>
                </div>

                <ng-container *ngIf="weeklyInterval > 1">
                    <div class="col-12">
                        <h4>Select Starting Week</h4>
                    </div>
                    <div class="col-12 mb-3">
                        <mya-block-scheduler-date-range-picker [accountId]="accountId"
                            [utcTimeZoneDiff]="utcTimeZoneDiff"
                            formControlName="dateRange"></mya-block-scheduler-date-range-picker>
                    </div>
                </ng-container>


                <div class="col-3">
                    <h4>Day</h4>
                </div>
                <div class="col-9">
                    <div class="d-flex flex-row-reverse">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="availableOnly"
                                formControlName="availableOnly" />
                            <label class="form-check-label" for="availableOnly">Show
                                available
                                time
                                only</label>
                        </div>
                    </div>
                </div>

                <mya-block-scheduler-day-selector formControlName="day"
                    [availableSlots]="adjustedAvailableSlots"></mya-block-scheduler-day-selector>

                <div class="col-6 mt-3 mb-2">
                    <h4>Start Time</h4>
                </div>
                <div class="col-6 mt-3 mb-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input primary check-outline outline-primary" type="radio" id="am"
                            name="timePortion" formControlName="timePortion" value="am">
                        <label class="form-check-label" for="am">AM</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input primary check-outline outline-primary" type="radio" id="pm"
                            name="timePortion" formControlName="timePortion" value="pm">
                        <label class="form-check-label" for="pm">PM</label>
                    </div>
                </div>

                <div class="col-12">
                    <mya-block-scheduler-time-selector formControlName="appointmentBlock"
                        [availableSlots]="finalAvailableSlots"></mya-block-scheduler-time-selector>
                </div>
                <div class="col-12 mb-1" *ngIf="!isTimeZoneSame && convertedAppointmentTime">
                    <div class="mt-3 box bg-success">
                        <h5 class="mb-0">
                            {{scheduleBlockerForm.controls.timeZone.value === 'mentee' ? "Mentor's time:" :
                            "Mentee's time:"}}
                        </h5>
                        <span>{{convertedAppointmentTime}}</span>
                    </div>
                </div>
                <div class="col-4 my-2">
                    <button type="button" class="btn waves-effect waves-light btn-primary"
                        [disabled]="(blockSessionsAllowed - appointmentBlocks.length) <= 0 || selectedAppointmentBlock || !scheduleBlockerForm.valid"
                        (click)="addAppointmentBlock()">
                        Add Block
                    </button>
                </div>
                <div class="col-8 mt-3 mb-2 align-self-center text-end">
                    <h5 class="mb-0">{{subscription?.autoRenew === false ? 'Available Session(s): ' +
                        subscriptionTokens.length : ''}}</h5>
                </div>
            </ng-container>

            <div class="col-md-12 d-flex align-items-stretch flex-column my-2 overflow-y-auto">
                <ng-container *ngFor="let appointmentBlock of sortedAppointmentBlocks">
                    <mya-block-scheduler-appointment-block-card [appointmentBlock]="appointmentBlock"
                        [selectedAppointmentBlock]="selectedAppointmentBlock" [utcTimeZoneDiff]="utcTimeZoneDiff"
                        [readonly]="readonly" [mentee]="mentee" (edit)="editAppointmentBlock(appointmentBlock)"
                        (delete)="removeAppointmentBlock(appointmentBlock)"></mya-block-scheduler-appointment-block-card>
                </ng-container>

                <div class="card text-white w-100 my-1 bg-secondary" *ngIf="sortedAppointmentBlocks.length === 0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="card-title mb-0 single-line text-center">
                                    No records found
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="readonly">
                <div class="col-12 mt-auto d-grid gap-2 bottom-button-container">
                    <button type="button" class="btn waves-effect waves-light btn-info"
                        (click)="switchToEditMode.emit()">
                        Change</button>
                </div>
            </ng-container>
        </div>

        <div class="mt-auto">
            <ng-container *ngIf="!selectedAppointmentBlock; else editButtons">
                <div class="col-12 my-2">
                    <h4>Notify changes to</h4>
                </div>
                <div class="col-12 text-center mb-3">

                    <div class="form-check form-check-inline">
                        <input class="form-check-input primary check-outline outline-primary" type="checkbox"
                            id="menteeReceiveEmail" name="menteeReceiveEmail" formControlName="menteeReceiveEmail"
                            [value]="true"
                            [attr.disabled]="(scheduleBlockerForm.controls.menteeReceiveEmail.value === true && scheduleBlockerForm.controls.primaryUserReceiveEmail.value !== true) ? true : null">
                        <label class="form-check-label" for="menteeReceiveEmail">Mentee</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input primary check-outline outline-primary" type="checkbox"
                            id="primaryUserReceiveEmail" name="primaryUserReceiveEmail"
                            formControlName="primaryUserReceiveEmail" [value]="true"
                            [attr.disabled]="(scheduleBlockerForm.controls.primaryUserReceiveEmail.value === true && scheduleBlockerForm.controls.menteeReceiveEmail.value !== true) ? true : null">
                        <label class="form-check-label" for="primaryUserReceiveEmail">Parent / Guardian</label>
                    </div>

                </div>
                <div class="col-12">
                    <button type="submit" class="btn waves-effect waves-light btn-success w-100"
                        [disabled]="!readonly && (appointmentBlocks.length === 0 || appointmentBlocks.length !== blockSessionsAllowed)">Save
                        Changes</button>
                </div>
            </ng-container>
            <ng-template #editButtons>
                <div class="col-12 my-3">
                    <button type="button" class="btn waves-effect waves-light btn-primary w-100"
                        (click)="cancelEditMode()">Cancel</button>
                </div>
                <div class="col-12">
                    <button type="button" class="btn waves-effect waves-light btn-success w-100"
                        (click)="updateSelectedBlock()">Update</button>
                </div>
            </ng-template>

        </div>


    </div>
</form>
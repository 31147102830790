import { AngularEditorConfig } from "@kolkov/angular-editor";

export const defaultEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '6rem',
    minHeight: '5rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter notes here...',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Times New Roman',
    defaultFontSize: '4',
    fonts: [
        { class: 'times-new-roman', name: 'Times New Roman' },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        [
            'strikeThrough',
            'subscript',
            'superscript',
        ],
        [
            'customClasses',
            'link',
            'unlink',
            'insertImage',
            'insertVideo',
            'insertHorizontalRule',
        ]
    ]
};

export const readOnlyEditorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '100%',
    minHeight: '100%',
    maxHeight: '100%',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    fonts: [
        { class: 'arial', name: 'Arial' },
    ],
    sanitize: true
};

export const inlineReadOnlyEditorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: false,
    height: '21px',
    minHeight: '21px',
    maxHeight: '21px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    fonts: [
      { class: 'arial', name: 'Arial' },
    ],
    sanitize: true
  };
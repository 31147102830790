import { Component, Input, OnDestroy } from '@angular/core';
import { ModalConstant } from '../../common/constants/modal.constant';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { UpdateClientNotesUrlForAccountRequest } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-client-notes-url-modal',
  templateUrl: './client-notes-url-modal.component.html',
  styleUrls: ['./client-notes-url-modal.component.scss'],
})
export class ClientNotesUrlModalComponent implements OnDestroy {
  @Input() accountId: string | null = null;
  subscriptions: Subs[] = [];
  elementId = ModalConstant.CLIENT_NOTES_MODAL;
  isEdit = false;

  clientNotesForm = this.formBuilder.group({
    link: [null as null | string, [Validators.required]]
  });

  constructor(private accountService: AccountService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private formBuilder: FormBuilder) { }

  public showClientNotesModal(link: string | null) {
    this.isEdit = link != null;
    this.clientNotesForm.controls.link.setValue(link);
    this.modalService.show(ModalConstant.CLIENT_NOTES_MODAL);
  }

  onSubmit() {
    if (this.clientNotesForm.controls.link.value && this.accountId) {
      const request: UpdateClientNotesUrlForAccountRequest = {
        accountId: this.accountId,
        clientNotesUrl: this.clientNotesForm.controls.link.value
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.accountService.updateClientNotesUrl(request, loaderIdentifier).subscribe(data => {
        if (this.accountId)
          this.accountService.getAccount(this.accountId, true);
        this.loaderService.hide(loaderIdentifier);
        this.modalService.hide(ModalConstant.CLIENT_NOTES_MODAL);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AddCoachingAppointmentRequest, CoachingAppointmentInfo, CoachingHourTypes, CoachingHourTypesLookup, UpdateCoachingAppointmentRequest } from '@mya/models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CoachingService } from '../../services/coaching.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../services/loader.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-coaching-hours-editor',
  templateUrl: './coaching-hours-editor.component.html',
  styleUrls: ['./coaching-hours-editor.component.scss'],
})
export class CoachingHoursEditorComponent implements OnDestroy {
  @Output() refreshRecords = new EventEmitter();
  @Output() removeSelection = new EventEmitter();
  selectedRecord: CoachingAppointmentInfo | null = null;
  subscriptions: Subs[] = [];
  model: NgbDateStruct | null = null;
  time = { hour: 13, minute: 30 };
  modules = [1, 2, 3];
  coachingHourTypes = (Object.values(CoachingHourTypes).filter(value => typeof value === 'number') as CoachingHourTypes[])
    .sort((one, two) => (CoachingHourTypes[one] > CoachingHourTypes[two] ? 1 : -1));

  get lmps() {
    return this.form.controls.module.value == 1 ? [1, 2] : [1, 2, 3, 4, 5];
  }

  get CoachingHourTypesLookup() {
    return CoachingHourTypesLookup;
  }

  form = this.formBuilder.group({
    date: [null as null | NgbDateStruct, [Validators.required]],
    module: [1 as number | null, [Validators.required]],
    lmp: [1 as number | null, [Validators.required]],
    coachingType: [CoachingHourTypes.Min30, [Validators.required]],
    mitFirstName: [null as null | string, [Validators.required]],
    mitLastName: [null as null | string, [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder, private coachingService: CoachingService, private loaderService: LoaderService) { }

  public selectRecord(selectedRecord: CoachingAppointmentInfo | null) {
    this.selectedRecord = selectedRecord;
    if (selectedRecord) {
      const startTime = new Date(selectedRecord.date);
      this.form.patchValue({
        date: { year: startTime.getFullYear(), month: startTime.getMonth() + 1, day: startTime.getDate() },
        module: selectedRecord.module,
        lmp: selectedRecord.lmp,
        coachingType: selectedRecord.coachingHourTypeId,
        mitFirstName: selectedRecord.mitFirstName,
        mitLastName: selectedRecord.mitLastName,
      });
    } else {
      this.form.reset();
    }
  }

  moduleChanged() {
    if (!this.lmps.find(i => i == this.form.controls.lmp.value)) {
      this.form.controls.lmp.setValue(null);
    }
  }

  getMeetingDuration(coachingType: CoachingHourTypes) {
    switch (coachingType) {
      case CoachingHourTypes.Min30:
        return 30;
      case CoachingHourTypes.Min45:
        return 45;
    }
  }

  getDate(date: NgbDateStruct) {
    return new Date(date.year, date.month - 1, date.day, 0, 0, 0);
  }

  onSubmit() {
    if (!this.form.controls.coachingType.value || !this.form.controls.mitFirstName.value || !this.form.controls.mitLastName.value ||
      !this.form.controls.module.value || !this.form.controls.lmp.value || !this.form.controls.date.value)
      return;

    if (this.selectedRecord == null) {
      const date = this.getDate(this.form.controls.date.value);
      const request: AddCoachingAppointmentRequest = {
        coachingAppointment: {
          id: uuid(),
          coachingHourTypeId: parseInt(this.form.controls.coachingType.value.toString()) as CoachingHourTypes,
          mitFirstName: this.form.controls.mitFirstName.value,
          mitLastName: this.form.controls.mitLastName.value,
          date: new Date(date.getTime() - (date.getTimezoneOffset() * 60000)),
          module: this.form.controls.module.value,
          lmp: this.form.controls.lmp.value,
          mentorId: uuid(),
          mentor: null
        }
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.coachingService.addCoachingAppointment(request, loaderIdentifier)
        .subscribe(data => {
          toastr.success("Coaching Hours Saved");
          this.loaderService.hide(loaderIdentifier);
          this.refreshRecords.emit();
        }));
    } else {
      if (this.form.pristine && !this.form.touched) {
        this.removeSelection.emit();
        return;
      }

      const date = this.getDate(this.form.controls.date.value);
      const request: UpdateCoachingAppointmentRequest = {
        id: this.selectedRecord.id,
        coachingAppointment: {
          id: this.selectedRecord.id,
          coachingHourTypeId: parseInt(this.form.controls.coachingType.value.toString()) as CoachingHourTypes,
          mitFirstName: this.form.controls.mitFirstName.value,
          mitLastName: this.form.controls.mitLastName.value,
          date: new Date(date.getTime() - (date.getTimezoneOffset() * 60000)),
          module: this.form.controls.module.value,
          lmp: this.form.controls.lmp.value,
          mentorId: this.selectedRecord.mentorId,
          mentor: null
        }
      }
      const loaderIdentifier = uuid();
      this.subscriptions.push(this.coachingService.updateCoachingAppointment(request, loaderIdentifier)
        .subscribe(data => {
          toastr.success("Coaching Hours Updated");
          this.loaderService.hide(loaderIdentifier);
          this.refreshRecords.emit();
        }));
    }

  }

  closeEditMode() {
    this.removeSelection.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { Account, GetAccountByIdResult, GetConcernsByAccountResult, Concerns, CancelCurrentPlanByAccountRequest, UpdateDailyRoutineForAccountRequest, UpdateWwymDriveForAccountRequest, MentoringSystem, MentoringProgram, GetMentoringSystemsByAccountResult, GetMentoringProgramsByAccountResult, UpdateClientNotesUrlForAccountRequest } from '@mya/models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG } from "@mya/configuration";
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];
  private _accounts: Record<string, BehaviorSubject<Account | null>> = {};
  private readonly concerns = new BehaviorSubject<Concerns[] | null>(null);
  public readonly Concerns$: Observable<Concerns[] | null> = this.concerns;
  private readonly mentoringSystems = new BehaviorSubject<MentoringSystem[] | null>(null);
  public readonly MentoringSystems$: Observable<MentoringSystem[] | null> = this.mentoringSystems;
  private readonly mentoringPrograms = new BehaviorSubject<MentoringProgram[] | null>(null);
  public readonly MentoringPrograms$: Observable<MentoringProgram[] | null> = this.mentoringPrograms;
  
  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.account}api/account`
  }

  public getAccount(accountId: string, refresh: boolean = false): Observable<Account | null> {
    this.EnsureAccount(accountId, refresh);

    if (!this._accounts[accountId])
      this._accounts[accountId] = new BehaviorSubject<Account | null>(null);

    return this._accounts[accountId];
  }

  private EnsureAccount(accountId: string, refresh: boolean) {
    if (!refresh && this._accounts[accountId])
      return;

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.GetAccountById(accountId, loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);

      if (result == null || result.account == undefined)
        return;

      this._accounts[accountId].next(result.account);
    }));
  }

  private GetAccountById(accountId: string, loaderIdentifier: string): Observable<GetAccountByIdResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetAccountByIdResult>(`${this.baseApiUrl}/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getAccountConcerns(accountId: string) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetConcernsByAccountResult>(`${this.baseApiUrl}/get-account-concerns/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.concerns.next(data.concerns);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getAccountMentoringSystems(accountId: string) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetMentoringSystemsByAccountResult>(`${this.baseApiUrl}/get-account-mentoring-systems/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.mentoringSystems.next(data.mentoringSystems);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getAccountMentoringPrograms(accountId: string) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetMentoringProgramsByAccountResult>(`${this.baseApiUrl}/get-account-mentoring-programs/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.mentoringPrograms.next(data.mentoringPrograms);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  cancelCurrentPlanByAccount(request: CancelCurrentPlanByAccountRequest, loaderIdentifier: string) {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/cancel-current-plan-by-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  updateDailyRoutine(request: UpdateDailyRoutineForAccountRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<any>(`${this.baseApiUrl}/update-daily-routine-for-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  updateWwymDrive(request: UpdateWwymDriveForAccountRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<any>(`${this.baseApiUrl}/update-wwym-drive-for-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  updateClientNotesUrl(request: UpdateClientNotesUrlForAccountRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<any>(`${this.baseApiUrl}/update-client-notes-url-for-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<ng-container [ngSwitch]="reportType">

    <ng-container *ngSwitchCase="ReportTypes.AccountsPayable">
        <mya-report-accounts-payable [dateRange]="dateRange"></mya-report-accounts-payable>
    </ng-container>
    <ng-container *ngSwitchCase="ReportTypes.MenteeAppointmentCancellation">
        <mya-report-mentee-appointment-cancellation
            [dateRange]="dateRange"></mya-report-mentee-appointment-cancellation>
    </ng-container>
    <ng-container *ngSwitchCase="ReportTypes.MenteeAccountCancellation">
        <mya-report-mentee-account-cancellation
            [dateRange]="dateRange"></mya-report-mentee-account-cancellation>
    </ng-container>
    <ng-container *ngSwitchCase="ReportTypes.FailedPayment">
        <mya-report-failed-payment
            [dateRange]="dateRange"></mya-report-failed-payment>
    </ng-container>
    <ng-container *ngSwitchCase="ReportTypes.MentorPerformance">
        <mya-report-mentor-performance *ngIf="mentor"
            [dateRange]="dateRange" [mentor]="mentor"></mya-report-mentor-performance>
    </ng-container>
    <ng-container *ngSwitchCase="ReportTypes.ConsultationFlow">
        <mya-report-consultation-flow [dateRange]="dateRange"></mya-report-consultation-flow>
    </ng-container>
</ng-container>
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { AddCoachingAppointmentRequest, AddCoachingAppointmentResult, UpdateCoachingAppointmentRequest, UpdateCoachingAppointmentResult } from '@mya/models';
import { catchError, map, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { StringLengthRule } from 'devextreme/common';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class CoachingService {
  baseApiUrl = '';

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.coaching}api/coaching`
  }

  addCoachingAppointment(request: AddCoachingAppointmentRequest, loaderIdentifier: string): Observable<AddCoachingAppointmentResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post<AddCoachingAppointmentResult>(`${this.baseApiUrl}/add-coaching-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  updateCoachingAppointment(request: UpdateCoachingAppointmentRequest, loaderIdentifier: string): Observable<UpdateCoachingAppointmentResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.put<UpdateCoachingAppointmentResult>(`${this.baseApiUrl}/update-coaching-appointment/${request.id}`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  deleteCoachingAppointment(id: StringLengthRule) {
    return this.http.delete<any>(`${this.baseApiUrl}/delete-coaching-appointment/${id}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        throw error;
      }));
  }

  getCoachingAppointmentsByUser(params: HttpParams) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    return this.http.get(`${this.baseApiUrl}/get-coaching-appointments-by-user`, { params })
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }), map((result) => {
        this.loaderService.hide(loaderIdentifier);
        return result;
      }));
  }

}

export const ModalConstant = {
    DAILY_ROUTINE_MODEL: 'daily-routine-modal',
    EXTERNAL_USER_MODAL: 'external-user-modal',
    CANCEL_PLAN_MODAL: 'cancel-plan-modal',
    RESCHEDULE_APPOINTMENT_MODAL: 'reschedule-appointment-modal',
    CANCEL_APPOINTMENT_MODAL: 'cancel-appointment-modal',
    EDIT_APPOINTMENT_MODAL: 'edit-appointment-modal',
    WWYM_DRIVE_MODAL: 'wwym-drive-modal',
    CLIENT_NOTES_MODAL: 'client-notes-modal',
    JOIN_APPOINTMENT_MODAL: 'join-appointment-modal',
}
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { DayOfWeek, DayOfWeekLookup, Account, AppointmentBlock, PlanPricing, ReferenceItem, ReferenceDataTypes, TimeSlot, UpdateAppointmentBlocksByAccountRequest, Subscription, SubscriptionToken, getTimeZoneById, ExternalUser, InternalUser, getMentee, getAccountCreator, validateDateRangeRequired, TimeZoneTypes, TimePortionTypes, TimeZoneInfo, getTimeZoneAbbreviationById, getTimeZoneOffsetById } from '@mya/models';
import { ReferenceDataService } from '../../services/reference-data.service';
import { AccountService } from '../../services/account.service';
import { PlanPricingService } from '../../services/plan-pricing.service';
import { AppointmentService } from '../../services/appointment.service';
import { BookingService, BookingStaffMember } from '@mya/booking-shared';
import { v4 as uuid } from 'uuid';
import { SubscriptionService } from '../../services/subscription.service';
import { MentorService } from '../../services/mentor.service';
import { InternalUserService } from '../../services/internal-user.service';
import { BlockSchedulerDateRangePickerComponent } from '../shared/block-scheduler/block-scheduler-date-range-picker/block-scheduler-date-range-picker.component';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-block-scheduler',
  templateUrl: './block-scheduler.component.html',
  styleUrls: ['./block-scheduler.component.scss'],
})
export class BlockSchedulerComponent implements OnInit, OnDestroy {
  @ViewChild(BlockSchedulerDateRangePickerComponent) blockSchedulerDateRangePickerComponent!: BlockSchedulerDateRangePickerComponent;
  @Input() accountId: string | null = null;
  @Input() mentoringPlanId: string | null = null;
  @Input() subscription: Subscription | null = null;
  @Input() readonly = false;
  @Output() save = new EventEmitter<UpdateAppointmentBlocksByAccountRequest>();
  @Output() switchToEditMode = new EventEmitter();
  bookingBusinessHoursTimeZoneOffset = 0;
  bookingStaffMember: BookingStaffMember | null = null;
  businessHours: Record<number, TimeSlot[]> | null = null;
  defaultBlockDuration = 60;
  businessHourSlots: Date[] = [];
  openBusinessHourSlots: Date[] = [];
  overriddenDate: Date | null = null;
  planPricings: PlanPricing[] = [];
  timeZones: ReferenceItem[] = [];
  appointmentBlocks: AppointmentBlock[] = [];
  mentorAppointmentBlocks: AppointmentBlock[] = [];
  account: Account | null = null;
  accountCreator: ExternalUser | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  selectedAppointmentBlock: AppointmentBlock | null = null;
  subscriptionTokens: SubscriptionToken[] = [];
  currentTimeZoneInfo: TimeZoneInfo | null = null;
  subscriptions: Subs[] = [];

  get menteeUTCTimeZoneDiff() {
    return getTimeZoneOffsetById(this.timeZones, this.mentee?.timeZone ?? null) ?? 0;
  }

  get mentorUTCTimeZoneDiff() {
    return this.currentDate.getTimezoneOffset() * -1;
  }

  get utcTimeZoneDiff(): number {
    return this.scheduleBlockerForm.controls.timeZone.value === 'mentee' ? this.menteeUTCTimeZoneDiff : this.mentorUTCTimeZoneDiff;
  }

  get selectedDate() {
    return this.datePipe.transform(this.scheduleBlockerForm.controls.appointmentBlock.value, 'shortDate');
  }

  get selectedDay() {
    return this.scheduleBlockerForm.controls.appointmentBlock.value != null ? DayOfWeekLookup[this.scheduleBlockerForm.controls.appointmentBlock.value.getDay() as DayOfWeek] : null;
  }

  get selectedTime() {
    return this.datePipe.transform(this.scheduleBlockerForm.controls.appointmentBlock.value, 'shortTime');
  }

  get sortedAppointmentBlocks() {
    return this.appointmentBlocks.sort((a, b) => a.day - b.day);
  }

  get isTimeZoneSame(): boolean {
    return this.menteeUTCTimeZoneDiff == this.mentorUTCTimeZoneDiff;
  }

  get remainingBlocks() {
    return `${this.mentee?.firstName}'s plan includes ${this.blockSessionsAllowed == 1 ? `${this.blockSessionsAllowed} session` : `${this.blockSessionsAllowed} sessions`}  per week. Currently, ` + (this.appointmentBlocks.length > 0 ? `${this.appointmentBlocks.length} ${this.appointmentBlocks.length == 1 ? 'block' : 'blocks'} has been created.` : `no blocks out of the ${this.blockSessionsAllowed} have been created.`);
  }

  get isUpdateMode() {
    return this.selectedAppointmentBlock != null;
  }

  get weeklyInterval() {
    return (this.mentoringPlanId ?? null) === null ? 0 : this.planPricings.find(planPricing => planPricing.mentoringPlanId == this.mentoringPlanId)?.weeklyInterval ?? 0;
  }

  get blockSessionsAllowed() {
    return (this.mentoringPlanId ?? null) === null ? 0 : this.planPricings.find(planPricing => planPricing.mentoringPlanId == this.mentoringPlanId)?.sessionsPerWeek ?? 0;
  }

  get convertedAppointmentTime() {
    if (!this.scheduleBlockerForm.controls.appointmentBlock.value || this.currentTimeZoneInfo == null)
      return;

    let appointmentSlot = this.scheduleBlockerForm.controls.appointmentBlock.value.addMinutes(this.utcTimeZoneDiff * -1);
    appointmentSlot = this.scheduleBlockerForm.controls.timeZone.value === 'mentee' ? appointmentSlot.addMinutes(this.mentorUTCTimeZoneDiff) : appointmentSlot.addMinutes(this.menteeUTCTimeZoneDiff);
    return this.scheduleBlockerForm.controls.timeZone.value === 'mentee' ? `${this.datePipe.transform(appointmentSlot, 'shortTime')} | ${this.currentTimeZoneInfo.description}` : `${this.datePipe.transform(appointmentSlot, 'shortTime')} | ${getTimeZoneById(this.timeZones, this.mentee?.timeZone ?? null)}`;
  }

  get selectedTimezone() {
    if (this.currentTimeZoneInfo == null)
      return;

    const menteeTimeZone = getTimeZoneAbbreviationById(this.timeZones, this.mentee?.timeZone ?? null);
    return this.scheduleBlockerForm.controls.timeZone.value === 'mentee' ? menteeTimeZone : this.currentTimeZoneInfo.abbreviation;
  }

  get availableSlots() {
    return this.scheduleBlockerForm.controls.availableOnly.value == true ? this.businessHourSlots.filter(availableSlot => this.isSlotAvailable(availableSlot)) : this.openBusinessHourSlots;
  }

  get adjustedAvailableSlots() {
    return this.availableSlots.map(i => i.addMinutes(this.utcTimeZoneDiff));
  }

  get dayAvailableSlots() {
    return this.adjustedAvailableSlots.filter(i => i.getDay() == this.scheduleBlockerForm.controls.day.value);
  }

  get finalAvailableSlots() {
    return this.scheduleBlockerForm.controls.timePortion.value == 'am' ? this.dayAvailableSlots.filter(i => i.isAMTime()) : this.dayAvailableSlots.filter(i => i.isPMTime());
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  scheduleBlockerForm = this.formBuilder.group({
    timeZone: ['mentee' as TimeZoneTypes, [Validators.required]],
    dateRange: [[null, null] as [Date | null, Date | null], []],
    availableOnly: [true, []],
    day: [null as DayOfWeek | null, []],
    timePortion: ['am' as TimePortionTypes, []],
    appointmentBlock: [null as Date | null, []],
    primaryUserReceiveEmail: [null as boolean | null, [Validators.required]],
    menteeReceiveEmail: [null as boolean | null, [Validators.required]],
  });

  constructor(private datePipe: DatePipe,
    private accountService: AccountService,
    private planPricingService: PlanPricingService,
    private appointmentService: AppointmentService,
    private referenceDataService: ReferenceDataService,
    private bookingService: BookingService,
    private subscriptionService: SubscriptionService,
    private internalUserService: InternalUserService,
    private mentorService: MentorService,
    private formBuilder: FormBuilder,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.referenceDataService.TimeZoneInfo$.subscribe(timeZoneInfo => this.currentTimeZoneInfo = timeZoneInfo));
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
        this.bookingBusinessHoursTimeZoneOffset = (references[ReferenceDataTypes.BookingTimeZone] as ReferenceItem[])[0].additionalData["offset"];
        this.calculateOpenBusinessHours();
      }
    }));

    if (this.accountId) {
      this.subscriptions.push(this.accountService.getAccount(this.accountId).subscribe((account) => {
        this.account = account;
        this.mentee = getMentee(account);
        this.accountCreator = getAccountCreator(account);

        if (this.accountCreator)
          this.scheduleBlockerForm.controls.primaryUserReceiveEmail.setValue(this.accountCreator.receiveScheduleEmail ?? false);
        if (this.mentee)
          this.scheduleBlockerForm.controls.menteeReceiveEmail.setValue(this.mentee.receiveScheduleEmail ?? false);
      }));
    }

    if (this.subscription) {
      this.subscriptions.push(this.appointmentService.getAppointmentBlocksBySubscription(this.subscription.id, true).subscribe(appointmentBlocks => {
        this.appointmentBlocks = appointmentBlocks;
      }));

      if (!this.subscription.autoRenew) {
        this.subscriptionService.getSubscriptionTokensBySubscription(this.subscription.id as string);
        this.subscriptions.push(this.subscriptionService.SubscriptionTokens$.subscribe(subscriptionTokens => {
          this.subscriptionTokens = subscriptionTokens;
        }));
      }
    }

    if (!this.readonly) {
      this.scheduleBlockerForm.controls.availableOnly.setValidators(Validators.required);
      this.scheduleBlockerForm.controls.day.setValidators(Validators.required);
      this.scheduleBlockerForm.controls.timePortion.setValidators(Validators.required);
      this.scheduleBlockerForm.controls.appointmentBlock.setValidators(Validators.required);

      this.subscriptions.push(this.planPricingService.PlanPricing$.subscribe(planPricings => {
        this.planPricings = planPricings;
        if (this.weeklyInterval > 1) {
          this.scheduleBlockerForm.controls.dateRange.setValidators(validateDateRangeRequired);
        } else {
          this.scheduleBlockerForm.controls.dateRange.removeValidators(validateDateRangeRequired);
        }
      }));

      this.subscriptions.push(this.bookingService.CurrentStaffMember$.subscribe(bookingStaffMember => {
        this.bookingStaffMember = bookingStaffMember;
        this.calculateAvailableSlots(this.bookingStaffMember);
      }));

      this.subscriptions.push(this.internalUserService.Mentor$.subscribe(user => {
        this.mentor = user

        if (this.mentor?.id) {
          this.mentorService.getAppointmentBlocksByMentor(true);
        }
      }));

      this.subscriptions.push(this.mentorService.MentorAppointmentBlocks$.subscribe(appointmentBlocks => {
        this.mentorAppointmentBlocks = appointmentBlocks;
      }));

      this.subscriptions.push(this.scheduleBlockerForm.controls.timeZone.valueChanges.subscribe(() => {
        this.scheduleBlockerForm.controls.appointmentBlock.setValue(null);
        this.scheduleBlockerForm.controls.day.setValue(null);
      }));

      this.subscriptions.push(this.scheduleBlockerForm.controls.dateRange.valueChanges.subscribe(() => {
        this.calculateAvailableSlots(this.bookingStaffMember);
        if (this.isUpdateMode)
          return;

        this.scheduleBlockerForm.controls.appointmentBlock.setValue(null);
        this.scheduleBlockerForm.controls.day.setValue(null);
      }));

      this.subscriptions.push(this.scheduleBlockerForm.controls.availableOnly.valueChanges.subscribe(() => {
        if (this.isUpdateMode)
          return;

        this.scheduleBlockerForm.controls.appointmentBlock.setValue(null);
        this.scheduleBlockerForm.controls.day.setValue(null);
      }));

      this.subscriptions.push(this.scheduleBlockerForm.controls.day.valueChanges.subscribe(() => {
        if (this.isUpdateMode)
          return;

        this.scheduleBlockerForm.controls.appointmentBlock.setValue(null);
      }));

      this.subscriptions.push(this.scheduleBlockerForm.controls.timePortion.valueChanges.subscribe(() => {
        if (this.isUpdateMode)
          return;

        this.scheduleBlockerForm.controls.appointmentBlock.setValue(null);
      }));
    }
  }

  calculateAvailableSlots(bookingStaffMember: BookingStaffMember | null) {
    if (bookingStaffMember == null)
      return;

    const businessHourSlots: Date[] = [];
    const dateRange = this.getDateRange();
    let date = dateRange[0].addMinutes(this.bookingBusinessHoursTimeZoneOffset);
    const endDate = dateRange[1].addMinutes(this.bookingBusinessHoursTimeZoneOffset);

    while (date <= endDate) {
      const businessHour = bookingStaffMember.workingHours.find(i => i.day == date.getDay());
      if (businessHour && businessHour.timeSlots
        .findIndex(i => date >= date.generateDate(i.startTime.hour, i.startTime.minute, i.startTime.second) &&
          date.addMinutes(this.defaultBlockDuration) < date.generateDate(i.endTime.hour, i.endTime.minute, i.endTime.second)) != -1) {
        businessHourSlots.push(date);
      }

      date = date.addMinutes(15);
    }

    this.businessHourSlots = businessHourSlots.map(i => i.addMinutes(this.bookingBusinessHoursTimeZoneOffset * -1));
  }

  calculateOpenBusinessHours() {
    const businessHourSlots: Date[] = [];
    let currentDate = this.currentDate.getUTCFullDate();
    currentDate = currentDate.getNextHourDate();

    let date = currentDate.getUTCFullDate();
    date = date.addMinutes(this.bookingBusinessHoursTimeZoneOffset);

    let endDate = currentDate.getUTCFullDate();
    endDate = endDate.addDays(7).addSeconds(-1);
    endDate = endDate.addMinutes(this.bookingBusinessHoursTimeZoneOffset);

    while (date <= endDate) {
      businessHourSlots.push(date);
      date = date.addMinutes(15);
    }

    this.openBusinessHourSlots = businessHourSlots.map(i => i.addMinutes(this.bookingBusinessHoursTimeZoneOffset * -1));
  }

  isSlotAvailable(availableSlot: Date): boolean {
    return this.appointmentBlocks.findIndex(appointmentBlock => this.selectedAppointmentBlock?.id != appointmentBlock.id && this.isSlotWithinBlock(availableSlot, appointmentBlock)) == -1 &&
      this.mentorAppointmentBlocks.findIndex(appointmentBlock => appointmentBlock.accountId != this.accountId &&
        this.isSlotWithinBlock(availableSlot, appointmentBlock)) == -1;
  }

  isCurrentSubscriptionBlock(appointmentBlock: AppointmentBlock): boolean {
    return this.appointmentBlocks.findIndex(i => i.id == appointmentBlock.id) != -1;
  }

  isSlotWithinBlock(availableSlot: Date, appointmentBlock: AppointmentBlock): boolean {
    let result = true;

    const availableSlotStartTime = availableSlot;
    const availableSlotEndTime = availableSlot.addMinutes(this.defaultBlockDuration);
    const appointmentBlockStartTime = this.getStartTimeOfAppointmentBlock(availableSlot, appointmentBlock);
    const appointmentBlockEndTime = appointmentBlockStartTime.addMinutes(appointmentBlock.duration);

    result = availableSlotStartTime.getDay() == appointmentBlockStartTime.getDay()
      && (availableSlotEndTime.isEqualTime(appointmentBlockStartTime) == 1)
      && (availableSlotStartTime.isEqualTime(appointmentBlockEndTime) == -1);

    if (result && this.weeklyInterval > 1 && appointmentBlock.weeklyInterval > 1) {
      if (this.weeklyInterval == appointmentBlock.weeklyInterval && availableSlotStartTime.isEqualDate(appointmentBlockStartTime) == 0) {
        result = true;
      } else if (this.weeklyInterval == 3 && (appointmentBlock.weeklyInterval == 2 || appointmentBlock.weeklyInterval == 4)) {
        result = true;
      } else if ((this.weeklyInterval == 2 || this.weeklyInterval == 4) && appointmentBlock.weeklyInterval == 3) {
        result = true;
      } else if (this.weeklyInterval == 2 && appointmentBlock.weeklyInterval == 4 && (availableSlotStartTime.isEqualDate(appointmentBlockStartTime) == 0 || availableSlotStartTime.addDays(14).isEqualDate(appointmentBlockStartTime) == 0 || availableSlotStartTime.addDays(-14).isEqualDate(appointmentBlockStartTime) == 0)) {
        result = true;
      } else if (this.weeklyInterval == 4 && appointmentBlock.weeklyInterval == 2 && (availableSlotStartTime.isEqualDate(appointmentBlockStartTime) == 0 || availableSlotStartTime.isEqualDate(appointmentBlockStartTime.addDays(14)) == 0 || availableSlotStartTime.isEqualDate(appointmentBlockStartTime.addDays(-14)) == 0)) {
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  getStartTimeOfAppointmentBlock(availableSlot: Date, appointmentBlock: AppointmentBlock) {
    if (this.weeklyInterval > 1 && appointmentBlock.weeklyInterval > 1 && appointmentBlock.intervalStartDate) {
      let appointmentBlockStartDate = new Date(appointmentBlock.intervalStartDate)
        .generateDate(parseInt(appointmentBlock.startTime.split(":")[0]), parseInt(appointmentBlock.startTime.split(":")[1]), parseInt(appointmentBlock.startTime.split(":")[2]));

      if (appointmentBlockStartDate.isEqualDate(availableSlot) == 0) {
        return appointmentBlockStartDate;
      } else if (appointmentBlockStartDate.isEqualDate(availableSlot) < 0) {
        while (appointmentBlockStartDate.isEqualDate(availableSlot) < 0) {
          appointmentBlockStartDate = appointmentBlockStartDate.addDays(7 * appointmentBlock.weeklyInterval);
        }
      } else {
        while (appointmentBlockStartDate.isEqualDate(availableSlot) > 0) {
          appointmentBlockStartDate = appointmentBlockStartDate.addDays(-7 * appointmentBlock.weeklyInterval);
        }
      }

      return appointmentBlockStartDate;
    } else {
      let currentDate = this.currentDate.getUTCFullDate();
      let appointmentBlockDate: Date | null = null;

      while (appointmentBlockDate == null) {
        if (currentDate.getDay() as DayOfWeek == appointmentBlock.day) {
          appointmentBlockDate = currentDate;
        }

        currentDate = currentDate.addDays(1);
      }

      return appointmentBlockDate.generateDate(parseInt(appointmentBlock.startTime.split(":")[0]), parseInt(appointmentBlock.startTime.split(":")[1]), parseInt(appointmentBlock.startTime.split(":")[2]));
    }
  }

  getDateRange(): [Date, Date] {
    const dateRange = this.scheduleBlockerForm.controls.dateRange.value;
    if (dateRange != null && dateRange[0] != null && dateRange[1] != null) {
      return dateRange as [Date, Date];
    }

    let currentDate = this.currentDate.getUTCFullDate();
    let startDate: Date | null = null;

    while (startDate == null) {
      if (currentDate.getDay() as DayOfWeek == DayOfWeek.Monday) {
        startDate = currentDate;
      }

      currentDate = currentDate.addDays(1);
    }

    startDate = startDate.generateDate(0, 0, 0);

    return [startDate, startDate.addDays(7).addSeconds(-1)];
  }


  addAppointmentBlock() {
    if (!this.accountId || this.scheduleBlockerForm.controls.day.value == null || !this.scheduleBlockerForm.controls.appointmentBlock.value)
      return;

    const appointmentSlot = this.scheduleBlockerForm.controls.appointmentBlock.value.addMinutes(this.utcTimeZoneDiff * -1);
    this.appointmentBlocks.push({
      id: uuid(),
      accountId: this.accountId,
      subscriptionId: this.subscription ? this.subscription.id : '',
      duration: this.defaultBlockDuration,
      day: appointmentSlot.getDay(),
      startTime: `${this.datePipe.transform(appointmentSlot, 'HH:mm:ss') as string}`,
      placeholderEventId: null,
      weeklyInterval: this.weeklyInterval,
      intervalStartDate: this.weeklyInterval > 1 ? appointmentSlot : null
    });

    this.cancelEditMode();
  }

  editAppointmentBlock(appointmentBlock: AppointmentBlock) {
    this.selectedAppointmentBlock = appointmentBlock;

    if (this.selectedAppointmentBlock.intervalStartDate != null)
      this.blockSchedulerDateRangePickerComponent.trySetRange(this.selectedAppointmentBlock.intervalStartDate);

    const dateRange = this.getDateRange();
    let date = dateRange[0];
    const endDate = dateRange[1];
    let selectedDate: Date | null = null;

    while (date <= endDate) {
      if (date.getDay() == appointmentBlock.day &&
        date.getHours() == parseInt(this.selectedAppointmentBlock.startTime.split(":")[0]) &&
        date.getMinutes() == parseInt(this.selectedAppointmentBlock.startTime.split(":")[1]) &&
        date.getSeconds() == parseInt(this.selectedAppointmentBlock.startTime.split(":")[2])) {
        selectedDate = date;
        break;
      }

      date = date.addMinutes(15);
    }

    if (!selectedDate)
      return;

    selectedDate = selectedDate.addMinutes(this.utcTimeZoneDiff);
    this.scheduleBlockerForm.controls.day.setValue(selectedDate.getDay());
    this.scheduleBlockerForm.controls.timePortion.setValue(selectedDate.isAMTime() ? 'am' : 'pm');
    this.scheduleBlockerForm.controls.appointmentBlock.setValue(selectedDate);
  }

  removeAppointmentBlock(appointmentBlock: AppointmentBlock) {
    const index = this.appointmentBlocks.findIndex(i => i.id == appointmentBlock.id);

    if (index !== -1) {
      this.appointmentBlocks.splice(index, 1);
    }
  }

  cancelEditMode() {
    this.selectedAppointmentBlock = null;
    this.scheduleBlockerForm.controls.day.setValue(null);
    this.scheduleBlockerForm.controls.timePortion.setValue('am');
    this.scheduleBlockerForm.controls.appointmentBlock.setValue(null);
  }

  updateSelectedBlock() {
    if (this.scheduleBlockerForm.controls.day.value == null || !this.scheduleBlockerForm.controls.appointmentBlock.value || this.selectedAppointmentBlock == null)
      return;

    const appointmentSlot = this.scheduleBlockerForm.controls.appointmentBlock.value.addMinutes(this.utcTimeZoneDiff * -1);
    this.selectedAppointmentBlock.startTime = `${this.datePipe.transform(appointmentSlot, 'HH:mm:ss') as string}`;
    this.selectedAppointmentBlock.day = this.scheduleBlockerForm.controls.day.value;
    this.selectedAppointmentBlock.intervalStartDate = this.weeklyInterval > 1 ? appointmentSlot : null;

    this.cancelEditMode();
  }

  saveChanges() {
    if (this.accountId) {
      const request: UpdateAppointmentBlocksByAccountRequest = {
        subscription: null,
        accountId: this.accountId,
        appointmentBlocks: this.appointmentBlocks,
        primaryUserReceiveScheduleEmail: this.scheduleBlockerForm.controls.primaryUserReceiveEmail.value ?? false,
        menteeReceiveScheduleEmail: this.scheduleBlockerForm.controls.menteeReceiveEmail.value ?? false
      };

      this.save.emit(request);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="container-fluid h-100 pt-3">
    <div class="card mb-0">
        <div class="row">
            <div class="col-3 border-end pe-0">
                <div class="card-body border-bottom d-flex">
                    <h4 class="card-title mt-2 d-inline me-auto">Appointment Blocks</h4>
                    <button type="button" class="btn waves-effect waves-light btn-primary"
                        (click)="manageBlockSchedules()"
                        [disabled]="!(account?.accountStatus === AccountStatus.Active && activeSubscription && !isHourlyPlan)">
                        Manage
                    </button>
                </div>
                <div class="d-flex align-items-stretch flex-column my-2 overflow-y-auto scrollable-content p-3">
                    <ng-container *ngFor="let appointmentBlock of sortedAppointmentBlocks">
                        <mya-block-scheduler-appointment-block-card [appointmentBlock]="appointmentBlock"
                            [utcTimeZoneDiff]="mentorUTCTimeZoneDiff" [readonly]="true"
                            [mentee]="mentee"></mya-block-scheduler-appointment-block-card>
                    </ng-container>

                    <div class="card text-white w-100 my-1 bg-secondary" *ngIf="sortedAppointmentBlocks.length === 0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <h4 class="card-title mb-0 single-line text-center">
                                        No records found
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-9">

                <div class="card-body h-100 p-4">
                    <div class="col-md-12 d-flex flex-row-reverse mb-3 justify-content-between">
                        <button type="button" class="btn waves-effect waves-light btn-primary px-5" (click)="back()">
                            Back
                        </button>
                        <div class="text-white align-content-end">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input current" type="checkbox" id="current-check" value="true"
                                    [(ngModel)]="currentAppointments" (change)="currentAppointmentsChecked()">
                                <label class="form-check-label" for="current-check">{{mentee?.firstName}}'s
                                    Appointments</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input other" type="checkbox" id="other-check" value="true"
                                    [(ngModel)]="otherAppointments" (change)="otherAppointmentsChecked()">
                                <label class="form-check-label" for="other-check">Other Mentees Appointments</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input outlook" type="checkbox" id="outlook-check" value="true"
                                    [(ngModel)]="outlookEvents" (change)="outlookEventsChecked()">
                                <label class="form-check-label" for="outlook-check">Outlook Events</label>
                            </div>
                        </div>
                    </div>
                    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
                </div>
            </div>
        </div>
    </div>

</div>

<mya-reschedule-appointment-modal [selectedAppointmentId]="selectedAppointmentId" [serviceId]="serviceId"
    [bookingStaffId]="mentor?.bookingStaffId ?? null"></mya-reschedule-appointment-modal>

<mya-cancel-appointment-modal [appointmentCancelReason]="appointmentCancelReason"
    [isAppointmentRefundable]="isAppointmentRefundable"
    [selectedAppointmentId]="selectedAppointmentId"></mya-cancel-appointment-modal>

<mya-edit-appointment-modal [selectedBookingAppointmentId]="selectedBookingAppointmentId"
    [activeSubscription]="activeSubscription" [meetingUrl]="selectedMeetingUrl"></mya-edit-appointment-modal>

<mya-block-scheduler-modal [accountId]="accountId" [activeSubscription]="activeSubscription"
    (switchToEditMode)="switchToEditMode()"></mya-block-scheduler-modal>

<mya-change-plan-modal [accountId]="accountId" [activeSubscription]="activeSubscription"
    [pendingSubscription]="pendingSubscription"></mya-change-plan-modal>
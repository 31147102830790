import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CoachingService } from '../../services/coaching.service';
import { CoachingAppointmentInfo, CoachingHourTypes, CoachingHourTypesLookup, getDataSourceLoaderParams } from '@mya/models';
import { lastValueFrom } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent } from 'devextreme-angular';
import { CoachingHoursEditorComponent } from '../coaching-hours-editor/coaching-hours-editor.component';

@Component({
  selector: 'mya-coaching-hours',
  templateUrl: './coaching-hours.component.html',
  styleUrls: ['./coaching-hours.component.scss'],
})
export class CoachingHoursComponent implements OnInit {
  @ViewChild(DxDataGridComponent) DxDataGridComponent!: DxDataGridComponent;
  @ViewChild(CoachingHoursEditorComponent) CoachingHoursEditorComponent!: CoachingHoursEditorComponent;
  dataSource: any = {};
  applyFilterTypes: any;
  currentFilter: any;
  showFilterRow: boolean;
  showHeaderFilter: boolean;

  constructor(private datePipe: DatePipe,
    private coachingService: CoachingService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;
    this.showFilterRow = true;
    this.showHeaderFilter = true;
  }

  ngOnInit(): void {
    this.loadDataSource();
  }

  loadDataSource() {
    const coachingService = this.coachingService;
    this.dataSource = new CustomStore({
      key: 'id',
      load(loadOptions: any) {
        return lastValueFrom(coachingService.getCoachingAppointmentsByUser(getDataSourceLoaderParams(loadOptions)))
          .then((result: any) => ({
            data: result.coachingAppointments.data,
            totalCount: result.coachingAppointments.totalCount,
            summary: result.coachingAppointments.summary,
            groupCount: result.coachingAppointments.groupCount,
          }))
          .catch((error: any) => { throw error; });
      },
      remove: (key) => {
        return lastValueFrom(coachingService.deleteCoachingAppointment(key))
          .catch(() => { throw 'Deletion failed' });
      }
    });
  }

  fetchAppointmentDate = (data: any) => {
    return this.datePipe.transform(new Date(data.startTime), 'MM/dd/yy');
  }

  fetchCoachingHourType = (data: any) => {
    return CoachingHourTypesLookup[data.coachingHourTypeId as CoachingHourTypes];
  }

  selectionChanged(records: any[]) {
    const selectedRecord = this.DxDataGridComponent.instance.getSelectedRowsData()[0] as CoachingAppointmentInfo;

    if (selectedRecord) {
      this.CoachingHoursEditorComponent.selectRecord(selectedRecord);
    } else {
      this.CoachingHoursEditorComponent.selectRecord(null);
    }
  }

  removeSelection() {
    this.DxDataGridComponent.selectedRowKeys = [];
  }
}

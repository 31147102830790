<div class="input-group">
    <input class="form-control" name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" navigation="arrows" [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)" [dayTemplate]="t" outsideDays="hidden" [startDate]="calendarStartDate!"
        [minDate]="calendarStartDate!" [maxDate]="calendarEndDate!" tabindex="-1" readonly="true" [value]="value"
        (click)="toggleDatePicker()" [markDisabled]="isDisabled" />

    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="false" [class.range]="isRange(date)"
            [class.disabled]="isDisabled(date)" [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
            {{ date.day }}
        </span>
    </ng-template>
    <button [disabled]="disabled" class="btn btn-outline-secondary bi bi-calendar3" (click)="toggleDatePicker()"
        type="button"></button>
</div>
<form [formGroup]="notesForm" (ngSubmit)="saveNotes()">
    <div class="card mb-2 mya-notes-editor">
        <div class="card-body">

            <div class="row">

                <div class="col-6">
                    <div class="card-title mb-0">
                        <h4 class="mb-0">Mentee Notes</h4>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group mb-3">
                        <label class="mr-sm-2 d-none" for="notesType">Select</label>
                        <select class="form-select form-select-lg mr-sm-2" id="notesType" formControlName="notesType"
                            (change)="changeNotesType($event)">
                            <ng-container *ngFor="let historyType of historyTypes">
                                <option
                                    *ngIf="historyType !== HistoryTypeEnum.System && historyType !== HistoryTypeEnum.Consultant"
                                    [value]="historyType"
                                    [ngClass]="(historyType === HistoryTypeEnum.Som || historyType === HistoryTypeEnum.Col)  ? 'd-none' : ''">
                                    {{ HistoryTypeLookup[historyType] }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>

                <div class="col-12 d-flex gap-3">

                    <ng-template #elseBlock1>
                        <button type="button" class="btn waves-effect waves-light btn-info w-100 text-nowrap"
                            (click)="openDailyRoutineModal()">Add Daily
                            Routine</button>
                    </ng-template>
                    <div class="btn-group w-100" *ngIf="dailyRoutineUrl; else elseBlock1">
                        <a class="btn waves-effect waves-light btn-info w-100 text-nowrap" [href]="dailyRoutineRedirectUrl"
                            target="_blank">View Daily
                            Routine</a>
                        <button type="button" class="btn waves-effect waves-light btn-info"
                            (click)="openDailyRoutineModal()">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>

                    <ng-template #elseBlock2>
                        <button type="button" class="btn waves-effect waves-light btn-info w-100 text-nowrap"
                            (click)="openWwymDriveModal()">Add Wwym Drive</button>
                    </ng-template>
                    <div class="btn-group w-100" *ngIf="wwymDriveUrl; else elseBlock2">
                        <a class="btn waves-effect waves-light btn-info w-100 text-nowrap" [href]="wwymDriveRedirectUrl"
                            target="_blank">View Wwym Drive</a>
                        <button type="button" class="btn waves-effect waves-light btn-info"
                            (click)="openWwymDriveModal()">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>

                    <ng-template #elseBlock3>
                        <button type="button" class="btn waves-effect waves-light btn-info w-100 text-nowrap"
                            (click)="openClientNotesUrlModal()">Add Client Notes</button>
                    </ng-template>
                    <div class="btn-group w-100" *ngIf="clientNotesUrl; else elseBlock3">
                        <a class="btn waves-effect waves-light btn-info w-100 text-nowrap" [href]="clientNotesRedirectUrl"
                            target="_blank">View Client Notes</a>
                        <button type="button" class="btn waves-effect waves-light btn-info"
                            (click)="openClientNotesUrlModal()">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>

                    <button type="button" class="btn waves-effect waves-light btn-info w-100 text-nowrap"
                        (click)="noteSelectionChanged(HistoryTypeEnum.Som)">Add SOM</button>

                    <button type="button" class="btn waves-effect waves-light btn-info w-100 text-nowrap"
                        (click)="noteSelectionChanged(HistoryTypeEnum.Col)">Add COL</button>

                </div>

                <div class="col-12 mt-3">
                    <angular-editor formControlName="notes" [config]="editorConfig"></angular-editor>
                </div>

                <div class="row m-0">
                    <div class="col-md-6 mt-3" *ngFor="let file of selectedFiles">
                        <div class="card rounded-3 card-hover mb-2 bg-secondary">
                            <div class="card-body p-3 cursor-pointer">

                                <div class="d-flex align-items-center">
                                    <span class="flex-shrink-0">
                                        <i class="ri-file-text-fill text-primary file-icon"></i>
                                    </span>
                                    <div class="ms-4 flex-grow-1 w-50">
                                        <div>
                                            <h4 class="card-title text-dark mb-0 single-line">
                                                {{file.name}}</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"
                                            (click)="removeFile(file, selectedFiles, fileInputRef)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-4 mt-3 mb-2">
                    <h5 *ngIf="isNoteSharable">
                        Confidentiality</h5>
                </div>

                <div class="col-4 mt-3 mb-2">
                    <h5 *ngIf="isNoteSharable">Who can
                        see this note?</h5>
                </div>

                <div class="col-4 mt-5">

                    <div class="d-flex flex-row-reverse action-buttons">
                        <button class="btn waves-effect waves-light btn-success m-1">
                            Save Notes
                        </button>

                        <input type="file" #fileInput class="d-none" (change)="handleFileInput($event, selectedFiles)"
                            multiple>
                        <button type="button" class="btn waves-effect waves-light btn-primary m-1"
                            (click)="fileInput.click()">
                            Attach Files
                        </button>
                    </div>

                </div>

                <div class="col-4 mb-2" *ngIf="isNoteSharable">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input primary check-outline outline-primary" type="radio"
                            id="confidential" name="confidentiality" formControlName="confidentiality" [value]="true"
                            (change)="onConfidentialityChanged(true)">
                        <label class="form-check-label" for="confidential">Confidential</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input primary check-outline outline-primary" type="radio"
                            id="non-confidential" name="confidentiality" formControlName="confidentiality"
                            [value]="false" (change)="onConfidentialityChanged(false)">
                        <label class="form-check-label" for="non-confidential">Non-confidential</label>
                    </div>
                </div>

                <div class="col-4 mb-2" *ngIf="isNoteSharable">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="visibleToParent"
                            formControlName="visibleToParent">
                        <label class="form-check-label" for="visibleToParent">
                            Parent
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="visibleToMentee"
                            formControlName="visibleToMentee" [attr.disabled]="(confidentiality === true) || null">
                        <label class="form-check-label" for="visibleToMentee">
                            Mentee
                        </label>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>
<mya-daily-routine-modal [accountId]="accountId"></mya-daily-routine-modal>
<mya-wwym-drive-modal [accountId]="accountId"></mya-wwym-drive-modal>
<mya-client-notes-url-modal [accountId]="accountId"></mya-client-notes-url-modal>
<div [id]="elementId" class="offcanvas offcanvas-end text-white" data-bs-scroll="false" data-bs-backdrop="true" tabindex="-1">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title">
            Block Schedular
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <mya-block-scheduler *ngIf="show" [accountId]="accountId" [subscription]="activeSubscription"
            (save)="saveBlockSchedulerChanges($event)" (switchToEditMode)="switchToEditMode.emit()" [readonly]="readonly"
            [mentoringPlanId]="mentoringPlanId"></mya-block-scheduler>
    </div>
</div>
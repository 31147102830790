import { AfterContentChecked, ChangeDetectorRef, Component, Input } from '@angular/core';
import { OffCanvasConstant } from '../../common/constants/offcanvas.constant';
import { Subscription } from '@mya/models';
import { OffcanvasService } from '../../services/offcanvas.service';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'mya-change-plan-modal',
  templateUrl: './change-plan-modal.component.html',
  styleUrls: ['./change-plan-modal.component.scss'],
})
export class ChangePlanModalComponent implements AfterContentChecked {
  @Input() activeSubscription: Subscription | null = null;
  @Input() pendingSubscription: Subscription | null = null;
  @Input() accountId: string | null = null;
  elementId = OffCanvasConstant.CHANGE_PLAN_OFFCANVAS;
  mentoringPlanId: string | null = null;
  show = false;

  constructor(private offcanvasService: OffcanvasService,
    private subscriptionService: SubscriptionService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterContentChecked(): void {
    this.offcanvasService.initialize(OffCanvasConstant.CHANGE_PLAN_OFFCANVAS);
  }

  public open(mentoringPlanId?: string | null) {
    this.show = false;
    this.mentoringPlanId = mentoringPlanId ?? null;
    this.subscriptionService.getActiveSubscriptionByAccount(this.accountId as string);
    this.subscriptionService.getPendingSubscriptionByAccount(this.accountId as string);
    this.changeDetectorRef.detectChanges();
    this.offcanvasService.show(OffCanvasConstant.CHANGE_PLAN_OFFCANVAS);
    this.show = true;
    this.changeDetectorRef.detectChanges();
  }
}

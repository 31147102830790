import { Component, Input } from '@angular/core';
import { HistoryItem, HistoryTypeEnum, HistoryTypeLookup } from '@mya/models';
import { OffCanvasConstant } from '../../common/constants/offcanvas.constant';
import { readOnlyEditorConfig } from '../../common/configs/angular-editor-configs';

@Component({
  selector: 'mya-notes-view',
  templateUrl: './notes-view.component.html',
  styleUrls: ['./notes-view.component.scss'],
})
export class NotesViewComponent {
  @Input() historyItem: HistoryItem | null = null;
  editorConfig = readOnlyEditorConfig;
  elementId = OffCanvasConstant.NOTES_VIEW;

  get HistoryTypeLookup() {
    return HistoryTypeLookup;
  }

  get noteTypeClass() {
    switch (this.historyItem?.historyTypeId) {
      case HistoryTypeEnum.System:
        return 'note-system';
      case HistoryTypeEnum.Consultant:
        return 'note-consultant';
      case HistoryTypeEnum.Parent:
        return 'note-parent';
      case HistoryTypeEnum.Quote:
        return 'note-quote';
      case HistoryTypeEnum.Gcs:
        return 'note-gcs';
      case HistoryTypeEnum.Som:
        return 'note-som';
      case HistoryTypeEnum.Col:
        return 'note-col';
      case HistoryTypeEnum.Office:
        return 'note-office';
      default:
        return null;
    }
  }
}

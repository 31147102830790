import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InternalUser, ReportTypes, ReportTypesLookup } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-report-mentor-performance',
  templateUrl: './report-mentor-performance.component.html',
  styleUrls: ['./report-mentor-performance.component.scss'],
})
export class ReportMentorPerformanceComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid!: DxDataGridComponent;
  @ViewChild('summaryGrid') summaryGrid!: DxDataGridComponent;
  @Input() dateRange: [Date, Date];
  @Input() mentor: string | null = null;

  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  dataSource: any[] | null = null;
  summaryDataSource: any[] | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private reportsService: ReportsService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate];
  }

  ngOnInit(): void {
    this.loadDataSource(this.dateRange[0], this.dateRange[1]);
  }

  loadDataSource(startDate: Date, endDate: Date) {
    this.subscriptions.push(this.reportsService.getReports(ReportTypes.MentorPerformance, startDate, endDate, this.mentor)
      .subscribe((result: any) => {
        this.dataSource = result.result.data;
        this.summaryDataSource = this.loadSummaryDataSource(result.result.data);
      }));
  }

  loadSummaryDataSource(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (filteredRecords.findIndex(i => i.mentorName == record.mentorName) == -1) {
        filteredRecords.push({
          mentorName: record.mentorName,
          avgLifeSpan: record.avgLifeSpan,
        });
      }
    }
    return filteredRecords;
  }

  onExporting() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    const summaryRow = worksheet.insertRow(1, ["Mentor Performance"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.grid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      this.exportSummaryGrid(worksheet, workbook);
    });
  }

  exportSummaryGrid(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow((worksheet.lastRow?.number ?? 1) + 2, ["Mentor’s client's average life span"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      worksheet.columns.forEach(column => {
        column.width = 30;
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${ReportTypesLookup[ReportTypes.MentorPerformance]} Report.xlsx`);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}


import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DayOfWeek } from '@mya/models';

@Component({
  selector: 'mya-block-scheduler-day-selector',
  templateUrl: './block-scheduler-day-selector.component.html',
  styleUrls: ['./block-scheduler-day-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BlockSchedulerDaySelectorComponent
    }
  ]
})
export class BlockSchedulerDaySelectorComponent implements ControlValueAccessor {
  @Input() availableSlots: Date[] = [];
  selectedDayOfWeek: DayOfWeek | null = null;

  get DayOfWeek() {
    return DayOfWeek;
  }

  get mondaySlots() {
    const a = this.availableSlots.filter(i => i.getDay() as DayOfWeek == DayOfWeek.Monday);
    return a;
  }

  get tuesdaySlots() {
    return this.availableSlots.filter(i => i.getDay() == DayOfWeek.Tuesday);
  }

  get wednesdaySlots() {
    const a = this.availableSlots.filter(i => i.getDay() as DayOfWeek == DayOfWeek.Wednesday);
    return a;
  }

  get thursdaySlots() {
    return this.availableSlots.filter(i => i.getDay() == DayOfWeek.Thursday);
  }

  get fridaySlots() {
    return this.availableSlots.filter(i => i.getDay() == DayOfWeek.Friday);
  }

  get saturdaySlots() {
    return this.availableSlots.filter(i => i.getDay() == DayOfWeek.Saturday);
  }

  get sundaySlots() {
    return this.availableSlots.filter(i => i.getDay() == DayOfWeek.Sunday);
  }

  writeValue(dayOfWeek: DayOfWeek | null): void {
    this.selectedDayOfWeek = dayOfWeek;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    console.warn('Disabling control not supported');
  }

  onChange = (dayOfWeek: DayOfWeek | null) => {
    //do nothing
  };
  onTouched = () => {
    //do nothing
  };

  valueChanged(event: any) {
    this.selectedDayOfWeek = parseInt(event.target.value) as DayOfWeek | null;
    this.onChange(this.selectedDayOfWeek);
  }
}

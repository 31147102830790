<div class="h-100 position-relative">
    <div @disableInitalTrigger class="position-absolute w-100" [ngClass]="{'h-100': currentView === MenteeDetailViews.Main}">
        <div @mainTrigger *ngIf="currentView === MenteeDetailViews.Main" class="h-100">
            <mya-mentee-detail [accountId]="accountId" (changeCurrentView)="currentViewedChanged($event)"></mya-mentee-detail>
        </div>
    </div>
    <div @disableInitalTrigger class="position-absolute w-100" [ngClass]="{'h-100': currentView === MenteeDetailViews.ScheduleAppointment}">
        <div @childTrigger *ngIf="currentView === MenteeDetailViews.ScheduleAppointment" class="h-100">
            <mya-schedule-appointment [accountId]="accountId" (changeCurrentView)="currentViewedChanged($event)"></mya-schedule-appointment>
        </div>
    </div>
    <div @disableInitalTrigger class="position-absolute w-100" [ngClass]="{'h-100': currentView === MenteeDetailViews.ViewAppointments}">
        <div @childTrigger *ngIf="currentView === MenteeDetailViews.ViewAppointments" class="h-100">
            <mya-mentee-appointments [accountId]="accountId" (changeCurrentView)="currentViewedChanged($event)"></mya-mentee-appointments>
        </div>
    </div>
</div>
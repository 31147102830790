import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { APP_CONFIG } from '@mya/configuration';
import { InternalClientTypes, InternalUserClaimTypes, ValidateADTokenRequest, ValidateADTokenResult } from '@mya/models';
import { BehaviorSubject, catchError, Observable, shareReplay } from 'rxjs';
import { JwtService } from './jwt.service';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { DateTimeService } from './date-time.service';
import { Subscription as Subs } from 'rxjs';
const tokenKey = 'mentor_token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];
  authTokenSubscription = new BehaviorSubject<Observable<AuthenticationResult> | null>(null);
  apiTokenSubscription = new BehaviorSubject<Observable<ValidateADTokenResult> | null>(null);
  overriddenDate: Date | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService, private jwtService: JwtService, private loaderService: LoaderService,
    private authService: MsalService, @Inject(APP_CONFIG) appConfig: any, dateTimeService: DateTimeService) {
    this.baseApiUrl = `${appConfig.apiUrls.authentication}auth`;
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  public getToken(): Observable<string> {
    return new Observable(observer => {

      const token = localStorage.getItem(tokenKey);
      if (token) {
        const decodedToken: any = this.jwtService.DecodeToken(token);
        const expiry = decodedToken[InternalUserClaimTypes.Expiry] as number;
        if (expiry > ((this.currentDate.getTime() / 1000) + 600)) {
          observer.next(token);
          return;
        }
      }

      const http = this.http;
      const authService = this.authService;
      const msalGuardConfig = this.msalGuardConfig;
      const baseApiUrl = this.baseApiUrl;
      const authTokenSubscription = this.authTokenSubscription;
      const apiTokenSubscription = this.apiTokenSubscription;
      const loaderService = this.loaderService;
      const subscriptions = this.subscriptions;

      if (this.authTokenSubscription.value === null) {
        this.authTokenSubscription.next(this.authService.acquireTokenSilent({ scopes: (this.msalGuardConfig.authRequest as any).scopes }));
      }

      const subs = this.authTokenSubscription.value?.subscribe({
        next(response) {

          const loaderIdentifier = uuid();
          if (apiTokenSubscription.value === null) {

            loaderService.show(loaderIdentifier);
            const request: ValidateADTokenRequest = {
              token: response.accessToken,
              client: InternalClientTypes.Mentor
            };
            apiTokenSubscription.next(http.post<ValidateADTokenResult>(`${baseApiUrl}/AuthenticateOAuth`, request)
              .pipe(shareReplay()));
          }

          const subs = apiTokenSubscription.value?.pipe(catchError((error: HttpErrorResponse) => {
            loaderService.hide(loaderIdentifier);
            if (error.status == HttpStatusCode.Unauthorized) {
              authService.loginRedirect({ ...msalGuardConfig.authRequest } as RedirectRequest);
            }
            authTokenSubscription.next(null);
            throw error;
          }))
            .subscribe((response: any) => {
              loaderService.hide(loaderIdentifier);
              localStorage.setItem(tokenKey, response.token);
              observer.next(response.token);
              authTokenSubscription.next(null);
            });

          if (subs)
            subscriptions.push(subs);
        },
        error(err) {
          authService.loginRedirect({ ...msalGuardConfig.authRequest } as RedirectRequest);
          authTokenSubscription.next(null);
        }
      });

      if (subs)
        this.subscriptions.push(subs);
    });
  }

  public refreshToken() {
    localStorage.removeItem(tokenKey);
    this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
  }

  public logout() {
    localStorage.removeItem(tokenKey);
    const account = this.msalService.instance.getActiveAccount();
    this.msalService.logout({ account: account, postLogoutRedirectUri: `${window.location.origin}/mentor` });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
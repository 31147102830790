<div [id]="elementId" class="offcanvas offcanvas-end" data-bs-scroll="false" data-bs-backdrop="true" tabindex="-1">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title">
            {{mentoringPlanId ? 'Block Scheduler' : 'Change Plan'}}
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <mya-change-plan *ngIf="show" [accountId]="accountId" [activeSubscription]="activeSubscription"
            [pendingSubscription]="pendingSubscription" [mentoringPlanId]="mentoringPlanId"></mya-change-plan>
    </div>
</div>
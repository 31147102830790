import { DatePipe } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mya-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangePickerComponent
    }
  ]
})
export class DateRangePickerComponent implements ControlValueAccessor {
  @ViewChild(NgbInputDatepicker) datepicker!: NgbInputDatepicker;
  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  today = inject(NgbCalendar).getToday();
  touched = false;
  disabled = false;
  selectedDate: [NgbDate | null, NgbDate | null] = [this.calendar.getToday(), this.calendar.getNext(this.calendar.getToday(), 'd', 10)];
  hoveredDate: NgbDate | null = null;

  get range() {
    return this.selectedDate[0] && this.selectedDate[1] ? `${this.datePipe.transform(this.selectedDate[0].toDate(), 'MM/dd/yy')} - ${this.datePipe.transform(this.selectedDate[1].toDate(), 'MM/dd/yy')}` : null;
  }

  constructor(private datePipe: DatePipe) { }

  onChange = (selectedDate: [NgbDate | null, NgbDate | null]) => {
    //do nothing
  };
  onTouched = () => {
    //do nothing
  };

  writeValue(selectedDate: [NgbDate | null, NgbDate | null]): void {
    this.selectedDate = selectedDate;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  toggleDatePicker() {
    if (this.disabled)
      return;

    this.datepicker.toggle();
  }

  onDateSelection(date: NgbDate) {
    this.markAsTouched();
    if (this.disabled)
      return;

    if (!this.selectedDate[0] && !this.selectedDate[1]) {
      this.selectedDate[0] = date;
    } else if (this.selectedDate[0] && !this.selectedDate[1] && date && date.after(this.selectedDate[0])) {
      this.selectedDate[1] = date;
    } else {
      this.selectedDate[1] = null;
      this.selectedDate[0] = date;
    }

    this.onChange(this.selectedDate);
  }

  selectRange(range: string) {
    this.markAsTouched();
    if (this.disabled)
      return;

    switch (range) {
      case 'week':
        this.selectedDate[1] = this.today;
        this.selectedDate[0] = this.today.addDays(7);
        break;
      case 'month':
        this.selectedDate[1] = this.today;
        this.selectedDate[0] = this.today.addDays(30);
        break;
      case '3-months':
        this.selectedDate[1] = this.today;
        this.selectedDate[0] = this.today.addDays(90);
        break;
      default:
        return;
    }

    this.onChange(this.selectedDate);
  }

  isHovered(date: NgbDate) {
    return (
      this.selectedDate[0] && !this.selectedDate[1] && this.hoveredDate && date.after(this.selectedDate[0]) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.selectedDate[1] && date.after(this.selectedDate[0]) && date.before(this.selectedDate[1]);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.selectedDate[0]) ||
      (this.selectedDate[1] && date.equals(this.selectedDate[1])) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
}

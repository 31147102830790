<div class="d-flex align-items-end m-2">
    <h3 class="mt-4 flex-grow-1">Mentor Performance</h3>

    <button type="button" class="mt-1 btn waves-effect waves-light btn-info btn-export" (click)="onExporting()">
        Export
    </button>
</div>
<dx-data-grid #grid class="m-2" [dataSource]="dataSource" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxi-column dataField="mentorName" caption="Mentor Name" dataType="string"> </dxi-column>
    <dxi-column dataField="userType" caption="Role" dataType="string"> </dxi-column>
    <dxi-column dataField="appointmentType" caption="Appointment Type" dataType="date"> </dxi-column>
    <dxi-column dataField="hours" caption="Hours" dataType="string"> </dxi-column>
</dx-data-grid>

<h3 class="m-2 mt-4">Mentor’s client's average life span</h3>
<dx-data-grid #summaryGrid class="m-2" [dataSource]="summaryDataSource" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">
    <dxo-paging [enabled]="false"></dxo-paging>
    
    <dxi-column dataField="mentorName" caption="Mentor Name" dataType="string"> </dxi-column>
    <dxi-column dataField="avgLifeSpan" caption="Average Life Span" dataType="string"></dxi-column>
</dx-data-grid>
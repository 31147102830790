import { AfterContentChecked, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MenteeDetailViews } from '../../shared/enums/mentee-detail-views.enum';
import { CalendarOptions, EventClickArg, EventInput, EventMountArg } from '@fullcalendar/core';
import { SubscriptionService } from '../../services/subscription.service';
import { AppointmentService } from '../../services/appointment.service';
import { Account, AccountStatus, Appointment, AppointmentBlock, AppointmentTypeEnum, CalendarEvent, DayOfWeekLookup, ExternalUser, InternalUser, PlanPricing, ReferenceDataTypes, ReferenceItem, Subscription, getMentee } from '@mya/models';
import { AccountService } from '../../services/account.service';
import { OffcanvasService } from '../../services/offcanvas.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { DatePipe, WeekDay } from '@angular/common';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { InternalUserService } from '../../services/internal-user.service';
import { ModalService } from '../../services/modal.service';
import { BookingService } from '@mya/booking-shared';
import { ModalConstant } from '../../common/constants/modal.constant';
import { OffCanvasConstant } from '../../common/constants/offcanvas.constant';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { BlockSchedulerModalComponent } from '../../modals/block-scheduler-modal/block-scheduler-modal.component';
import { PlanPricingService } from '../../services/plan-pricing.service';
import { ChangePlanModalComponent } from '../../modals/change-plan-modal/change-plan-modal.component';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';
declare const bootstrap: any;

@Component({
  selector: 'mya-mentee-appointments',
  templateUrl: './mentee-appointments.component.html',
  styleUrls: ['./mentee-appointments.component.scss'],
})
export class MenteeAppointmentsComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild(BlockSchedulerModalComponent) blockSchedulerModal!: BlockSchedulerModalComponent;
  @ViewChild(ChangePlanModalComponent) changePlanModal!: ChangePlanModalComponent;
  @Output() changeCurrentView = new EventEmitter<MenteeDetailViews>();
  @Input() accountId: string | null = null;

  serviceId: string | null = null;
  overriddenDate: Date | null = null;
  account: Account | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  activeSubscription: Subscription | null = null;
  pendingSubscription: Subscription | null = null;
  appointmentBlocks: AppointmentBlock[] = [];
  appointments: Appointment[] = [];
  calendarEvents: CalendarEvent[] = [];
  appointmentCancelReason: string | null = null;
  isAppointmentRefundable: boolean | null = null;
  selectedAppointmentId: string | null = null;
  selectedBookingAppointmentId: string | null = null;
  planPricings: PlanPricing[] = [];
  currentAppointments = true;
  otherAppointments = false;
  outlookEvents = false;
  startDate: Date | null = null;
  endDate: Date | null = null;
  subscriptions: Subs[] = [];

  timezoneOffset = 0;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin],
    datesSet: this.handleDatesSet.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    initialView: 'dayGridMonth',
    contentHeight: "70vh",
    eventClassNames: ['px-1', 'text-white'],
    firstDay: 1,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    headerToolbar: {
      left: 'prev,next,today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }
  };

  get sortedAppointmentBlocks() {
    return this.appointmentBlocks.sort((a, b) => a.day - b.day);
  }

  get mentorUTCTimeZoneDiff() {
    return this.currentDate.getTimezoneOffset() * -1;
  }

  get AccountStatus() {
    return AccountStatus;
  }

  get isHourlyPlan(): boolean {
    return this.planPricings.find(i => i.id == this.activeSubscription?.planPricingId)?.sessionsPerWeek == null;
  }

  get weeklyInterval() {
    return this.account != null ? this.planPricings.find(planPricing => planPricing.mentoringPlanId == this.account?.mentoringPlanId)?.weeklyInterval ?? 0 : 0;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private accountService: AccountService,
    private subscriptionService: SubscriptionService,
    private appointmentService: AppointmentService,
    private offcanvasService: OffcanvasService,
    private internalUserService: InternalUserService,
    private bookingService: BookingService,
    private planPricingService: PlanPricingService,
    private modalService: ModalService,
    private datePipe: DatePipe,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => {
      this.overriddenDate = date;
      this.timezoneOffset = this.currentDate.getTimezoneOffset() * 60000;
    }));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.planPricingService.PlanPricing$.subscribe(planPricings => {
      this.planPricings = planPricings;
    }));
    this.subscriptions.push(this.internalUserService.Mentor$.subscribe(user => {
      this.mentor = user
      this.getAppointments();
    }));

    if (this.accountId) {
      this.subscriptions.push(this.accountService.getAccount(this.accountId).subscribe((account) => {
        this.account = account;
        this.mentee = getMentee(account);
      }));

      this.subscriptionService.getActiveSubscriptionByAccount(this.accountId as string);
      this.subscriptionService.getPendingSubscriptionByAccount(this.accountId as string);
    }

    this.subscriptions.push(this.subscriptionService.PendingSubscription$.subscribe(subscription => {
      this.pendingSubscription = subscription;
    }));
    this.subscriptions.push(this.subscriptionService.ActiveSubscription$.subscribe(subscription => {
      this.activeSubscription = subscription;
      if (this.activeSubscription) {
        this.subscriptions.push(this.appointmentService.getAppointmentBlocksBySubscription(this.activeSubscription.id, true).subscribe(appointmentBlocks => {
          this.appointmentBlocks = appointmentBlocks;
        }));
      }
    }));

    this.subscriptions.push(this.bookingService.ServiceId$.subscribe(serviceId => {
      this.serviceId = serviceId;
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.appointmentService.UserAppointments$.subscribe(appointments => {
      this.appointments = appointments;
      this.generateEvents();
    }));

    this.subscriptions.push(this.internalUserService.CalendarEvents$.subscribe(events => {
      this.calendarEvents = events;
      this.generateEvents();
    }));
  }

  ngAfterContentChecked(): void {
    this.offcanvasService.initialize(OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS);
    this.modalService.initialize(ModalConstant.EDIT_APPOINTMENT_MODAL);
    this.modalService.initialize(ModalConstant.CANCEL_APPOINTMENT_MODAL);
    this.modalService.initialize(ModalConstant.RESCHEDULE_APPOINTMENT_MODAL);
  }

  handleDatesSet() {
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      this.startDate = calendarApi.view.currentStart;
      this.endDate = calendarApi.view.currentEnd;
      this.getAppointments();
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (clickInfo.event.extendedProps['isEditable']) {
      this.appointmentCancelReason = null;
      this.isAppointmentRefundable = null;
      this.selectedAppointmentId = clickInfo.event.extendedProps['appointmentId'];
      this.selectedBookingAppointmentId = clickInfo.event.extendedProps['bookingAppointmentId'];
      this.modalService.show(ModalConstant.EDIT_APPOINTMENT_MODAL);
    }
  }

  handleEventDidMount(eventInfo: EventMountArg) {
    new bootstrap.Tooltip(eventInfo.el, {
      title: eventInfo.event.title,
      delay: { "show": 500, "hide": 100 }
    });
  }

  getAppointments() {
    if (this.mentor && this.startDate && this.endDate) {
      if (this.currentAppointments || this.otherAppointments) {
        this.appointmentService.getAppointmentsByUser(this.startDate, this.endDate);
      }

      if (this.outlookEvents) {
        this.internalUserService.getCalendarEventsByUser(this.mentor.emailAddress, this.startDate, this.endDate);
      }
    }
  }

  currentAppointmentsChecked() {
    if (this.appointments.length == 0 && this.currentAppointments && this.mentor && this.startDate && this.endDate) {
      this.appointmentService.getAppointmentsByUser(this.startDate, this.endDate);
      return;
    }
    this.generateEvents();
  }

  otherAppointmentsChecked() {
    if (this.appointments.length == 0 && this.otherAppointments && this.mentor && this.startDate && this.endDate) {
      this.appointmentService.getAppointmentsByUser(this.startDate, this.endDate);
      return;
    }
    this.generateEvents();
  }

  outlookEventsChecked() {
    if (this.calendarEvents.length == 0 && this.outlookEvents && this.mentor && this.startDate && this.endDate) {
      this.internalUserService.getCalendarEventsByUser(this.mentor.emailAddress, this.startDate, this.endDate);
      return;
    }
    this.generateEvents();
  }

  generateEvents() {
    const appointmentEvents = this.appointments.filter(i => (i.accountId == this.accountId && this.currentAppointments) || (i.accountId != this.accountId && this.otherAppointments)).map((appointment) =>
      <EventInput>{
        title: appointment.title,
        start: this.datePipe.transform(new Date(new Date(appointment.startTime).getTime() - this.timezoneOffset), 'YYYY-MM-ddTHH:mm:ss'),
        end: this.datePipe.transform(new Date(new Date(appointment.startTime).getTime() + (appointment.durationInMinutes * 60000) - this.timezoneOffset), 'YYYY-MM-ddTHH:mm:ss'),
        className: this.getAppointmentClassName(appointment, this.accountId),
        extendedProps: {
          appointmentId: appointment.id,
          bookingAppointmentId: appointment.bookingAppointmentId,
          isEditable: this.isAppointmentEditable(appointment, this.accountId)
        }
      });

    const calendarEvents = !this.outlookEvents ? [] : this.calendarEvents.map((calendarEvent) =>
      <EventInput>{
        title: `${calendarEvent.subject}`,
        start: this.datePipe.transform(new Date(new Date(calendarEvent.start.dateTime).getTime() - this.timezoneOffset), 'YYYY-MM-ddTHH:mm:ss'),
        end: this.datePipe.transform(new Date(new Date(calendarEvent.end.dateTime).getTime() - this.timezoneOffset), 'YYYY-MM-ddTHH:mm:ss'),
        className: 'calendar-outlook cursor-default',
        extendedProps: {
          isEditable: false
        }
      });

    const events: EventInput[] = [];
    if (appointmentEvents && appointmentEvents.length > 0) {
      events.push(...appointmentEvents);
    }

    if (calendarEvents && calendarEvents.length > 0) {
      events.push(...calendarEvents);
    }

    if (this.calendarComponent) {
      this.calendarComponent.events = events;
    }
  }

  getAppointmentClassName(appointment: Appointment, accountId: string | null) {
    if (appointment.accountId != accountId) {
      return 'calendar-other-mentee cursor-default';
    }

    if (appointment.rescheduledAppointmentId != null) {
      return 'bg-warning cursor-default';
    }

    if (appointment.cancellationDate != null) {
      return 'bg-danger cursor-default';
    }

    return 'calendar-current-mentee cursor-pointer card-hover';
  }

  isAppointmentEditable(appointment: Appointment, accountId: string | null): boolean {
    return appointment.accountId == accountId && appointment.appointmentTypeId == AppointmentTypeEnum.Mentor && appointment.cancellationDate == null;
  }

  manageBlockSchedules() {
    if (this.weeklyInterval == 1) {
      this.blockSchedulerModal.open();
    } else if (this.weeklyInterval > 1) {
      if (!this.pendingSubscription) {
        this.blockSchedulerModal.open(true);
      } else {
        this.changePlanModal.open(this.account?.mentoringPlanId);
      }
    }
  }

  switchToEditMode() {
    this.blockSchedulerModal.close();
    if (this.weeklyInterval == 1) {
      this.blockSchedulerModal.open();
    } else if (this.weeklyInterval > 1) {
      this.changePlanModal.open(this.account?.mentoringPlanId);
    }
  }

  manageBlocks() {
    this.offcanvasService.show(OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS);
  }

  back() {
    this.changeCurrentView.emit(MenteeDetailViews.Main);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

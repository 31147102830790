<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="cancelPlanForm" (ngSubmit)="confirmCancelPlan()">
                <div class="modal-header modal-colored-header bg-danger text-white">
                    <h4 class="modal-title">
                        Cancel Plan
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <label for="comments">Comments</label>
                            <textarea class="form-control" id="comments" formControlName="comments" rows="5"
                                placeholder="Enter"></textarea>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="confirm" formControlName="confirm">
                                <label class="form-check-label" for="confirm">
                                    I confirm that the Mentee or Parents have agreed to cancel the plan.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="submit" class="btn btn-light-danger text-danger font-medium"
                        [disabled]="cancelPlanForm.invalid">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
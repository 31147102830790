<div class="input-group">
    <input class="form-control" name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" placement="bottom right"
        [footerTemplate]="footerTemplate" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)"
        [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="selectedDate[0]!" tabindex="-1"
        readonly="true" [value]="range" (click)="toggleDatePicker()" />

    <ng-template #footerTemplate>
        <hr class="my-0" />
        <button type="button" class="btn btn-primary btn-sm m-2 float-start" (click)="selectRange('week')">Last 7 days</button>
        <button type="button" class="btn btn-primary btn-sm m-2 float-start" (click)="selectRange('month')">Last 30 days</button>
        <button type="button" class="btn btn-primary btn-sm m-2 float-start" (click)="selectRange('3-months')">Last 90 days</button>
        <button type="button" class="btn btn-secondary btn-sm m-2 float-end" (click)="datepicker.close()">Close</button>
    </ng-template>

    <ng-template #t let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
        </span>
    </ng-template>
    <button [disabled]="disabled" class="btn btn-outline-secondary bi bi-calendar3" (click)="datepicker.toggle()"
        type="button"></button>
</div>
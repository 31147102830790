import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [CommonModule, NgbDatepickerModule],
  exports: [DateRangePickerComponent],
  declarations: [DateRangePickerComponent],
})
export class ComponentsSharedModule { }

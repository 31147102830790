<div class="d-flex align-items-end m-2">
    <h3 class="mt-4 flex-grow-1">Mentee Account Cancellations</h3>

    <button type="button" class="mt-1 btn waves-effect waves-light btn-info btn-export" (click)="onExporting()">
        Export
    </button>
</div>
<dx-data-grid #grid class="m-2" [dataSource]="dataSource" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">

    <dxo-paging [enabled]="false"></dxo-paging>    
    <dxi-column dataField="mentorName" caption="Mentor Name" dataType="string"> </dxi-column>
    <dxi-column dataField="menteeName" caption="Mentee Name" dataType="string"> </dxi-column>
    <dxi-column dataField="remainingSessions" caption="Remaining Sessions" dataType="string"> </dxi-column>
    <dxi-column dataField="planName" caption="Plan Name" dataType="string"> </dxi-column>
    <dxi-column dataField="terminationDate" caption="Date of Termination" dataType="date"> </dxi-column>
    <dxi-column dataField="initiatedBy" caption="Initiated By" dataType="string"> </dxi-column>
    <dxi-column dataField="lifespan" caption="Mentee Lifespan" dataType="string"> </dxi-column>
    <dxi-column dataField="mentorEmail" caption="Mentor Email" dataType="string"> </dxi-column>
    <dxi-column dataField="menteeEmail" caption="Mentee Email" dataType="string"> </dxi-column>
    <dxi-column dataField="parentName" caption="Parent Name" dataType="string"> </dxi-column>
    <dxi-column dataField="parentEmail" caption="Parent Email" dataType="string"> </dxi-column>
</dx-data-grid>

<h3 class="m-2 mt-4">Mentee Duration & Cancellation Summary</h3>
<dx-data-grid #summaryGrid1 class="m-2" [dataSource]="summaryDataSource1" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">
    <dxo-paging [enabled]="false"></dxo-paging>
    
    <dxi-column dataField="mentorName" caption="Mentor Name" dataType="string"> </dxi-column>
    <dxi-column dataField="terminations" caption="Terminations" dataType="string"></dxi-column>
</dx-data-grid>
<h3 class="m-2 mt-4">Mentor Breakdown by Duration Category</h3>
<dx-data-grid #summaryGrid2 class="m-2" [dataSource]="summaryDataSource2" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxi-column dataField="mentorName" caption="Mentor Name" dataType="string"> </dxi-column>
    <dxi-column dataField="terminations" caption="Terminations" dataType="string" [groupIndex]="0"></dxi-column>
</dx-data-grid>
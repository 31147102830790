import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mya-block-scheduler-time-selector',
  templateUrl: './block-scheduler-time-selector.component.html',
  styleUrls: ['./block-scheduler-time-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BlockSchedulerTimeSelectorComponent
    }
  ]
})
export class BlockSchedulerTimeSelectorComponent implements ControlValueAccessor {
  @Input() availableSlots: Date[] = [];
  selectedSlot: Date | null = null;

  get lowestDate() {
    return this.availableSlots.length > 0 ? this.availableSlots.sort((a, b) => a.getTime() - b.getTime())[0] : null;
  }

  writeValue(date: Date | null): void {
    this.selectedSlot = date;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    console.warn('Disabling control not supported');
  }

  onChange = (dayOfWeek: Date | null) => {
    //do nothing
  };
  onTouched = () => {
    //do nothing
  };

  valueChanged(event: any) {
    this.selectedSlot = new Date(event.target.value);
    this.onChange(this.selectedSlot);
  }

  trackByFn(i: number, item: Date) {
    return item.getTime();
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InternalUserService } from '../../services/internal-user.service';
import { AppointmentTypeEnum, CreateAppointmentRequest, Account, ExternalUser, InternalUser, SmartCode, SmartTypes, Appointment, getMentee, SubscriptionToken, adjustDateByTimeZone, ReferenceDataTypes, ReferenceItem } from '@mya/models';
import { APP_CONFIG } from '@mya/configuration';
import { AccountService } from '../../services/account.service';
import { MenteeDetailViews } from '../../shared/enums/mentee-detail-views.enum';
import { BookingAppointmentSlot, BookingService } from '@mya/booking-shared';
import * as moment from 'moment';
import { AppointmentService } from '../../services/appointment.service';
import { LoaderService } from '../../services/loader.service';
import { v4 as uuid } from 'uuid';
import { SmartTypesService } from '../../services/smart-types.service';
import { SubscriptionService } from '../../services/subscription.service';
import { HttpStatusCode } from '@angular/common/http';
import { DateTimeService } from '../../services/date-time.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-schedule-appointment',
  templateUrl: './schedule-appointment.component.html',
  styleUrls: ['./schedule-appointment.component.scss'],
})
export class ScheduleAppointmentComponent implements OnInit, OnDestroy {
  @Output() changeCurrentView = new EventEmitter<MenteeDetailViews>();
  @Input() accountId: string | null = null;

  serviceId = '';
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  appointmentSlot: BookingAppointmentSlot | null = null;
  mentor: InternalUser | null = null;
  account: Account | null = null;
  mentee: ExternalUser | null = null;
  selectedUsers: ExternalUser[] = [];
  meetingDuration = 0;
  userTypes: Record<string, SmartCode> = {};
  subscriptionTokens: SubscriptionToken[] = [];
  showAppointmentDatepicker = true;
  timeZones: ReferenceItem[] = [];

  get accountAccesses() {
    return this.account?.accountAccesses ?? [];
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(@Inject(APP_CONFIG) appConfig: any,
    private internalUserService: InternalUserService,
    private accountService: AccountService,
    private bookingService: BookingService,
    private loaderService: LoaderService,
    private subscriptionService: SubscriptionService,
    private smartTypesService: SmartTypesService,
    private appointmentService: AppointmentService,
    private referenceDataService: ReferenceDataService,
    private changeDetectorRef: ChangeDetectorRef,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.serviceId = appConfig.booking.serviceId;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.internalUserService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));

    if (this.accountId) {
      this.subscriptions.push(this.accountService.getAccount(this.accountId).subscribe((account) => {
        this.account = account;
        this.mentee = getMentee(account);
      }));
    }
    this.subscriptions.push(this.bookingService.CurrentBookingService$.subscribe(service => {
      if (service) {
        this.meetingDuration = moment.duration(service.defaultDuration).asMinutes();
      }
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType).subscribe(smartCodes => {
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));

    this.subscriptions.push(this.subscriptionService.ActiveSubscription$.subscribe(subscription => {
      if (subscription?.autoRenew) {
        this.subscriptionService.getSubscriptionTokensBySubscription(subscription.id as string);
      }
    }));

    this.subscriptions.push(this.subscriptionService.SubscriptionTokens$.subscribe(subscriptionTokens => {
      this.subscriptionTokens = subscriptionTokens;
    }));

    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));
  }

  getUserType(user: ExternalUser | null) {
    if (user == null) {
      return '';
    }

    if (this.userTypes[user.userTypeId].code === 'O') {
      return user.otherUserType;
    } else {
      return this.userTypes[user.userTypeId].label;
    }
  }

  appointmentSlotSelected(date: BookingAppointmentSlot) {
    this.appointmentSlot = date;
  }

  selectUser(user: ExternalUser | null) {
    if (user != null) {
      if (!this.selectedUsers.some(i => i === user)) {
        this.selectedUsers.push(user);
      } else {
        this.selectedUsers.splice(this.selectedUsers.findIndex(i => i === user), 1);
      }
    }
  }

  isUserSelected(user: ExternalUser | null): boolean {
    return user != null && this.selectedUsers.some(i => i === user);
  }

  bookAppointment() {
    if (this.mentor && this.account && this.mentee && this.appointmentSlot && this.subscriptionTokens.length != 0) {
      const timeZone = this.selectedUsers.find(i => i.timeZone != null)?.timeZone ?? null;
      if (timeZone == null) {
        toastr.error('Time zone not found');
        return;
      }

      const appointmentId = uuid();
      const endDateTime = new Date(this.appointmentSlot.date);
      endDateTime.setMinutes(endDateTime.getMinutes() + this.meetingDuration);

      const request: CreateAppointmentRequest = {
        subscriptionTokenId: this.subscriptionTokens[0].id,
        appointment: <Appointment>{
          id: appointmentId,
          appointmentBlockId: null,
          accountId: this.account.id,
          durationInMinutes: this.meetingDuration,
          title: `Appointment`,
          startTime: this.appointmentSlot.date,
          appointmentTypeId: AppointmentTypeEnum.Mentor,
          meetingUrl: '',
          bookingAppointmentId: '',
          cancellationReason: null,
          cancellationDate: null
        },
        appointmentInternalUsers: [{
          id: uuid(),
          appointmentId: appointmentId,
          internalUserId: this.mentor.id,
          internalUser: null
        }],
        appointmentExternalUsers: [],
        bookingAppointment: {
          startDateTime: {
            dateTime: adjustDateByTimeZone(this.appointmentSlot.date, timeZone, this.timeZones),
            timeZone: timeZone
          },
          endDateTime: {
            dateTime: adjustDateByTimeZone(endDateTime, timeZone, this.timeZones),
            timeZone: timeZone
          },
          serviceId: this.serviceId,
          staffMemberIds: [this.appointmentSlot.staffId],
        },
        customers: []
      };

      this.selectedUsers.forEach(selectedUser => {
        request.appointmentExternalUsers.push({
          id: uuid(),
          appointmentId: appointmentId,
          externalUserId: selectedUser.id,
          externalUser: null
        });

        request.customers.push({
          customerId: selectedUser.bookingCustomerId,
          emailAddress: selectedUser.emailAddress,
          name: selectedUser.firstName
        });
      });

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.appointmentService.createAppointment(request, loaderIdentifier).subscribe(result => {
        this.loaderService.hide(loaderIdentifier);
        if (!result.success) {
          if (result.error.status == HttpStatusCode.Conflict && result.error.error == 'Slot Unavailable') {
            toastr.error('Selected Appointment slot is no longer available please select again.');
            this.showAppointmentDatepicker = false;
            this.changeDetectorRef.detectChanges();
            this.showAppointmentDatepicker = true;
            return;
          }

          if (result.error.status == HttpStatusCode.BadRequest) {
            toastr.error(result.error.error);
            return;
          }

          toastr.error('Error creating appointment');
          return;
        }

        toastr.success('Appointment booked.');
        this.changeCurrentView.emit(MenteeDetailViews.Main);
      }));
    }
  }

  back() {
    this.changeCurrentView.emit(MenteeDetailViews.Main);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

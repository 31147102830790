import { Component } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'mya-error-forbidden',
  templateUrl: './error-forbidden.component.html',
  styleUrls: ['./error-forbidden.component.scss'],
})
export class ErrorForbiddenComponent {
  constructor(private authenticationService: AuthenticationService) { }

  redirectToLoginPage() {
    this.authenticationService.logout();
  }
}

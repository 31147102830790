import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Subscription as Subs } from 'rxjs';
import { SubscriptionService } from '../../../../services/subscription.service';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';

@Component({
  selector: 'mya-block-scheduler-date-range-picker',
  templateUrl: './block-scheduler-date-range-picker.component.html',
  styleUrls: ['./block-scheduler-date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BlockSchedulerDateRangePickerComponent
    }
  ]
})
export class BlockSchedulerDateRangePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() accountId: string | null = null;
  @Input() utcTimeZoneDiff = 0;
  @ViewChild(NgbInputDatepicker) datepicker!: NgbInputDatepicker;
  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  today = inject(NgbCalendar).getToday();
  touched = false;
  disabled = false;
  subscriptions: Subs[] = [];
  range: [Date | null, Date | null] = [null, null];
  selectedTimeZoneDiff = 0;
  hoveredDate: NgbDate | null = null;
  subscriptionEndDate: Date | null = null;
  overriddenDate: Date | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;

  get calendarStartDate() {
    return this.startDate != null ? this.startDate.addMinutes(this.utcTimeZoneDiff).toNgbDate() : null;
  }

  get calendarEndDate() {
    return this.endDate != null ? this.endDate.addMinutes(this.utcTimeZoneDiff).toNgbDate() : null;
  }

  get calendarRangeStart() {
    return this.range[0] != null ? this.range[0].addMinutes(this.utcTimeZoneDiff).toNgbDate() : null;
  }

  get calendarRangeEnd() {
    return this.range[1] != null ? this.range[1].addMinutes(this.utcTimeZoneDiff).toNgbDate() : null;
  }

  get value() {
    return this.calendarRangeStart && this.calendarRangeEnd ? `${this.datePipe.transform(this.calendarRangeStart.toDate(), 'MM/dd/yy')} - ${this.datePipe.transform(this.calendarRangeEnd.toDate(), 'MM/dd/yy')}` : null;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private datePipe: DatePipe,
    private subscriptionService: SubscriptionService,
    private changeDetectorRef: ChangeDetectorRef,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  ngOnInit(): void {
    if (this.accountId) {
      this.subscriptionService.getActiveSubscriptionByAccount(this.accountId as string);
    }

    this.subscriptions.push(this.subscriptionService.ActiveSubscription$.subscribe(subscription => {
      if (subscription?.expirationDate) {
        this.subscriptionEndDate = new Date(subscription.expirationDate);
      }

      this.initializeDateRange();
    }));
  }

  initializeDateRange() {
    if (this.subscriptionEndDate) {
      this.startDate = new Date(this.subscriptionEndDate).addSeconds(1).addDays(1);
      this.endDate = new Date(this.startDate).addDays(14).addSeconds(-1);
      this.setDateRange(this.startDate, this.startDate.addDays(7).addSeconds(-1));
      this.onChange(this.range);
    } else {
      let date = this.currentDate.getUTCFullDate();
      date = date.addHours(1);
      date = date.generateDate(date.getHours(), 0, 0);

      this.startDate = date.addDays(2);
      this.endDate = new Date(this.startDate).addDays(14).addSeconds(-1);
      this.setDateRange(this.startDate, this.startDate.addDays(7).addSeconds(-1));
      this.onChange(this.range);
    }
  }

  onChange = (selectedDate: [Date | null, Date | null]) => {
    //do nothing
  };
  onTouched = () => {
    //do nothing
  };

  writeValue(range: [Date | null, Date | null]): void {
    this.range = range;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  toggleDatePicker() {
    if (this.disabled)
      return;

    this.datepicker.toggle();
  }

  onDateSelection(date: NgbDate) {
    this.markAsTouched();
    if (this.disabled || this.calendarStartDate == null || this.calendarEndDate == null || this.startDate == null || this.endDate == null)
      return;

    this.setDateRange(null, null);
    this.changeDetectorRef.detectChanges();
    const secondWeek = this.calendarStartDate.addDays(7);

    if ((date.equals(this.calendarStartDate) || date.after(this.calendarStartDate)) && date.before(secondWeek)) {
      this.setDateRange(this.startDate, this.startDate.addDays(7).addSeconds(-1));
    } else if ((date.equals(secondWeek) || date.after(secondWeek)) && (date.before(this.calendarEndDate) || date.equals(this.calendarEndDate))) {
      this.setDateRange(this.startDate.addDays(7), this.endDate);
    } else {
      return;
    }

    this.onChange(this.range);
  }

  setDateRange(start: Date | null, end: Date | null) {
    this.selectedTimeZoneDiff = this.utcTimeZoneDiff;
    this.range[0] = start;
    this.range[1] = end;
  }

  public trySetRange(date: Date) {
    if (!this.startDate || !this.endDate)
      return;

    if (date >= this.startDate && date <= this.startDate.addDays(7).addSeconds(-1) && this.range[0] != this.startDate) {
      this.setDateRange(this.startDate, this.startDate.addDays(7).addSeconds(-1));
      this.onChange(this.range);
    } else if (date >= this.startDate.addDays(7) && date <= this.endDate && this.range[0] != this.startDate.addDays(7)) {
      this.setDateRange(this.startDate.addDays(7), this.endDate);
      this.onChange(this.range);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.calendarRangeStart && !this.calendarRangeEnd && this.hoveredDate && date.after(this.calendarRangeStart) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.calendarRangeEnd && date.after(this.calendarRangeStart) && date.before(this.calendarRangeEnd);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.calendarRangeStart) ||
      (this.calendarRangeEnd && date.equals(this.calendarRangeEnd)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isDisabled = (date: NgbDate) => {
    if (this.calendarStartDate == null || this.calendarEndDate == null)
      return true;

    return this.calendarStartDate.after(date) || this.calendarEndDate.before(date);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

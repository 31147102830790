import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { ReportTypes } from '@mya/models';
import { catchError, map } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  baseApiUrl = '';

  constructor(private http: HttpClient, private loaderService: LoaderService,
    private datePipe: DatePipe, @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.reports}api/reports`
  }

  getReports(reportType: ReportTypes, startDate: Date, endDate: Date, mentorId: string | null = null) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    const formattedStartDate = this.datePipe.transform(new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000), 'yyyy-MM-ddTHH:mm:ss');
    const formatterdEndDate = this.datePipe.transform(new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000), 'yyyy-MM-ddTHH:mm:ss');
    return this.http.get(mentorId ? `${this.baseApiUrl}/get-reports/${reportType}/${formattedStartDate}/${formatterdEndDate}/${mentorId}` : `${this.baseApiUrl}/get-reports/${reportType}/${formattedStartDate}/${formatterdEndDate}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }), map((result) => {
        this.loaderService.hide(loaderIdentifier);
        return result;
      }));
  }
}

import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { OffCanvasConstant } from '../../common/constants/offcanvas.constant';
import { PlanPricing, Subscription, UpdateAppointmentBlocksByAccountRequest } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { AppointmentService } from '../../services/appointment.service';
import { LoaderService } from '../../services/loader.service';
import { OffcanvasService } from '../../services/offcanvas.service';
import { PlanPricingService } from '../../services/plan-pricing.service';
import { SubscriptionService } from '../../services/subscription.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-block-scheduler-modal',
  templateUrl: './block-scheduler-modal.component.html',
  styleUrls: ['./block-scheduler-modal.component.scss'],
})
export class BlockSchedulerModalComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() activeSubscription: Subscription | null = null;
  @Input() accountId: string | null = null;
  @Output() switchToEditMode = new EventEmitter();
  subscriptions: Subs[] = [];
  elementId = OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS;
  planPricings: PlanPricing[] = [];
  readonly = false;
  show = false;

  get mentoringPlanId() {
    return this.planPricings.find(i => i.id == this.activeSubscription?.planPricingId)?.mentoringPlanId ?? null;
  }

  constructor(private appointmentService: AppointmentService,
    private loaderService: LoaderService,
    private offcanvasService: OffcanvasService,
    private subscriptionService: SubscriptionService,
    private planPricingService: PlanPricingService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterContentChecked(): void {
    this.offcanvasService.initialize(OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.planPricingService.PlanPricing$.subscribe(planPricings => this.planPricings = planPricings));
  }

  public open(readonly?: boolean) {
    this.show = false;
    this.readonly = readonly ?? false;
    this.subscriptionService.getActiveSubscriptionByAccount(this.accountId as string);
    this.changeDetectorRef.detectChanges();
    this.offcanvasService.show(OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS);
    this.show = true;
    this.changeDetectorRef.detectChanges();
  }

  public close() {
    this.show = false;
    this.offcanvasService.hide(OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS);
  }

  saveBlockSchedulerChanges(request: UpdateAppointmentBlocksByAccountRequest) {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.appointmentService.updateAppointmentBlocks(request, loaderIdentifier).subscribe(() => {
      this.loaderService.hide(loaderIdentifier);
      toastr.success("Changes saved");
      this.offcanvasService.hide(OffCanvasConstant.BLOCK_SCHEDULER_OFFCANVAS);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { style, transition, animate, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { MenteeDetailViews } from '../../shared/enums/mentee-detail-views.enum';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-mentee-detail-base',
  templateUrl: './mentee-detail-base.component.html',
  styleUrls: ['./mentee-detail-base.component.scss'],
  animations: [
    trigger('disableInitalTrigger', [
      transition(':enter', [])
    ]),
    trigger("mainTrigger", [
      transition(":enter", [style({ transform: 'translateX(100%)' }), animate('500ms', style({ transform: 'translateX(0)' }))]),
      transition(":leave", [style({ transform: 'translateX(0)' }), animate('500ms', style({ transform: 'translateX(100%)' }))])
    ]),
    trigger("childTrigger", [
      transition(":enter", [style({ transform: 'translateX(-100%)' }), animate('500ms', style({ transform: 'translateX(0)' }))]),
      transition(":leave", [style({ transform: 'translateX(0)' }), animate('500ms', style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class MenteeDetailBaseComponent implements OnDestroy {
  currentView: MenteeDetailViews | null = MenteeDetailViews.Main;
  subscriptions: Subs[] = [];
  accountId: string | null = null;

  constructor(private route: ActivatedRoute){
    this.subscriptions.push(this.route.queryParams
    .pipe(filter((params) => params['id']))
    .subscribe((params) => {
      this.accountId = params['id'];
    }));
  }

  get MenteeDetailViews() {
    return MenteeDetailViews;
  }

  currentViewedChanged(view: MenteeDetailViews) {
    this.currentView = view;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

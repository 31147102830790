<div class="d-flex align-items-end m-2">
    <h3 class="mt-4 flex-grow-1">Accounts Payable Preview</h3>

    <button type="button" class="mt-1 btn waves-effect waves-light btn-info btn-export" (click)="onExporting()">
        Export
    </button>
</div>
<dx-data-grid #grid class="m-2" [dataSource]="dataSource" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">

    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="firstName" caption="First Name" dataType="string"> </dxi-column>
    <dxi-column dataField="lastName" caption="Last Name" dataType="string"> </dxi-column>
    <dxi-column dataField="userType" caption="Role" dataType="string"> </dxi-column>
    <dxi-column dataField="appointmentType" caption="Appointment Type" dataType="string"> </dxi-column>
    <dxi-column dataField="hours" caption="No. of Hours Worked" dataType="string"> </dxi-column>
    <dxi-column dataField="emailAddress" caption="Email Address" dataType="string"> </dxi-column>
    <dxi-column dataField="cellPhone" caption="Phone Number" dataType="string"> </dxi-column>
    <dxi-column dataField="addressLine1" caption="Address Line 1" dataType="string"> </dxi-column>
    <dxi-column dataField="addressLine2" caption="Address Line 2" dataType="string"> </dxi-column>
</dx-data-grid>

<h3 class="m-2 mt-4">Appointments Summary</h3>
<dx-data-grid #summaryGrid1 class="m-2" [dataSource]="summaryDataSource1" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxi-column dataField="appointmentType" caption="Appointment Type" dataType="string"> </dxi-column>
    <dxi-column dataField="hours" caption="Hours" dataType="string"></dxi-column>
    <dxi-column dataField="price" caption="Price" dataType="string">
        <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="total" caption="Total" dataType="string">
        <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>
</dx-data-grid>

<h3 class="m-2 mt-4">Breakdown by Contractor</h3>
<dx-data-grid #summaryGrid2 class="m-2" [dataSource]="summaryDataSource2" [showBorders]="true" [remoteOperations]="true"
    [rowAlternationEnabled]="true" [hoverStateEnabled]="true">
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxi-column dataField="name" caption="Name" dataType="string" [groupIndex]="0"> </dxi-column>
    <dxi-column dataField="appointmentType" caption="Appointment Type" dataType="string"> </dxi-column>
    <dxi-column dataField="hours" caption="Hours" dataType="string"></dxi-column>
    <dxi-column dataField="price" caption="Price" dataType="string">
        <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>
    <dxi-column dataField="total" caption="Total" dataType="string">
        <dxo-format type="currency" [precision]="2"></dxo-format>
    </dxi-column>
</dx-data-grid>
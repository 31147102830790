<div class="container-fluid pt-3">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="d-flex border-bottom title-part-padding align-items-center">
                    <div>
                        <h4 class="card-title mb-0">Reports</h4>
                    </div>
                </div>

                <mya-reports-selector (generateReport)="generateReport($event)"></mya-reports-selector>
                <mya-reports-grid *ngIf="dateRange[0] && dateRange[1]" [reportType]="reportType" [dateRange]="[dateRange[0], dateRange[1]]" [mentor]="mentor"></mya-reports-grid>
            </div>
        </div>
    </div>
</div>
import { AfterContentChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HistoryItem, HistoryTypeEnum, HistoryTypeLookup } from '@mya/models';
import { OffcanvasService } from '../../services/offcanvas.service';
import { HistoryService } from '../../services/history.service';
import { OffCanvasConstant } from '../../common/constants/offcanvas.constant';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() accountId: string | null = null;
  @Output() reloadAccount = new EventEmitter();
  subscriptions: Subs[] = [];
  selectedHistoryItem: HistoryItem | null = null;
  historyItems: HistoryItem[] = [];
  historyTypeFilter: HistoryTypeEnum | null = null;
  historyTypes = Object.values(HistoryTypeEnum).filter(value => typeof value === 'number') as HistoryTypeEnum[];
  sortAscending = true;

  get HistoryTypeLookup() {
    return HistoryTypeLookup;
  }

  get HistoryTypeEnum() {
    return HistoryTypeEnum;
  }

  get notes() {
    const historyItems = this.historyItems.filter(i => this.historyTypeFilter == null || this.historyTypeFilter == i.historyTypeId);

    return this.sortAscending ? historyItems.sort(
      (b, a) =>
        (a.creationDate ? new Date(a.creationDate).getTime() : 0) -
        (b.creationDate ? new Date(b.creationDate).getTime() : 0)
    ) :
      historyItems.sort(
        (a, b) =>
          (a.creationDate ? new Date(a.creationDate).getTime() : 0) -
          (b.creationDate ? new Date(b.creationDate).getTime() : 0)
      );
  }

  constructor(private historyService: HistoryService,
    private offcanvasService: OffcanvasService) { }

  ngOnInit(): void {
    if (this.accountId) {
      this.historyService.getHistoryItemsByAccount(this.accountId);
      this.subscriptions.push(this.historyService.HistoryItems$.subscribe(historyItems => {
        this.historyItems = historyItems ?? [];
      }));
    }
  }

  ngAfterContentChecked(): void {
    this.offcanvasService.initialize(OffCanvasConstant.NOTES_VIEW);
  }

  viewNote(historyItem: HistoryItem) {
    this.selectedHistoryItem = historyItem;
    this.offcanvasService.show(OffCanvasConstant.NOTES_VIEW);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportTypes, ReportTypesLookup } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Subscription as Subs } from 'rxjs';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';

@Component({
  selector: 'mya-report-mentee-account-cancellation',
  templateUrl: './report-mentee-account-cancellation.component.html',
  styleUrls: ['./report-mentee-account-cancellation.component.scss'],
})
export class ReportMenteeAccountCancellationComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid!: DxDataGridComponent;
  @ViewChild('summaryGrid1') summaryGrid1!: DxDataGridComponent;
  @ViewChild('summaryGrid2') summaryGrid2!: DxDataGridComponent;
  @Input() dateRange: [Date, Date];

  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  dataSource: any[] | null = null;
  summaryDataSource1: any[] | null = null;
  summaryDataSource2: any[] | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private reportsService: ReportsService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate]
  }

  ngOnInit(): void {
    this.loadDataSource(this.dateRange[0], this.dateRange[1]);
  }

  loadDataSource(startDate: Date, endDate: Date) {
    this.subscriptions.push(this.reportsService.getReports(ReportTypes.MenteeAccountCancellation, startDate, endDate, null)
      .subscribe((result: any) => {
        this.dataSource = result.result.data;
        this.summaryDataSource1 = this.loadSummaryDataSource1(result.result.data);
        this.summaryDataSource2 = this.loadSummaryDataSource2(result.result.data);
      }));
  }


  loadSummaryDataSource1(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (filteredRecords.findIndex(i => i.mentorEmail == record.mentorEmail) == -1) {
        filteredRecords.push({
          mentorEmail: record.mentorEmail,
          mentorName: record.mentorName,
          terminations: records.filter(i => i.mentorEmail == record.mentorEmail).length
        });
      }
    }
    return filteredRecords;
  }

  loadSummaryDataSource2(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (filteredRecords.findIndex(i => i.mentorEmail == record.mentorEmail) == -1) {
        const getTerminationCategory = function (terminations: number) {
          if (terminations > 1 || terminations < 4) {
            return '1-3';
          } else if (terminations > 3 || terminations < 7) {
            return '4-6';
          } else if (terminations > 6 || terminations < 10) {
            return '7-9';
          } else {
            return '9+';
          }
        };

        filteredRecords.push({
          mentorEmail: record.mentorEmail,
          mentorName: record.mentorName,
          terminations: getTerminationCategory(records.filter(i => i.mentorEmail == record.mentorEmail).length)
        });
      }
    }
    return filteredRecords;
  }

  onExporting() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    const summaryRow = worksheet.insertRow(1, ["Mentee Account Cancellations"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.grid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      this.exportSummaryGrid1(worksheet, workbook);
    });
  }

  exportSummaryGrid1(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow((worksheet.lastRow?.number ?? 1) + 2, ["Mentee Duration & Cancellation Summary"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid1.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      this.exportSummaryGrid2(worksheet, workbook);
    });
  }

  exportSummaryGrid2(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow((worksheet.lastRow?.number ?? 1) + 2, ["Mentor Breakdown by Duration Category"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid2.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      worksheet.columns.forEach(column => {
        column.width = 30;
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${ReportTypesLookup[ReportTypes.MenteeAccountCancellation]} Report.xlsx`);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

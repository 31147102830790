import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalConstant } from '../../common/constants/modal.constant';
import { CancelAppointmentRequest, SmartCode, SmartTypes } from '@mya/models';
import { AppointmentService } from '../../services/appointment.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SmartTypesService } from '../../services/smart-types.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-cancel-appointment-modal',
  templateUrl: './cancel-appointment-modal.component.html',
  styleUrls: ['./cancel-appointment-modal.component.scss'],
})
export class CancelAppointmentModalComponent implements OnInit, OnDestroy {
  @Input() appointmentCancelReason: string | null = null;
  @Input() isAppointmentRefundable: boolean | null = null;
  @Input() selectedAppointmentId: string | null = null;
  subscriptions: Subs[] = [];
  elementId = ModalConstant.CANCEL_APPOINTMENT_MODAL;
  showAppointmentCancelComments = false;
  appointmentCancelReasons: SmartCode[] = [];

  appointmentCancelForm = this.formBuilder.group({
    appointmentCancelReason: this.formBuilder.group({}),
    appointmentCancelReasonOther: [null as string | null, []],
  })

  constructor(private formBuilder: FormBuilder, private appointmentService: AppointmentService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private smartTypesService: SmartTypesService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.AppointmentCancelReason).subscribe(smartCodes => {
      this.generateCheckBoxControls(smartCodes, 'appointmentCancelReason', true);
      this.appointmentCancelReasons = smartCodes;
    }));
  }

  generateCheckBoxControls(smartCodes: SmartCode[], formGroupName: string, required: boolean) {
    if (smartCodes.length > 0) {
      const formGroup = required ? new FormGroup({}, [this.concernCheckboxGroupValidator(smartCodes, formGroupName)]) : this.formBuilder.group({});

      for (const smartCode of smartCodes) {
        formGroup.addControl(smartCode.id, new FormControl(false));
      }
      this.appointmentCancelForm.setControl(formGroupName as any, formGroup, { emitEvent: false });
    }
  }

  concernCheckboxGroupValidator(smartCodes: SmartCode[], formGroupName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      for (const smartCode of smartCodes) {
        if (this.appointmentCancelForm.get(formGroupName)?.get(smartCode.id)?.value === true) {
          return null;
        }
      }

      return { [formGroupName]: true };
    }
  }

  onAppointmentCancelReasonChanged(event: any, smartCode: SmartCode) {
    if (smartCode.code === 'OT') {
      this.showAppointmentCancelComments = event.target.checked;

      if (this.showAppointmentCancelComments) {
        this.appointmentCancelForm.controls.appointmentCancelReasonOther.setValidators(Validators.required);
      } else {
        this.appointmentCancelForm.controls.appointmentCancelReasonOther.removeValidators(Validators.required);
        this.appointmentCancelForm.controls.appointmentCancelReasonOther.reset();
      }

      this.appointmentCancelForm.controls.appointmentCancelReasonOther.updateValueAndValidity();
    }

  }

  onAppointmentCancelReasonSelected(reason: string, isRefundable: boolean) {
    this.appointmentCancelReason = reason;
    this.isAppointmentRefundable = isRefundable;
  }

  cancelAppointment() {
    if (this.selectedAppointmentId && this.isAppointmentRefundable && this.appointmentCancelReason) {

      const request: CancelAppointmentRequest = {
        appointmentId: this.selectedAppointmentId,
        isRefundable: this.isAppointmentRefundable,
        cancellationReason: this.appointmentCancelReason,
        appointmentCancelReasons: []
      };

      for (const appointmentCancelReason of this.appointmentCancelReasons) {
        if (this.appointmentCancelForm.controls.appointmentCancelReason.get(appointmentCancelReason.id)?.value === true) {
          request.appointmentCancelReasons.push({
            id: uuid(),
            appointmentId: this.selectedAppointmentId,
            smartCodeId: appointmentCancelReason.id,
            additionalComments: appointmentCancelReason.code === 'OT' ? this.appointmentCancelForm.controls.appointmentCancelReasonOther.value : null
          });
        }
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.appointmentService.cancelAppointment(request, loaderIdentifier).subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
        toastr.success("Appointment cancelled");
        this.modalService.hide(ModalConstant.CANCEL_APPOINTMENT_MODAL);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

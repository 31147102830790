import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ReportTypes } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxPieChartComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { exportWidgets } from 'devextreme/viz/export';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-report-consultation-flow',
  templateUrl: './report-consultation-flow.component.html',
  styleUrls: ['./report-consultation-flow.component.scss'],
})
export class ReportConsultationFlowComponent implements OnInit, OnDestroy {
  @ViewChildren('charts') charts!: QueryList<DxPieChartComponent>;
  @Input() dateRange: [Date, Date];
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  pieCharts = ['Cities', 'Keywords', 'TimeZones', 'Programs', 'Issues'];
  titles: Record<string, string> = {
    'Cities': 'Cities',
    'Keywords': 'Keywords',
    'TimeZones': 'Time Zones',
    'Issues': 'Issues',
    'Programs': 'Programs'
  };
  palletes: Record<string, string> = {
    'Cities': 'Soft Pastel',
    'Keywords': 'Harmony Light',
    'TimeZones': 'Pastel',
    'Issues': 'Bright',
    'Programs': 'Soft'
  };
  dataSources: Record<string, DataSource | null> = {
    'Cities': null,
    'Keywords': null,
    'TimeZones': null,
    'Issues': null,
    'Programs': null,
  };
  isEmptySource: Record<string, boolean> = {
    'Cities': true,
    'Keywords': true,
    'TimeZones': true,
    'Issues': true,
    'Programs': true,
  };

  get hasData() {
    return this.pieCharts.some(i => !this.isEmptySource[i]);
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private reportsService: ReportsService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate]
  }

  ngOnInit(): void {
    this.subscriptions.push(this.reportsService.getReports(ReportTypes.ConsultationFlow, this.dateRange[0], this.dateRange[1], null)
      .subscribe((result: any) => {
        for (const data of (result.result.data as any[])) {
          this.dataSources[data.key] = (data.value as any[]).length > 0 ? new DataSource(data.value) : new DataSource([{ "item1": "No Data", "item2": 1 }]);
          this.isEmptySource[data.key] = (data.value as any[]).length == 0;
        }
      }));
  }

  customizeLabel(arg: any) {
    return (arg.percent == 1 && arg.argument == 'No Data') ? arg.argument : `${arg.argument} | ${arg.valueText} (${arg.percentText})`;
  }

  export() {
    exportWidgets(this.charts.map((i) => [i.instance]), {
      fileName: 'Consultation Flow',
      format: 'PDF'
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

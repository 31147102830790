import { AfterContentChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, EventClickArg, EventInput, EventMountArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { AppointmentService } from '../../../services/appointment.service';
import { InternalUserService } from '../../../services/internal-user.service';
import { Appointment, CalendarEvent, InternalUser, InternalUserClaimTypes, InternalUserRoles } from '@mya/models';
import { DatePipe } from '@angular/common';
import { DateTimeService } from '../../../services/date-time.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { JwtService } from '../../../services/jwt.service';
import { NIL as NIL_UUID } from 'uuid';
import { Subscription as Subs } from 'rxjs';
import { ModalConstant } from '../../../common/constants/modal.constant';
import { ModalService } from '../../../services/modal.service';
declare const bootstrap: any;

@Component({
  selector: 'mya-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  startDate: Date | null = null;
  endDate: Date | null = null;
  mentor: InternalUser | null = null;
  currentAppointments = true;
  otherAppointments = false;
  outlookEvents = false;
  subscriptions: Subs[] = [];
  appointments: Appointment[] = [];
  calendarEvents: CalendarEvent[] = [];
  selectedMeetingUrl: string | null = null;
  overriddenDate: Date | null = null;
  timezoneOffset = 0;
  roles: any;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin],
    datesSet: this.handleDatesSet.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    initialView: 'dayGridMonth',
    contentHeight: "70vh",
    eventClassNames: ['px-1', 'text-white'],
    firstDay: 1,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    headerToolbar: {
      left: 'prev,next,today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }
  };

  get isSuperAdmin() {
    return (Array.isArray(this.roles) && this.roles.some(i => i === InternalUserRoles.Admin)) || this.roles === InternalUserRoles.Admin;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private appointmentService: AppointmentService,
    private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private internalUserService: InternalUserService,
    private datePipe: DatePipe,
    private modalService: ModalService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => {
      this.overriddenDate = date;
      this.timezoneOffset = this.currentDate.getTimezoneOffset() * 60000;
    }));
  }
  
  ngOnInit(): void {
    this.subscriptions.push(this.authenticationService.getToken().subscribe(token => {
      const decodedToken: any = this.jwtService.DecodeToken(token);
      this.roles = decodedToken[InternalUserClaimTypes.Roles];
    }));
    this.subscriptions.push(this.internalUserService.Mentor$.subscribe(user => {
      this.mentor = user
      this.getAppointments();
    }));
  }
  
  ngAfterViewInit(): void {
    this.subscriptions.push(this.appointmentService.UserAppointments$.subscribe(appointments => {
      this.appointments = appointments;
      this.generateEvents();
    }));
    
    this.subscriptions.push(this.internalUserService.CalendarEvents$.subscribe(events => {
      this.calendarEvents = events;
      this.generateEvents();
    }));
  }
  
  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.JOIN_APPOINTMENT_MODAL);
  }
  
  handleEventDidMount(eventInfo: EventMountArg) {
    new bootstrap.Tooltip(eventInfo.el, {
      title: eventInfo.event.title,
      delay: { "show": 500, "hide": 100 }
    });
  }

  handleDatesSet() {
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      this.startDate = calendarApi.view.currentStart;
      this.endDate = calendarApi.view.currentEnd;
      this.getAppointments();
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.selectedMeetingUrl = clickInfo.event.extendedProps['meetingUrl'];
    if (this.selectedMeetingUrl) {
      this.modalService.show(ModalConstant.JOIN_APPOINTMENT_MODAL);
    }
  }
  
  getAppointments() {
    if (this.mentor && this.startDate && this.endDate) {
      if (this.currentAppointments) {
        this.appointmentService.getAppointmentsByUser(this.startDate, this.endDate);
      }

      if (this.outlookEvents) {
        this.internalUserService.getCalendarEventsByUser(this.mentor.emailAddress, this.startDate, this.endDate);
      }
    }
  }

  currentAppointmentsChecked() {
    if (this.appointments.length == 0 && this.currentAppointments && this.mentor && this.startDate && this.endDate) {
      this.appointmentService.getAppointmentsByUser(this.startDate, this.endDate);
      return;
    }
    this.generateEvents();
  }

  otherAppointmentsChecked() {
    if (this.appointments.length == 0 && this.otherAppointments && this.mentor && this.startDate && this.endDate) {
      this.appointmentService.getAppointmentsByUser(this.startDate, this.endDate);
      return;
    }
    this.generateEvents();
  }

  outlookEventsChecked() {
    if (this.calendarEvents.length == 0 && this.outlookEvents && this.mentor && this.startDate && this.endDate) {
      this.internalUserService.getCalendarEventsByUser(this.mentor.emailAddress, this.startDate, this.endDate);
      return;
    }
    this.generateEvents();
  }

  generateEvents() {
    const appointmentEvents = this.appointments.filter(i => (i.meetingUrl != NIL_UUID && this.currentAppointments) || (i.meetingUrl == NIL_UUID && this.otherAppointments)).map((appointment) =>
      <EventInput>{
        title: appointment.title,
        start: this.datePipe.transform(new Date(new Date(appointment.startTime).getTime() - this.timezoneOffset), 'yyyy-MM-ddTHH:mm:ss'),
        end: this.datePipe.transform(new Date(new Date(appointment.startTime).getTime() + (appointment.durationInMinutes * 60000) - this.timezoneOffset), 'yyyy-MM-ddTHH:mm:ss'),
        className: this.getAppointmentClassName(appointment),
        extendedProps: {
          appointmentId: appointment.id,
          bookingAppointmentId: appointment.bookingAppointmentId,
          meetingUrl: this.isAppointmentJoinable(appointment) ? appointment.meetingUrl : null
        }
      });

    const calendarEvents = !this.outlookEvents ? [] : this.calendarEvents.map((calendarEvent) =>
      <EventInput>{
        title: `${calendarEvent.subject}`,
        start: this.datePipe.transform(new Date(new Date(calendarEvent.start.dateTime).getTime() - this.timezoneOffset), 'yyyy-MM-ddTHH:mm:ss'),
        end: this.datePipe.transform(new Date(new Date(calendarEvent.end.dateTime).getTime() - this.timezoneOffset), 'yyyy-MM-ddTHH:mm:ss'),
        className: 'calendar-outlook cursor-default'
      });

    const events: EventInput[] = [];
    if (appointmentEvents && appointmentEvents.length > 0) {
      events.push(...appointmentEvents);
    }

    if (calendarEvents && calendarEvents.length > 0) {
      events.push(...calendarEvents);
    }

    if (this.calendarComponent) {
      this.calendarComponent.events = events;
    }
  }

  getAppointmentClassName(appointment: Appointment) {
    if (appointment.meetingUrl == NIL_UUID) {
      return 'calendar-other-mentee cursor-default';
    }

    if (appointment.rescheduledAppointmentId != null) {
      return 'bg-warning cursor-default';
    }

    if (appointment.cancellationDate != null) {
      return 'bg-danger cursor-default';
    }

    return 'calendar-current-mentee cursor-pointer';
  }

  isAppointmentJoinable(appointment: Appointment){
    if (appointment.meetingUrl == NIL_UUID) {
      return false;
    }

    if (appointment.rescheduledAppointmentId != null) {
      return false;
    }

    if (appointment.cancellationDate != null) {
      return false;
    }

    return true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="container-fluid h-100 pt-3 mya-mentee-detail">
    <div class="row h-100">
        <div class="col-12">
            <div class="row h-100">
                <div class="col-12 col-md-3 mt-1 d-flex flex-column">
                    <div class="card mb-3" *ngIf="mentee">
                        <div class="card-body">
                            <div class="card-title mb-0">
                                <h4 class="mb-0 d-inline">{{mentee.firstName}}
                                    {{mentee.lastName}}</h4>
                                <span class="ms-2 mb-1 badge fs-4"
                                    [ngClass]="(isDangerStatus || isWarningStatus) ? ( isDangerStatus ? 'bg-danger' : 'bg-warning') : 'bg-success'"
                                    style="font-size: 1rem;">{{accountStatusPill}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="card h-100 bg-transparent">
                        <div class="card-body p-0">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="card-title mb-0 d-flex">
                                        <h4 class="mb-0">Mentee Contacts</h4>
                                        <button type="button"
                                            class="justify-content-center btn btn-rounded btn-success ms-auto"
                                            (click)="editUser(null)">
                                            <i class="fas fa-plus align-self-center me-2"></i>
                                            Add
                                        </button>
                                    </div>

                                    <div class="card mt-3 h-90">
                                        <div class="card-body scrollable-content p-0">

                                            <div class="card bg-primary text-white" *ngIf="mentee">
                                                <div class="card-body">
                                                    <i class="fas fa-edit fa-lg float-end cursor-pointer"
                                                        (click)="editUser(mentee)"></i>
                                                    <div class="d-flex no-block align-items-center">
                                                        <div class="user-pic">
                                                            <img src="./assets/images/users/default-profile-pic.png"
                                                                alt="users" class="rounded-circle" width="40" />
                                                        </div>
                                                        <div class="ms-3">
                                                            <h4 class="font-medium mb-0 text-white">
                                                                {{mentee.firstName}}
                                                                {{mentee.lastName}}</h4>
                                                            <span class="badge bg-light text-dark">Mentee</span>
                                                        </div>
                                                    </div>
                                                    <div class="card-text mb-1 mt-2" *ngIf="mentee.emailAddress">
                                                        <i class="far fa-envelope border-end border-primary pe-1"></i>
                                                        {{mentee.emailAddress}}
                                                    </div>
                                                    <div class="card-text mb-1 mt-2" *ngIf="mentee.phoneNumber">
                                                        <i class="fas fa-phone border-end border-primary pe-1"></i>
                                                        {{getMaskedPhoneNumber(mentee)}}
                                                    </div>
                                                    <div class="card-text mb-1 mt-2" *ngIf="mentee.timeZone">
                                                        <i class="fas fa-clock border-end border-primary pe-1"></i>
                                                        {{getTimeZoneById(this.timeZones, mentee.timeZone)}}
                                                    </div>
                                                    <div class="card-text mb-1 mt-2"
                                                        *ngIf="mentee.mailingAddress?.addressLine1 || mentee.mailingAddress?.addressLine2">
                                                        <i class="fas fa-home border-end border-primary pe-1"></i>
                                                        {{mentee.mailingAddress?.addressLine1}}
                                                        {{mentee.mailingAddress?.addressLine2}}
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-container *ngFor="let accountAccess of account?.accountAccesses">
                                                <div class="card bg-success text-white"
                                                    *ngIf="accountAccess.externalUser && userTypes[accountAccess.externalUser.userTypeId]?.code && userTypes[accountAccess.externalUser.userTypeId]?.code !== 'MT'">
                                                    <div class="card-body">
                                                        <i class="fas fa-edit fa-lg float-end cursor-pointer"
                                                            (click)="editUser(accountAccess?.externalUser ?? null)"></i>
                                                        <div class="d-flex no-block align-items-center">
                                                            <div class="user-pic">
                                                                <img src="./assets/images/users/default-profile-pic.png"
                                                                    alt="users" class="rounded-circle" width="40" />
                                                            </div>
                                                            <div class="ms-3">
                                                                <h4 class="font-medium mb-0 text-white">
                                                                    {{accountAccess?.externalUser?.firstName}}
                                                                    {{accountAccess?.externalUser?.lastName}}</h4>
                                                                <span class="badge bg-light text-dark">
                                                                    {{getUserType(accountAccess?.externalUser ??
                                                                    null)}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.emailAddress">
                                                            <i
                                                                class="far fa-envelope border-end border-primary pe-1"></i>
                                                            {{accountAccess?.externalUser?.emailAddress}}
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.phoneNumber">
                                                            <i class="fas fa-phone border-end border-primary pe-1"></i>
                                                            {{getMaskedPhoneNumber(accountAccess?.externalUser ??
                                                            null)}}
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.timeZone">
                                                            <i class="fas fa-clock border-end border-primary pe-1"></i>
                                                            {{getTimeZoneById(this.timeZones,
                                                            accountAccess.externalUser.timeZone)}}
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.mailingAddress?.addressLine1 || accountAccess?.externalUser?.mailingAddress?.addressLine2">
                                                            <i class="fas fa-home border-end border-primary pe-1"></i>
                                                            {{accountAccess?.externalUser?.mailingAddress?.addressLine1}}
                                                            {{accountAccess?.externalUser?.mailingAddress?.addressLine2}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" *ngIf="mentee">
                        <div class="card-body">
                            <div class="row" *ngIf="!isTimeZoneSame">
                                <div class="col-4 mb-2 align-self-center">
                                    <h4>Time zone</h4>
                                </div>
                                <div class="col-8 mb-2 text-center">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input primary check-outline outline-primary"
                                            type="radio" id="mentee" name="timeZone" [(ngModel)]="timeZone"
                                            value="mentee">
                                        <label class="form-check-label" for="mentee">Mentee</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input primary check-outline outline-primary"
                                            type="radio" id="mentor" name="timeZone" [(ngModel)]="timeZone"
                                            value="mentor">
                                        <label class="form-check-label" for="mentor">Mentor</label>
                                    </div>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="account?.accountStatus === AccountStatus.Active || account?.accountStatus === AccountStatus.ResumePending; else accountStatus">
                                <div class="box bg-success mb-3 py-2" *ngIf="activeSubscriptionExpirationDate">
                                    <h5 class="mb-0">
                                        {{activeSubscription?.autoRenew === false ?
                                        'Next Payment Date (Recurring Schedule Starts)' : 'Next Payment Date'}}
                                    </h5>
                                    <p class="m-0">{{activeSubscriptionExpirationDate | date:'EEEE, MMMM d, y'}}</p>
                                </div>
                            </ng-container>

                            <ng-template #accountStatus>
                                <ng-container *ngIf="account?.accountStatus === AccountStatus.PendingCancellation">
                                    <div class="box bg-danger mb-3 py-2" *ngIf="activeSubscriptionExpirationDate">
                                        <h5 class="mb-0">
                                            Cancellation Date
                                        </h5>
                                        <p class="m-0">{{activeSubscriptionExpirationDate | date:'EEEE, MMMM d, y'}}</p>
                                    </div>
                                </ng-container>
                            </ng-template>


                            <div class="mt-3 box bg-warning mb-3 py-2">
                                <h5 class="mb-0">
                                    Next Session
                                </h5>
                                <p class="m-0" *ngIf="adjustedNextSession; else elseNextSession">{{adjustedNextSession |
                                    date:'h:mm a'}}
                                    | {{adjustedNextSession | date:'EEEE, MMMM d, y'}}</p>
                                <ng-template #elseNextSession>
                                    <p class="m-0">None</p>
                                </ng-template>
                            </div>
                            <div class="mt-3 box bg-warning py-2">
                                <h5 class="mb-0">
                                    Last Session
                                </h5>
                                <p class="m-0" *ngIf="adjustedLastSession; else elseLastSession">{{adjustedLastSession |
                                    date:'h:mm a'}}
                                    | {{adjustedLastSession | date:'EEEE, MMMM d, y'}}</p>
                                <ng-template #elseLastSession>
                                    <p class="m-0">None</p>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-7 mt-1">
                    <ng-container>
                        <div class="collapse" id="collapseConsultationForm">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title mb-4">
                                        <h4>Consultation Form</h4>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="card-title mb-0">Program Preference</h5>
                                            <div class="mb-3">
                                                <ng-container *ngFor="let mentoringProgram of accountMentoringPrograms">
                                                    <span class="badge bg-primary m-1">
                                                        {{mentoringPrograms[mentoringProgram.smartCodeId]?.label}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h5 class="card-title mb-0">Starting With</h5>
                                            <div class="mb-3">
                                                <ng-container *ngFor="let mentoringSystem of accountMentoringSystems">
                                                    <span class="badge bg-primary m-1">
                                                        {{mentoringSystems[mentoringSystem.smartCodeId]?.label}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h5 class="card-title mb-0">Area of Concerns</h5>
                                            <div class="mb-3">
                                                <ng-container *ngFor="let concern of accountConcerns">
                                                    <span class="badge bg-primary m-1">
                                                        {{concerns[concern.smartCodeId]?.code === 'O' ?
                                                        concerns[concern.smartCodeId]?.description :
                                                        concerns[concern.smartCodeId]?.label}}</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 truncated">
                                            <h5 class="card-title mb-0">Tell us a bit about your situation</h5>
                                            <p class="collapsible" [class.collapsed]="isClassActive"
                                                (click)="isClassActive = !isClassActive">{{account?.aboutYourself ??
                                                "-"}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                    <mya-notes [accountId]="accountId" *ngIf="accountId"
                        (reloadAccount)="loadAccountDetails()"></mya-notes>
                </div>

                <div class="col-12 col-md-2 mt-1">
                    <div class="card" *ngIf="accountPlan">
                        <div class="card-body">
                            <div class="card-title mb-0">
                                <h4 class="mb-3">Plan</h4>
                                <div class="box bg-success py-2">
                                    <h5 class="mb-0" >
                                        {{accountPlan}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="card-title mb-0">
                                <h4 class="mb-0">Actions</h4>
                            </div>

                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button" (click)="showConsultationForm = !showConsultationForm"
                                        class="btn waves-effect waves-light btn-primary w-100" data-bs-toggle="collapse"
                                        data-bs-target="#collapseConsultationForm">
                                        {{showConsultationForm ? 'Hide' : 'View'}} Consultation Form</button>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button" class="btn waves-effect waves-light btn-primary w-100"
                                        (click)="navigateToView(MenteeDetailViews.ScheduleAppointment)"
                                        [disabled]="!((account?.accountStatus === AccountStatus.Active || account?.accountStatus === AccountStatus.PendingCancellation || account?.accountStatus === AccountStatus.ResumePending) && activeSubscription?.autoRenew)">
                                        Schedule One Time Appointment
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button" class="btn waves-effect waves-light btn-primary w-100"
                                        (click)="navigateToView(MenteeDetailViews.ViewAppointments)">
                                        View Upcoming Sessions
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button" class="btn waves-effect waves-light btn-primary w-100"
                                        [disabled]="!(account?.accountStatus === AccountStatus.Active && activeSubscription && !isHourlyPlan)"
                                        (click)="manageBlockSchedules()">
                                        Review Schedule
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3"
                                *ngIf="(account?.accountStatus === AccountStatus.Active || account?.accountStatus === AccountStatus.ResumePending)">
                                <div class="text-center">
                                    <button type="button" class="btn waves-effect waves-light btn-primary w-100"
                                        [disabled]="!((account?.accountStatus === AccountStatus.Active && activeSubscription && (activeSubscription.autoRenew)) || account?.accountStatus === AccountStatus.ResumePending)"
                                        (click)="changePlanModal.open()">
                                        Change Plan
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="text-center">
                                    <button *ngIf="account?.accountStatus === AccountStatus.Active && !isHourlyPlan"
                                        (click)="cancelPlan()" type="button"
                                        class="btn waves-effect waves-light btn-danger w-100 mt-4">
                                        Cancel Mentoring Plan
                                    </button>

                                    <button
                                        *ngIf="account?.accountStatus === AccountStatus.Suspended || account?.accountStatus === AccountStatus.Cancelled"
                                        type="button" class="btn waves-effect waves-light btn-success w-100 mt-4"
                                        (click)="changePlanModal.open()">
                                        Reactivate Account
                                    </button>

                                    <button *ngIf="account?.accountStatus === AccountStatus.PendingCancellation"
                                        type="button" class="btn waves-effect waves-light btn-success w-100 mt-4"
                                        (click)="changePlanModal.open()">
                                        Resume Mentoring Plan
                                    </button>

                                    <ng-container *ngIf="isLastPlanHourly">
                                        <button
                                            *ngIf="account?.accountStatus === AccountStatus.TrialEnded;else elseBlock"
                                            type="button" class="btn waves-effect waves-light btn-success w-100 mt-4"
                                            (click)="changePlanModal.open()">
                                            Continue Trial
                                        </button>

                                        <ng-template #elseBlock>
                                            <div class="d-flex assign-mentor-container w-100"
                                                popoverTitle="Requirements" placement="bottom"
                                                triggers="mouseenter:mouseleave" [ngbPopover]="popContent">
                                                <ng-template #popContent>
                                                    <ul>
                                                        <li>Will be available at the end of the trial.</li>
                                                    </ul>
                                                </ng-template>
                                                <button type="button" disabled
                                                    class="btn waves-effect waves-light btn-success w-100 mt-4">
                                                    <i class="fas fa-exclamation-circle fill-white me-2"></i>
                                                    Continue Trial
                                                </button>
                                            </div>
                                        </ng-template>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<mya-external-user-modal (addUser)="onAddUser($event)" (updateUser)="onUpdateUser($event)"></mya-external-user-modal>

<mya-cancel-plan-modal [accountId]="accountId" (reloadAccount)="loadAccountDetails()"></mya-cancel-plan-modal>

<mya-block-scheduler-modal [accountId]="accountId" [activeSubscription]="activeSubscription"
    (switchToEditMode)="switchToEditMode()"></mya-block-scheduler-modal>

<mya-change-plan-modal [accountId]="accountId" [activeSubscription]="activeSubscription"
    [pendingSubscription]="pendingSubscription"></mya-change-plan-modal>
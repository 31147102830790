import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmartTypesService } from '../../services/smart-types.service';
import {
  Account,
  AccountStatus,
  AppointmentBlock,
  ExternalUser,
  InternalUser,
  PlanPricing,
  ReferenceDataTypes,
  ReferenceItem,
  SmartCode,
  SmartTypes,
  Subscription,
  TimeZoneTypes,
  UpdateAppointmentBlocksByAccountRequest,
  getMentee,
  getMentorType,
  getTimeZoneOffsetById,
} from '@mya/models';
import { FormBuilder, Validators } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { PlanPricingService } from '../../services/plan-pricing.service';
import { AccountService } from '../../services/account.service';
import { AppointmentService } from '../../services/appointment.service';
import { LoaderService } from '../../services/loader.service';
import { OffcanvasService } from '../../services/offcanvas.service';
import { SubscriptionService } from '../../services/subscription.service';
import { OffCanvasConstant } from '../../common/constants/offcanvas.constant';
import { InternalUserService } from '../../services/internal-user.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss'],
})
export class ChangePlanComponent implements OnInit, OnDestroy {
  @Input() accountId: string | null = null;
  @Input() activeSubscription: Subscription | null = null;
  @Input() pendingSubscription: Subscription | null = null;
  @Input() mentoringPlanId: string | null = null;
  overriddenDate: Date | null = null;
  timezoneOffset = 0;
  account: Account | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  planPricings: PlanPricing[] = [];
  mentoringPlanLookup: SmartCode[] = [];
  currentAppointmentBlocks: AppointmentBlock[] = [];
  pendingAppointmentBlocks: AppointmentBlock[] = [];
  timeZones: ReferenceItem[] = [];
  subscriptions: Subs[] = [];
  isAppointmentBlockWindow = false;
  editChanges = false;
  showComponent = true;

  get menteeUTCTimeZoneDiff() {
    return (
      getTimeZoneOffsetById(this.timeZones, this.mentee?.timeZone ?? null) ?? 0
    );
  }

  get mentorUTCTimeZoneDiff() {
    return this.currentDate.getTimezoneOffset() * -1;
  }

  get utcTimeZoneDiff(): number {
    return this.timeZoneForm.controls.timeZone.value === 'mentee'
      ? this.menteeUTCTimeZoneDiff
      : this.mentorUTCTimeZoneDiff;
  }

  get isTimeZoneSame(): boolean {
    return this.menteeUTCTimeZoneDiff == this.mentorUTCTimeZoneDiff;
  }

  get sortedCurrentAppointmentBlocks() {
    return this.currentAppointmentBlocks.sort((a, b) => a.day - b.day);
  }

  get sortedPendingAppointmentBlocks() {
    return this.pendingAppointmentBlocks.sort((a, b) => a.day - b.day);
  }

  get planPricing(): PlanPricing | null {
    const mentorType = getMentorType(this.mentor ?? null);
    if (
      this.planPricings &&
      mentorType &&
      this.planForm.controls.mentoringPlan.value
    ) {
      return (
        this.planPricings.find(
          (i) =>
            i.mentorType == mentorType &&
            i.mentoringPlanId == this.planForm.controls.mentoringPlan.value
        ) ?? null
      );
    }

    return null;
  }

  get isPendingCancellation() {
    return this.account?.accountStatus == AccountStatus.PendingCancellation;
  }

  get isPlanCancelled() {
    return this.account?.accountStatus == AccountStatus.Cancelled;
  }

  get isSuspendedLate() {
    return this.account?.accountStatus == AccountStatus.Suspended;
  }

  get plansAvailable() {
    return this.mentoringPlanLookup.filter(
      (i) =>
        (this.isPlanCancelled ||
          this.isSuspendedLate ||
          this.isPendingCancellation ||
          this.pendingSubscription ||
          i.id != this.account?.mentoringPlanId) &&
        i.code != 'OH'
    );
  }

  get currentPlan() {
    return this.mentoringPlanLookup.find(
      (i) =>
        this.activeSubscription &&
        i.id == this.getMentoringPlanId(this.activeSubscription.planPricingId)
    )?.label;
  }

  get pendingPlan() {
    return this.mentoringPlanLookup.find(
      (i) =>
        this.pendingSubscription &&
        i.id == this.getMentoringPlanId(this.pendingSubscription.planPricingId)
    )?.label;
  }

  get isLastPlanHourly() {
    return (
      this.planPricings.find(
        (i) => i.mentoringPlanId == this.account?.mentoringPlanId
      )?.sessionsPerWeek == null ?? false
    );
  }

  get PendingPlanEffectiveDate() {
    return this.pendingSubscription
      ? new Date(this.pendingSubscription.effectiveDate)
      : this.currentDate;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  planForm = this.formBuilder.group({
    mentoringPlan: ['', [Validators.required]],
  });

  timeZoneForm = this.formBuilder.group({
    timeZone: ['mentee' as TimeZoneTypes, [Validators.required]],
  });

  constructor(
    private accountService: AccountService,
    private smartTypesService: SmartTypesService,
    private planPricingService: PlanPricingService,
    private formBuilder: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private appointmentService: AppointmentService,
    private subscriptionService: SubscriptionService,
    private internalUserService: InternalUserService,
    private loaderService: LoaderService,
    private offcanvasService: OffcanvasService,
    dateTimeService: DateTimeService
  ) {
    this.subscriptions.push(
      dateTimeService.OverriddenDate$.subscribe((date) => {
        this.overriddenDate = date;
        this.timezoneOffset = this.currentDate.getTimezoneOffset() * 60000;
      })
    );
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.referenceDataService.ReferenceData$.subscribe((referenceData) => {
        if (referenceData != null) {
          const references = JSON.parse(referenceData);
          this.timeZones = references[ReferenceDataTypes.TimeZones];
        }
      })
    );

    this.subscriptions.push(
      this.internalUserService.Mentor$.subscribe((user) => (this.mentor = user))
    );
    this.subscriptions.push(
      this.smartTypesService
        .SmartTypes(SmartTypes.MentoringPlan)
        .subscribe((smartCodes) => {
          this.mentoringPlanLookup = smartCodes;
        })
    );

    this.subscriptions.push(
      this.planPricingService.PlanPricing$.subscribe((i) => {
        this.planPricings = i;
      })
    );

    if (this.accountId) {
      this.subscriptions.push(
        this.accountService.getAccount(this.accountId).subscribe((account) => {
          this.account = account;
          this.mentee = getMentee(account);
        })
      );
    }

    if (this.mentoringPlanId) {
      this.planForm.controls.mentoringPlan.setValue(this.mentoringPlanId);
      if (!this.pendingSubscription) {
        this.selectAppointmentBlock();
      }
    }

    if (this.activeSubscription) {
      this.subscriptions.push(
        this.appointmentService
          .getAppointmentBlocksBySubscription(this.activeSubscription.id, true)
          .subscribe((appointmentBlocks) => {
            this.currentAppointmentBlocks = appointmentBlocks;
          })
      );
    }

    if (this.pendingSubscription) {
      this.subscriptions.push(
        this.appointmentService
          .getAppointmentBlocksBySubscription(this.pendingSubscription.id, true)
          .subscribe((appointmentBlocks) => {
            this.pendingAppointmentBlocks = appointmentBlocks;
          })
      );
    }
  }

  loadAccountDetails() {
    if (this.accountId) {
      this.accountService.getAccount(this.accountId, true);
      this.subscriptionService.getActiveSubscriptionByAccount(
        this.accountId as string
      );
      this.subscriptionService.getPendingSubscriptionByAccount(
        this.accountId as string
      );
    }
  }

  getMentoringPlanId(planPricingId: string) {
    return (
      this.planPricings.find((i) => i.id == planPricingId)?.mentoringPlanId ??
      null
    );
  }

  saveChanges(request: UpdateAppointmentBlocksByAccountRequest) {
    if (
      this.accountId &&
      this.planForm.controls.mentoringPlan.value &&
      this.planPricing
    ) {
      let subscriptionEffectiveDate = this.activeSubscription?.expirationDate
        ? new Date(this.activeSubscription.expirationDate).addSeconds(1)
        : this.currentDate.getUTCFullDate();

      if (this.isLastPlanHourly) {
        subscriptionEffectiveDate = subscriptionEffectiveDate.addDays(1);
      }

      request.subscription = {
        id: this.pendingSubscription ? this.pendingSubscription.id : uuid(),
        accountId: this.accountId,
        autoRenew: true,
        effectiveDate: subscriptionEffectiveDate.getUTCFullDateInverse(),
        expirationDate: null,
        planPricingId: this.planPricing.id,
        paymentIntentId: '',
        paymentAttempts: 0,
        isPaid: false,
      };

      request.appointmentBlocks.forEach((appointmentBlock) => {
        if (!this.pendingSubscription) {
          appointmentBlock.subscriptionId = request.subscription?.id as string;
        }

        if (
          this.account?.accountStatus == AccountStatus.Cancelled ||
          this.account?.accountStatus == AccountStatus.Suspended
        ) {
          appointmentBlock.id = uuid();
        }

        appointmentBlock.intervalStartDate =
          appointmentBlock.intervalStartDate != null
            ? new Date(
                appointmentBlock.intervalStartDate
              ).getUTCFullDateInverse()
            : null;
      });

      const loaderIdentifier = uuid();
      this.subscriptions.push(
        this.appointmentService
          .updateAppointmentBlocks(request, loaderIdentifier)
          .subscribe(() => {
            if (this.activeSubscription) {
              toastr.success('Changes saved');
            } else if (this.isLastPlanHourly) {
              toastr.success('Trial renewal in progress');
            } else {
              toastr.success('Account Reactivated');
            }

            this.loaderService.hide(loaderIdentifier);
            this.offcanvasService.hide(OffCanvasConstant.CHANGE_PLAN_OFFCANVAS);
            this.loadAccountDetails();
          })
      );
    }
  }

  selectAppointmentBlock() {
    this.isAppointmentBlockWindow = true;
  }

  editPendingPlan() {
    if (this.pendingSubscription) {
      if (!this.mentoringPlanId) {
        this.editChanges = true;
      } else {
        this.selectAppointmentBlock();
      }
      this.planForm.controls.mentoringPlan.setValue(
        this.getMentoringPlanId(this.pendingSubscription.planPricingId)
      );
    }
  }

  deletePendingPlan() {
    if (this.accountId) {
      const loaderIdentifier = uuid();
      this.subscriptions.push(
        this.subscriptionService
          .deletePendingSubscriptionByAccount(this.accountId, loaderIdentifier)
          .subscribe((data) => {
            toastr.success('Pending changes deleted');
            this.loadAccountDetails();
            this.offcanvasService.hide(OffCanvasConstant.CHANGE_PLAN_OFFCANVAS);
            this.loaderService.hide(loaderIdentifier);
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form class="mb-3" [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div class="modal-header modal-colored-header text-white"
                    [ngClass]="isMentee ? 'bg-primary' : 'bg-success'">
                    <h4 class="modal-title">
                        {{title}}
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label class="required-label" for="firstName">First Name</label>
                            <input class="form-control" type="text" id="firstName" placeholder="Enter"
                                formControlName="firstName" />
                        </div>
                        <div class="col-6 mb-3">
                            <label [ngClass]="{ 'required-label': !isMentee}" for="lastName">Last Name</label>
                            <input class="form-control" type="text" id="lastName" placeholder="Enter"
                                formControlName="lastName" />
                        </div>
                        <div class="col-6 mb-3" *ngIf="!isMentee">
                            <label class="required-label" for="userType">User Type</label>
                            <select class="form-select" (change)="changeUserType($event)" formControlName="userType">
                                <ng-container *ngFor="let userType of userTypeLookup">
                                    <option *ngIf="isMentee || userType.code !== 'MT'" [value]="userType.id">
                                        {{ userType.label }}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-6 mb-3" *ngIf="isOtherUser">
                            <label class="required-label" for="otherUserType">Other User Description</label>
                            <input class="form-control" type="text" id="otherUserType" placeholder="Enter"
                                formControlName="otherUserType" />
                        </div>
                        <div class="col-6 mb-3" *ngIf="isMentee">
                            <label for="type">Phone OS</label>
                            <select class="form-select" (change)="changePhoneOS($event)" formControlName="phoneOS">
                                <ng-container *ngFor="let phoneOS of phoneOSLookup">
                                    <option [value]="phoneOS.id">
                                        {{ phoneOS.label }}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-6 mb-3" *ngIf="isOtherPhoneOS">
                            <label for="otherPhoneOS">Other Phone OS</label>
                            <input class="form-control" type="text" id="otherPhoneOS" placeholder="Enter"
                                formControlName="otherPhoneOS" />
                        </div>
                        <div class="col-6 mb-3" *ngIf="isMentee">
                            <label for="type">Computer OS</label>
                            <select class="form-select" (change)="changeComputerOS($event)"
                                formControlName="computerOS">
                                <ng-container *ngFor="let computerOS of computerOSLookup">
                                    <option [value]="computerOS.id">
                                        {{ computerOS.label }}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-6 mb-3" *ngIf="isOtherComputerOS">
                            <label for="otherComputerOS">Other Computer OS</label>
                            <input class="form-control" type="text" id="otherComputerOS" placeholder="Enter"
                                formControlName="otherComputerOS" />
                        </div>
                        <div class="mb-3 col-6" *ngIf="isMentee">
                            <label class="required-label" for="age">Age</label>
                            <input class="form-control" type="number" id="age" placeholder="Enter" formControlName="age"
                                [imask]="ageMask" />
                        </div>
                        <div class="mb-3 col-6">
                            <label for="dateOfBirth">Date of Birth</label>
                            <input class="form-control" type="date" id="dateOfBirth" placeholder="Enter"
                                formControlName="dateOfBirth" />
                        </div>
                        <div class="mb-3"
                            [ngClass]="isOtherUser || (isOtherComputerOS && !isOtherPhoneOS) || (!isOtherComputerOS && isOtherPhoneOS) ? 'col-6' : 'col-12'">
                            <label class="required-label" for="email">Email</label>
                            <input class="form-control" type="email" id="email" placeholder="Enter"
                                formControlName="email" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="address1">Address 1</label>
                            <input class="form-control" type="text" id="address1" placeholder="Enter"
                                formControlName="address1" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="address2">Address 2</label>
                            <input class="form-control" type="text" id="address2" placeholder="Enter"
                                formControlName="address2" />
                        </div>
                        <div class="col-6 mb-3">
                            <label for="city">City</label>
                            <input class="form-control" type="text" id="city" placeholder="Enter"
                                formControlName="city" />
                        </div>
                        <div class="col-6 mb-3">
                            <label for="state">State</label>
                            <select class="form-select" (change)="changeState($event)" formControlName="state">
                                <option *ngFor="let state of stateLookup" [value]="state.id">
                                    {{ state.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-3">
                            <label for="zip">Zip</label>
                            <input class="form-control" type="text" id="zip" placeholder="Enter"
                                formControlName="zip" />
                        </div>
                        <div class="col-6 mb-3">
                            <label for="country">Country</label>
                            <select id="country" class="form-select" (change)="changeCountry($event)"
                                formControlName="country">
                                <option *ngFor="let country of countryLookup" [value]="country.id">
                                    {{ country.label }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-3">
                            <label for="gender">Gender</label>
                            <select class="form-select" (change)="changeGender($event)" formControlName="gender">
                                <option *ngFor="let gender of genders" [value]="gender">
                                    {{ GenderLookup[gender] }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-3">
                            <label [ngClass]="isMentee ? 'required-label' : ''" for="timeZone">Time Zone</label>
                            <select class="form-select" (change)="changeTimeZone($event)" formControlName="timeZone">
                                <option *ngFor="let timeZone of timeZones" [value]="timeZone.id">
                                    {{ timeZone.description }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 mb-3">
                            <label [ngClass]="isMentee ? '' : 'required-label'" for="phoneNumber">Phone Number</label>
                            <div class="input-group">
                                <button
                                    class="btn btn-light-light text-secondary font-medium dropdown-toggle country-dropdown"
                                    type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="/static/images/blank.gif"
                                        [class]="'flag fnone '+ (userForm.controls.countryCode.value !== null  ? countryCodeLookup[userForm.controls.countryCode.value].code.toLowerCase() : '')">
                                </button>
                                <ul class="dropdown-menu country-code-select" role="presentation"
                                    style="margin-top: 0px; margin-bottom: 0px;">
                                    <ng-container *ngFor="let countryCode of countryCodes">
                                        <li *ngIf="countryCode.order === 2">
                                            <hr class="dropdown-divider">
                                        </li>
                                        <li (click)="changeCountryCode(countryCode)">
                                            <a role="option" class="dropdown-item" id="bs-select-1-1" tabindex="0"
                                                aria-setsize="241" aria-posinset="2">
                                                <span class="text">
                                                    <img src="/static/images/blank.gif"
                                                        [class]="'flag fnone '+ countryCode.code.toLowerCase()">
                                                    <span>{{countryCode.description}}</span>
                                                    <span class="text-muted ms-1">
                                                        +{{countryCode.label}}
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ul>
                                <input class="form-control" type="text" id="phoneNumber" placeholder="Enter"
                                    [imask]="phoneMask" formControlName="phoneNumber" />
                            </div>
                        </div>
                        <div class="col-6 mb-3">
                            <label [ngClass]="isMentee ? '' : 'required-label'">Can Receive Texts</label>
                            <div class="d-flex mt-2">
                                <div class="form-check ms-2">
                                    <input id="receiveTexts-yes" class="form-check-input" type="radio"
                                        formControlName="receiveTexts" [value]=true>
                                    <label class="form-check-label" for="receiveTexts-yes">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check ms-2">
                                    <input id="receiveTexts-no" class="form-check-input" type="radio"
                                        formControlName="receiveTexts" [value]=false>
                                    <label class="form-check-label" for="receiveTexts-no">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mb-3">
                            <label for="typeDescription">Description</label>
                            <textarea class="form-control" id="typeDescription" formControlName="typeDescription"
                                placeholder="Enter"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="submit" class="btn font-medium"
                        [ngClass]="!userForm.valid ? 'btn btn-light' : (isMentee ? 'btn-light-primary text-primary' : 'btn-light-success text-success')"
                        [disabled]="!userForm.valid">
                        Save changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportTypes, ReportTypesLookup } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Subscription as Subs } from 'rxjs';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';

@Component({
  selector: 'mya-report-accounts-payable',
  templateUrl: './report-accounts-payable.component.html',
  styleUrls: ['./report-accounts-payable.component.scss'],
})
export class ReportAccountsPayableComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid!: DxDataGridComponent;
  @ViewChild('summaryGrid1') summaryGrid1!: DxDataGridComponent;
  @ViewChild('summaryGrid2') summaryGrid2!: DxDataGridComponent;
  @Input() dateRange: [Date, Date];

  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  dataSource: any[] | null = null;
  summaryDataSource1: any[] | null = null;
  summaryDataSource2: any[] | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private reportsService: ReportsService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate];
  }

  ngOnInit(): void {
    this.loadDataSource(this.dateRange[0], this.dateRange[1]);
  }

  loadDataSource(startDate: Date, endDate: Date) {
    this.subscriptions.push(this.reportsService.getReports(ReportTypes.AccountsPayable, startDate, endDate, null)
      .subscribe((result: any) => {
        this.dataSource = result.result.data;
        this.summaryDataSource1 = this.loadSummaryDataSource1(result.result.data);
        this.summaryDataSource2 = this.loadSummaryDataSource2(result.result.data);
      }));
  }

  loadSummaryDataSource1(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (filteredRecords.findIndex(i => i.appointmentType == record.appointmentType) == -1) {
        const hours = records.filter(i => i.appointmentType == record.appointmentType).reduce((n, { hours }) => n + hours, 0);
        filteredRecords.push({
          appointmentType: record.appointmentType,
          price: record.price,
          hours: hours,
          total: hours * record.price
        });
      }
    }
    return filteredRecords;
  }

  loadSummaryDataSource2(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (filteredRecords.findIndex(i => i.appointmentType == record.appointmentType && i.emailAddress == record.emailAddress) == -1) {
        const hours = records.filter(i => i.appointmentType == record.appointmentType && i.emailAddress == record.emailAddress).reduce((n, { hours }) => n + hours, 0);
        filteredRecords.push({
          emailAddress: record.emailAddress,
          name: `${record.firstName} ${record.lastName}`,
          appointmentType: record.appointmentType,
          price: record.price,
          hours: hours,
          total: hours * record.price
        });
      }
    }
    return filteredRecords;
  }

  onExporting() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    const summaryRow = worksheet.insertRow(1, ["Accounts Payable Preview"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.grid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      this.exportSummaryGrid1(worksheet, workbook);
    });
  }

  exportSummaryGrid1(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow((worksheet.lastRow?.number ?? 1) + 2, ["Appointments Summary"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid1.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      this.exportSummaryGrid2(worksheet, workbook);
    });
  }

  exportSummaryGrid2(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow((worksheet.lastRow?.number ?? 1) + 2, ["Breakdown by Contractor"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid2.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      worksheet.columns.forEach(column => {
        column.width = 30;
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${ReportTypesLookup[ReportTypes.AccountsPayable]} Report.xlsx`);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Gender, GenderLookup, ExternalUser, ReferenceItem, SmartCode, ReferenceDataTypes, SmartTypes } from '@mya/models';
import { SmartTypesService } from '../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { ReferenceDataService } from '../../services/reference-data.service';
import { ModalService } from '../../services/modal.service';
import { ModalConstant } from '../../common/constants/modal.constant';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-external-user-modal',
  templateUrl: './external-user-modal.component.html',
  styleUrls: ['./external-user-modal.component.scss'],
})
export class ExternalUserModalComponent implements OnInit, OnDestroy {
  @Output() addUser = new EventEmitter<ExternalUser>();
  @Output() updateUser = new EventEmitter<ExternalUser>();
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  elementId = ModalConstant.EXTERNAL_USER_MODAL;
  title = "Add User";
  accoundId: string | null = null;
  user: ExternalUser | null = null;
  genders = Object.values(Gender).filter(value => typeof value === 'number') as Gender[];
  userTypeLookup: SmartCode[] = [];
  states: SmartCode[] = [];
  countries: Record<string, SmartCode> = {};
  countryLookup: SmartCode[] = [];
  countryCodes: SmartCode[] = [];
  countryCodeLookup: Record<string, SmartCode> = {};
  computerOSLookup: SmartCode[] = [];
  phoneOSLookup: SmartCode[] = [];
  userTypes: Record<string, SmartCode> = {};
  timeZones: ReferenceItem[] = [];
  phoneMask: any = {
    mask: '(000) 000-0000'
  };
  ageMask: any = {
    mask: Number,
    min: 1,
    max: 99
  };

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  get GenderLookup() {
    return GenderLookup;
  }

  get isMentee() {
    return this.user?.userTypeId && this.userTypes[this.user?.userTypeId].code === 'MT';
  }

  get isOtherUser() {
    return this.userTypeLookup.find(i => i.id == this.userForm.controls.userType.value)?.code === 'O';
  }

  get isOtherComputerOS() {
    return this.computerOSLookup.find(i => i.id == this.userForm.controls.computerOS.value)?.code === 'O';
  }

  get isOtherPhoneOS() {
    return this.phoneOSLookup.find(i => i.id == this.userForm.controls.phoneOS.value)?.code === 'O';
  }

  get stateLookup() {
    return this.states.filter(i => i.subTypeSmartCodeId == this.userForm.controls.country.value);
  }

  get isNorthAmericanCountry() {
    return this.countries[this.userForm.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' || this.countries[this.userForm.controls.country.value ?? '']?.code?.toLowerCase() == 'us';
  }

  get stateTitle() {
    return this.countries[this.userForm.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' ? 'Province' : 'State';
  }

  get zipTitle() {
    return this.countries[this.userForm.controls.country.value ?? '']?.code?.toLowerCase() == 'ca' ? 'Postal Code' : 'ZIP Code';
  }

  userForm = this.formBuilder.group({
    dateOfBirth: [null as string | null, []],
    age: [null as number | null, []],
    gender: [null as Gender | null, []],
    firstName: [null as string | null, [Validators.required]],
    lastName: [null as string | null, []],
    email: [null as string | null, [Validators.required, Validators.email]],
    userType: [null as string | null, [Validators.required]],
    otherUserType: [null as string | null, []],
    computerOS: [null as string | null, []],
    otherComputerOS: [null as string | null, []],
    phoneOS: [null as string | null, []],
    timeZone: [null as string | null, []],
    otherPhoneOS: [null as string | null, []],
    typeDescription: [null as string | null, []],
    address1: [null as string | null, []],
    address2: [null as string | null, []],
    city: [null as string | null, []],
    state: [null as string | null, []],
    country: [null as string | null, []],
    zip: [null as string | null, []],
    countryCode: [null as string | null, []],
    phoneNumber: [null as string | null, []],
    receiveTexts: [true, [Validators.required]]
  });

  constructor(private smartTypesService: SmartTypesService,
    private referenceDataService: ReferenceDataService,
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType).subscribe(smartCodes => {
      this.userTypeLookup = smartCodes;
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.State).subscribe(smartCodes => {
      this.states = smartCodes;
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.Country).subscribe(smartCodes => {
      this.countryLookup = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countries[smartCode.id] = smartCode;
      });
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.CountryCode).subscribe(smartCodes => {
      this.countryCodes = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countryCodeLookup[smartCode.id] = smartCode;
      });
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.ComputerOS).subscribe(smartCodes => {
      this.computerOSLookup = smartCodes;
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.PhoneOS).subscribe(smartCodes => {
      this.phoneOSLookup = smartCodes;
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType).subscribe(smartCodes => {
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));
  }

  changeState(e: any) {
    this.userForm.controls.state?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeCountry(e: any) {
    this.setCountryValue(e.target.value);
    this.resetAddressFields();
  }

  changeCountryCode(countryCode: SmartCode) {
    this.userForm.controls.countryCode?.setValue(countryCode.id, {
      onlySelf: true,
    });

    if (countryCode.label == "1") {
      this.phoneMask = {
        mask: '(000) 000-0000'
      };
    } else {
      this.phoneMask = {
        mask: '0000000000000000'
      };

      this.changeDetectorRef.detectChanges();
      if (this.userForm.controls.phoneNumber.value) {
        this.userForm.controls.phoneNumber.setValue(this.userForm.controls.phoneNumber.value.replace(/[^0-9]/g, ''));
      }
    }

    this.updateValidators(this.user?.userTypeId ?? null);
  }

  resetAddressFields() {
    this.userForm.controls.address1?.setValue(null, { onlySelf: true });
    this.userForm.controls.address2?.setValue(null, { onlySelf: true });
    this.userForm.controls.city?.setValue(null, { onlySelf: true });
    this.userForm.controls.state?.setValue(null, { onlySelf: true });
    this.userForm.controls.zip?.setValue(null, { onlySelf: true });
  }

  changeGender(e: any) {
    this.userForm.controls.gender?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeUserType(e: any) {
    this.userForm.controls.userType?.setValue(e.target.value, {
      onlySelf: true,
    });

    const userTypeId = this.userTypeLookup.find(i => i.id == this.userForm.controls.userType.value)?.id ?? null;
    this.updateValidators(userTypeId);
  }

  changeComputerOS(e: any) {
    this.userForm.controls.computerOS?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changePhoneOS(e: any) {
    this.userForm.controls.phoneOS?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeTimeZone(e: any) {
    this.userForm.controls.phoneOS?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  setCountryValue(country: string) {
    this.userForm.controls.country.setValue(country);
    if (this.isNorthAmericanCountry) {
      this.userForm.controls.state.enable();
      this.userForm.controls.zip.enable();
    } else {
      this.userForm.controls.state.reset();
      this.userForm.controls.state.disable();
      this.userForm.controls.zip.reset();
      this.userForm.controls.zip.disable();
    }
  }

  public editUser(user: ExternalUser | null, accoundId: string | null = null) {
    this.ageMask = null;
    this.phoneMask = null;
    this.changeDetectorRef.detectChanges();
    this.accoundId = accoundId;

    this.user = user;
    if (user) {
      this.userForm.patchValue({
        dateOfBirth: user.dateOfBirth ? formatDate(user.dateOfBirth, 'yyyy-MM-dd', 'en') : null,
        age: user.age,
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
        userType: user.userTypeId,
        otherUserType: user.otherUserType,
        computerOS: user.computerOSId,
        otherComputerOS: user.otherComputerOS,
        phoneOS: user.phoneOSId,
        otherPhoneOS: user.otherPhoneOS,
        typeDescription: user.description,
        address1: user.mailingAddress?.addressLine1,
        address2: user.mailingAddress?.addressLine2,
        city: user.mailingAddress?.city,
        state: user.mailingAddress?.stateId,
        country: user.mailingAddress?.countryId,
        zip: user.mailingAddress?.zipCode,
        countryCode: user.countryCodeId,
        phoneNumber: user.phoneNumber,
        timeZone: user.timeZone,
        receiveTexts: user.receiveTexts
      });
      this.title = "Edit User";
    } else {
      this.userForm.reset();
      this.title = "Add User";
    }

    if (!this.userForm.controls.country.value) {
      const country = this.countryLookup.find(i => i.code.toLowerCase() == 'us');
      if (country) {
        this.setCountryValue(country.id);
      }
    }

    if (!this.userForm.controls.countryCode.value) {
      const countryCode = this.countryCodes.find(i => i.code.toLowerCase() == 'us');
      if (countryCode) {
        this.userForm.controls.countryCode.setValue(countryCode.id);
        this.phoneMask = {
          mask: '(000) 000-0000'
        };
      }
    } else if (this.countryCodes.find(i => i.id == this.userForm.controls.countryCode.value)?.label == "1") {
      this.phoneMask = {
        mask: '(000) 000-0000'
      };
    } else {
      this.phoneMask = {
        mask: '0000000000000000'
      };
    }

    if (user && this.userTypes[user.userTypeId].code == 'MT') {
      this.ageMask = {
        mask: Number,
        min: 1,
        max: 99
      };
    }

    this.updateValidators(user?.userTypeId ?? null);
    this.modalService.show(ModalConstant.EXTERNAL_USER_MODAL);
  }

  updateValidators(userTypeId: string | null) {
    if (userTypeId && this.userTypes[userTypeId].code == 'MT') {
      this.userForm.controls.lastName.removeValidators(Validators.required);
      this.userForm.controls.countryCode.removeValidators(Validators.required);
      this.userForm.controls.age.setValidators(Validators.required);
      this.userForm.controls.timeZone.setValidators(Validators.required);
      if (this.countryCodes.find(i => i.id == this.userForm.controls.countryCode.value)?.label == "1") {
        this.userForm.controls.phoneNumber.setValidators([Validators.minLength(14)]);
      } else {
        this.userForm.controls.phoneNumber.setValidators([]);
      }
    } else {
      this.userForm.controls.lastName.setValidators(Validators.required);
      this.userForm.controls.countryCode.setValidators(Validators.required);
      this.userForm.controls.age.removeValidators(Validators.required);
      this.userForm.controls.timeZone.removeValidators(Validators.required);
      if (this.countryCodes.find(i => i.id == this.userForm.controls.countryCode.value)?.label == "1") {
        this.userForm.controls.phoneNumber.setValidators([Validators.required, Validators.minLength(14)]);
      } else {
        this.userForm.controls.phoneNumber.setValidators([Validators.required]);
      }
    }

    if (userTypeId && this.userTypes[userTypeId].code == 'O') {
      this.userForm.controls.otherUserType.setValidators(Validators.required);
    } else {
      this.userForm.controls.otherUserType.removeValidators(Validators.required);
    }

    this.userForm.controls.countryCode.updateValueAndValidity();
    this.userForm.controls.phoneNumber.updateValueAndValidity();
    this.userForm.controls.otherUserType.updateValueAndValidity();
    this.userForm.controls.age.updateValueAndValidity();
    this.userForm.controls.timeZone.updateValueAndValidity();
  }

  onSubmit() {
    if (this.user) {
      this.user.emailAddress = this.userForm.controls.email.value as string;
      this.user.dateOfBirth = new Date(this.userForm.controls.dateOfBirth.value as string);
      this.user.age = this.userForm.controls.age.value as number ?? 0;
      this.user.gender = this.userForm.controls.gender.value ? parseInt(this.userForm.controls.gender.value.toString()) : null;
      this.user.firstName = this.userForm.controls.firstName.value as string;
      this.user.lastName = this.userForm.controls.lastName.value as string;
      this.user.userTypeId = this.userTypeLookup.find(i => i.id == this.userForm.controls.userType.value)?.id as string;
      this.user.otherUserType = this.userForm.controls.otherUserType.value;
      this.user.phoneOSId = this.phoneOSLookup.find(i => i.id == this.userForm.controls.phoneOS.value)?.id ?? null;
      this.user.timeZone = this.userForm.controls.timeZone.value;
      this.user.otherPhoneOS = this.userForm.controls.otherPhoneOS.value;
      this.user.computerOSId = this.computerOSLookup.find(i => i.id == this.userForm.controls.computerOS.value)?.id ?? null;
      this.user.otherComputerOS = this.userForm.controls.otherComputerOS.value;
      this.user.description = this.userForm.controls.typeDescription.value as string;
      this.user.mailingAddress = {
        addressLine1: this.userForm.controls.address1.value as string,
        addressLine2: this.userForm.controls.address2.value as string,
        city: this.userForm.controls.city.value as string,
        stateId: this.isNorthAmericanCountry ? ((this.stateLookup.find(i => i.id == this.userForm.controls.state.value) ?? null)?.id ?? null) : null,
        countryId: this.countryLookup.find(i => i.id == this.userForm.controls.country.value)?.id ?? null,
        zipCode: this.isNorthAmericanCountry ? (this.userForm.controls.zip.value as string) : null
      }
      this.user.phoneNumber = this.userForm.controls.phoneNumber.value?.replace(/[^0-9]/g, '') ?? null;
      this.user.countryCodeId = this.countryCodes.find(i => i.id == this.userForm.controls.countryCode.value)?.id ?? null;
      this.user.receiveTexts = this.userForm.controls.receiveTexts.value as boolean;
      this.updateUser.emit(this.user)
    } else {
      const userId = uuid();
      const user: ExternalUser = <ExternalUser>{
        id: userId,
        emailAddress: this.userForm.controls.email.value as string,
        dateOfBirth: new Date(this.userForm.controls.dateOfBirth.value as string),
        age: this.userForm.controls.age.value as number ?? 0,
        gender: this.userForm.controls.gender.value ? parseInt(this.userForm.controls.gender.value.toString()) : null,
        firstName: this.userForm.controls.firstName.value as string,
        lastName: this.userForm.controls.lastName.value as string,
        userTypeId: this.userTypeLookup.find(i => i.id == this.userForm.controls.userType.value)?.id as string,
        otherUserType: this.userForm.controls.otherUserType.value,
        phoneOSId: this.phoneOSLookup.find(i => i.id == this.userForm.controls.phoneOS.value)?.id ?? null,
        timeZone: this.userForm.controls.timeZone.value,
        otherPhoneOS: this.userForm.controls.otherPhoneOS.value,
        computerOSId: this.computerOSLookup.find(i => i.id == this.userForm.controls.computerOS.value)?.id ?? null,
        otherComputerOS: this.userForm.controls.otherComputerOS.value,
        description: this.userForm.controls.typeDescription.value as string,
        receiveTexts: this.userForm.controls.receiveTexts.value as boolean,
        mailingAddress: {
          addressLine1: this.userForm.controls.address1.value as string,
          addressLine2: this.userForm.controls.address2.value as string,
          city: this.userForm.controls.city.value as string,
          stateId: this.isNorthAmericanCountry ? (this.stateLookup.find(i => i.id == this.userForm.controls.state.value)?.id ?? null) : null,
          countryId: this.countryLookup.find(i => i.id == this.userForm.controls.country.value)?.id ?? null,
          zipCode: this.isNorthAmericanCountry ? (this.userForm.controls.zip.value as string) : null
        },
        countryCodeId: this.countryCodes.find(i => i.id == this.userForm.controls.countryCode.value)?.id ?? null,
        phoneNumber: this.userForm.controls.phoneNumber.value?.replace(/[^0-9]/g, '') ?? null,
        creationDate: this.currentDate,
      };
      this.addUser.emit(user);
    }
    this.modalService.hide(ModalConstant.EXTERNAL_USER_MODAL);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

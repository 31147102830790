import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { GetReferenceDataResult, TimeZoneInfo } from '@mya/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private readonly referenceData = new BehaviorSubject<string | null>(null);
  public readonly ReferenceData$: Observable<string | null> = this.referenceData;

  private readonly timeZoneInfo = new BehaviorSubject<TimeZoneInfo | null>(null);
  public readonly TimeZoneInfo$: Observable<TimeZoneInfo | null> = this.timeZoneInfo;

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.referenceData}api/referencedata`
  }

  public fetchReferenceData() {
    this.subscriptions.push(this.http.get<GetReferenceDataResult>(this.baseApiUrl)
      .subscribe((response) => {
        this.referenceData.next(JSON.stringify(response.referenceData));
      }));
  }

  public getTimeZoneInfo(timezone: string) {
    const subs = this.http.get<TimeZoneInfo>(`${this.baseApiUrl}/get-timezone-info/${encodeURIComponent(timezone)}`)
      .subscribe(timeZoneInfo => {
        this.timeZoneInfo.next(timeZoneInfo);
      });

    this.subscriptions.push(subs);
    return subs;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

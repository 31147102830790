import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportTypes, ReportTypesLookup } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Subscription as Subs } from 'rxjs';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import * as IMask from 'imask';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';

@Component({
  selector: 'mya-report-failed-payment',
  templateUrl: './report-failed-payment.component.html',
  styleUrls: ['./report-failed-payment.component.scss'],
})
export class ReportFailedPaymentComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid!: DxDataGridComponent;
  @ViewChild('summaryGrid') summaryGrid!: DxDataGridComponent;
  @Input() dateRange: [Date, Date];
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  dataSource: any[] | null = null;
  summaryDataSource: any[] | null = null;
  phoneMask = IMask.createMask({
    mask: '(000) 000-0000'
  })

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private reportsService: ReportsService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate];
  }

  ngOnInit(): void {
    this.loadDataSource(this.dateRange[0], this.dateRange[1]);
  }

  loadDataSource(startDate: Date, endDate: Date) {
    this.subscriptions.push(this.reportsService.getReports(ReportTypes.FailedPayment, startDate, endDate, null)
      .subscribe((result: any) => {
        this.dataSource = result.result.data;
        this.summaryDataSource = this.loadSummaryDataSource(result.result.data);
      }));
  }

  loadSummaryDataSource(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (filteredRecords.findIndex(i => i.paymentAttempts == record.paymentAttempts) == -1) {
        filteredRecords.push({
          paymentAttempts: record.paymentAttempts,
          accounts: records.filter(i => i.paymentAttempts == record.paymentAttempts).length
        });
      }
    }
    return filteredRecords;
  }

  fetchParentPhoneNumber = (data: any) => {
    return data.parentCountryCode == 1 ? `+${data.parentCountryCode} ${this.phoneMask.resolve(data.parentPhoneNumber)}` : `+${data.parentCountryCode} ${data.parentPhoneNumber}`;
  }

  onExporting() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    const summaryRow = worksheet.insertRow(1, ["Client"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.grid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      this.exportSummaryGrid(worksheet, workbook);
    });
  }

  exportSummaryGrid(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow((worksheet.lastRow?.number ?? 1) + 2, ["Summary"]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      worksheet.columns.forEach(column => {
        column.width = 30;
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${ReportTypesLookup[ReportTypes.FailedPayment]} Report.xlsx`);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { LoaderService } from './loader.service';
import { GetActiveSubscriptionByAccountResult, GetPendingSubscriptionByAccountResult, GetSubscriptionTokensBySubscriptionResult, Subscription, SubscriptionToken } from '@mya/models';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private readonly activeSubscription = new BehaviorSubject<Subscription | null>(null);
  public readonly ActiveSubscription$: Observable<Subscription | null> = this.activeSubscription;
  private readonly pendingSubscription = new BehaviorSubject<Subscription | null>(null);
  public readonly PendingSubscription$: Observable<Subscription | null> = this.pendingSubscription;
  private readonly subscriptionTokens = new BehaviorSubject<SubscriptionToken[]>([]);
  public readonly SubscriptionTokens$: Observable<SubscriptionToken[]> = this.subscriptionTokens;

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.subscription}api/subscription`
  }

  getPendingSubscriptionByAccount(accountId: string): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetPendingSubscriptionByAccountResult>(`${this.baseApiUrl}/get-pending-subscription-by-account/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.pendingSubscription.next(data.subscription);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getActiveSubscriptionByAccount(accountId: string): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetActiveSubscriptionByAccountResult>(`${this.baseApiUrl}/get-active-subscription-by-account/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.activeSubscription.next(data.subscription);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getSubscriptionTokensBySubscription(subscriptionId: string): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetSubscriptionTokensBySubscriptionResult>(`${this.baseApiUrl}/get-subscription-tokens-by-subscription/${subscriptionId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.subscriptionTokens.next(data.subscriptionTokens);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  deletePendingSubscriptionByAccount(accountId: string, loaderIdentifier: string) {
    this.loaderService.show(loaderIdentifier);
    return this.http.delete(`${this.baseApiUrl}/delete-pending-subscription-by-account/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

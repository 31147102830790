<div class="d-flex flex-column h-100 note-has-grid mya-notes">

    <mya-notes-editor [accountId]="accountId"></mya-notes-editor>

    <div class="mt-1">
        <div class="card mb-0">
            <div class="card-body pb-0">
                <div class="card-title mb-0">
                    <h4 class="mb-0">Mentee History</h4>
                </div>
                <i class="ri-lg cursor-pointer sort-button" [ngClass]="sortAscending ? 'ri-sort-asc' : 'ri-sort-desc'"
                    (click)="sortAscending = !sortAscending"></i>

            </div>
        </div>
        <ul class="nav nav-pills p-3 bg-white mb-2 align-items-center">
            <li class="nav-item">
                <a (click)="historyTypeFilter = null" [ngClass]="{ 'active': !historyTypeFilter}" class="
                  nav-link
                  rounded-pill
                  note-link
                  d-flex
                  align-items-center
                  justify-content-center
                  px-3
                  me-0
                  cursor-pointer
                "><span class="d-none d-md-block font-weight-medium">All Notes</span></a>
            </li>
            <ng-container *ngFor="let historyType of historyTypes">
                <li class="nav-item">
                    <a (click)="historyTypeFilter = historyType"
                        [ngClass]="{ 'active': historyTypeFilter === historyType}" class="
                    nav-link
                    rounded-pill
                    note-link
                    d-flex
                    align-items-center
                    justify-content-center
                    px-3
                    me-0
                    cursor-pointer
                    "><span class="d-none d-md-block font-weight-medium"> {{
                            HistoryTypeLookup[historyType] }}</span></a>
                </li>
            </ng-container>

        </ul>
    </div>

    <div class="card h-100 transparent">
        <div class="card-body scrollable-content p-0">

            <div class="card card-body mb-2" *ngIf="notes.length === 0">
                <h4 class="text-center mb-0">No records</h4>
            </div>

            <ng-container *ngFor="let historyItem of notes">
                <mya-note-card [historyItem]="historyItem" (viewHistoryItem)="viewNote($event)"></mya-note-card>
            </ng-container>

        </div>
    </div>

</div>
<mya-notes-view [historyItem]="selectedHistoryItem"></mya-notes-view>
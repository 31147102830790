import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { CreateAppointmentRequest, CreateAppointmentResult, AppointmentBlock, UpdateAppointmentBlocksByAccountRequest, GetAppointmentBlocksBySubscriptionResult, GetAllAppointmentsResult, Appointment, CancelAppointmentRequest, RescheduleAppointmentRequest, GetUpcomingSessionsResult, GetAppointmentsBySubscriptionResult } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { DatePipe } from '@angular/common';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class AppointmentService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  private _appointmentBlocks: Record<string, BehaviorSubject<AppointmentBlock[]>> = {};

  private readonly appointments = new BehaviorSubject<Appointment[]>([]);
  public readonly Appointments$: Observable<Appointment[]> = this.appointments;

  private readonly userAppointments = new BehaviorSubject<Appointment[]>([]);
  public readonly UserAppointments$: Observable<Appointment[]> = this.userAppointments;

  constructor(private http: HttpClient, private loaderService: LoaderService, private datePipe: DatePipe,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.appointment}api/appointment`
  }

  createAppointment(request: CreateAppointmentRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
        return new Observable(observer => {
          this.subscriptions.push(this.http.post(`${this.baseApiUrl}/create-appointment`, request)
                .pipe(catchError((error: HttpErrorResponse) => {
                    this.loaderService.hide(loaderIdentifier);
                    observer.next({ success: false, error: error });
                    throw error;
                }))
                .subscribe(() => {
                    observer.next({ success: true, errorType: null });
                }));
        });
  }

  getAppointmentsBySubscription(subscriptionId: string): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAppointmentsBySubscriptionResult>(`${this.baseApiUrl}/get-appointments-by-subscription/${subscriptionId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.appointments.next(data.appointments);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getAppointmentBlocksBySubscription(subscriptionId: string, refresh: boolean = false): Observable<AppointmentBlock[]> {
    this.EnsureAppointmentBlocks(subscriptionId, refresh);

    if (!this._appointmentBlocks[subscriptionId])
      this._appointmentBlocks[subscriptionId] = new BehaviorSubject<AppointmentBlock[]>([]);

    return this._appointmentBlocks[subscriptionId];
  }

  private EnsureAppointmentBlocks(subscriptionId: string, refresh: boolean) {
    if (!refresh && this._appointmentBlocks[subscriptionId])
      return;

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.GetAppointmentBlocksBySubscriptionId(subscriptionId, loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);

      if (result == null || result.appointmentBlocks == undefined)
        return;

      this._appointmentBlocks[subscriptionId].next(result.appointmentBlocks);
    }));
  }

  private GetAppointmentBlocksBySubscriptionId(subscriptionId: string, loaderIdentifier: string): Observable<GetAppointmentBlocksBySubscriptionResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetAppointmentBlocksBySubscriptionResult>(`${this.baseApiUrl}/get-appointment-blocks-by-subscription/${subscriptionId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  updateAppointmentBlocks(request: UpdateAppointmentBlocksByAccountRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/update-appointment-blocks-by-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        if (error.status == HttpStatusCode.Conflict) {
          window.location.reload();
        }

        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getAppointmentsByUser(startDate: Date, endDate: Date): void {
    const loaderIdentifier = uuid();
    const formattedStartDate = this.datePipe.transform(new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000), 'yyyy-MM-ddTHH:mm:ss');
    const formatterdEndDate = this.datePipe.transform(new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000), 'yyyy-MM-ddTHH:mm:ss');
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAllAppointmentsResult>(`${this.baseApiUrl}/get-all-appointments/${formattedStartDate}/${formatterdEndDate}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.userAppointments.next(data.appointments);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  cancelAppointment(request: CancelAppointmentRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/cancel-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  rescheduleAppointment(request: RescheduleAppointmentRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/reschedule-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  getUpcomingSessions(accountId: string, loaderIdentifier: string): Observable<GetUpcomingSessionsResult> {
    this.loaderService.show(loaderIdentifier);
    return this.http.get<GetUpcomingSessionsResult>(`${this.baseApiUrl}/get-upcoming-sessions/${accountId}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="row">
                    <div class="col-6 text-center">
                        <button [disabled]="activeSubscription?.autoRenew !== true" type="button"
                            class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                            (click)="openCancelAppointmentModal()">Cancel</button>
                    </div>
                    <div class="col-6 text-center">
                        <button type="button" class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                            [disabled]="!selectedBookingAppointmentId"
                            (click)="openRescheduleAppointmentModal()">Reschedule</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { ChangeDetectorRef, Component } from '@angular/core';
import { InternalUser, ReportTypes } from '@mya/models';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'mya-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  reportType: ReportTypes | null = null;
  dateRange: [Date | null, Date | null] = [null, null];
  mentor: string | null = null;
  dataSource: CustomStore | null = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  generateReport(event: [ReportTypes, [NgbDate, NgbDate], string | null]) {
    const reportType = event[0];
    const dateRange = event[1];

    const startDate = dateRange[0].toDate();
    let endDate = dateRange[1].toDate();
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1, 0, 0, -1);
    this.dateRange = [startDate, endDate];
    this.mentor = event[2];
    
    if (reportType == this.reportType) {
      this.reportType = null;
      this.changeDetectorRef.detectChanges();
    }

    this.reportType = reportType;
  }
}

<div class="row justify-content-center">
    <div class="col-12 text-center">
        <button type="button" class="mt-1 btn waves-effect waves-light btn-info" *ngIf="hasData" (click)="export()">
            Export
        </button>
    </div>
    <ng-container *ngFor="let pie of pieCharts">
        <div class="col-12 h-100" *ngIf="dataSources[pie]">
            <dx-pie-chart #charts class="pie" [title]="titles[pie]" [dataSource]="dataSources[pie]" palette="Material"
                paletteExtensionMode="extrapolate" sizeGroup="piecharts" resolveLabelOverlapping="shift">
                <dxo-legend [visible]="false"></dxo-legend>
                <dxi-series argumentField="item1" valueField="item2">
                    <dxo-label [visible]="true" [customizeText]="customizeLabel" position="outside" backgroundColor="rgba(108,117,125,.5)">
                        <dxo-connector [visible]="true" [width]="1"></dxo-connector>
                    </dxo-label>
                </dxi-series>
            </dx-pie-chart>
        </div>
    </ng-container>
</div>
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { InternalUser, ReportTypes, ReportTypesLookup } from '@mya/models';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { MentorService } from '../../../services/mentor.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-reports-selector',
  templateUrl: './reports-selector.component.html',
  styleUrls: ['./reports-selector.component.scss'],
})
export class ReportsSelectorComponent implements OnInit, OnDestroy {
  @Output() generateReport = new EventEmitter<[ReportTypes, [NgbDate, NgbDate], string | null]>();
  subscriptions: Subs[] = [];
  mentors: InternalUser[] = [];
  reportTypes = (Object.values(ReportTypes).filter(value => typeof value === 'number') as ReportTypes[])
    .sort((one, two) => (ReportTypes[one] > ReportTypes[two] ? 1 : -1));

  get ReportTypes() {
    return ReportTypes;
  }

  get ReportTypesLookup() {
    return ReportTypesLookup;
  }

  form = this.formBuilder.group({
    mentor: [null as string | null, []],
    reportType: [ReportTypes.AccountsPayable, [Validators.required]],
    dateRange: [[null, null] as [NgbDate | null, NgbDate | null], [this.ValidateDateRangeRequired]],
  });

  constructor(private formBuilder: FormBuilder, private mentorService: MentorService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.mentorService.Mentors$.subscribe(mentors => {
      this.mentors = mentors;
      const mentor = this.mentors[0];
      if (mentor && this.form.controls.mentor.value == null) {
        this.form.controls.mentor.setValue(mentor.id);
      }
    }));
  }

  changeReportType(e: any) {
    if (e.target.value == ReportTypes.MentorPerformance) {
      this.mentorService.getAllMentors();
      this.form.controls.mentor.setValidators(Validators.required);
    } else {
      this.form.controls.mentor.setValue(null);
      this.form.controls.mentor.removeValidators(Validators.required);
    }

    this.form.controls.mentor.updateValueAndValidity();
  }

  ValidateDateRangeRequired(control: AbstractControl) {
    const value = control.value as [NgbDate | null, NgbDate | null];
    return value[0] && value[1] ? null : { required: true };
  }

  onSubmit() {
    if (!this.form.valid)
      return;
    
    this.generateReport.emit([this.form.controls.reportType.value as ReportTypes, this.form.controls.dateRange.value as [NgbDate, NgbDate], this.form.controls.mentor.value]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

<div class="row">
    <div class="col-12">
            <div class="d-flex flex-wrap">
                <input id="monday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Monday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="mondaySlots.length === 0"
                     [checked]="selectedDayOfWeek === DayOfWeek.Monday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="monday">Monday</label>
                <input id="tuesday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Tuesday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="tuesdaySlots.length === 0"
                    [checked]="selectedDayOfWeek === DayOfWeek.Tuesday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="tuesday">Tuesday</label>
                <input id="wednesday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Wednesday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="wednesdaySlots.length === 0"
                    [checked]="selectedDayOfWeek === DayOfWeek.Wednesday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="wednesday">Wednesday</label>
                <input id="thursday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Thursday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="thursdaySlots.length === 0"
                    [checked]="selectedDayOfWeek === DayOfWeek.Thursday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="thursday">Thursday</label>
                <input id="friday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Friday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="fridaySlots.length === 0"
                    [checked]="selectedDayOfWeek === DayOfWeek.Friday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="friday">Friday</label>
                <input id="saturday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Saturday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="saturdaySlots.length === 0"
                    [checked]="selectedDayOfWeek === DayOfWeek.Saturday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="saturday">Saturday</label>
                <input id="sunday" type="radio" class="btn-check" name="day" [value]="DayOfWeek.Sunday"
                    (change)="valueChanged($event)" autocomplete="off" [disabled]="sundaySlots.length === 0"
                    [checked]="selectedDayOfWeek === DayOfWeek.Sunday">
                <label class="btn btn-outline-primary rounded-pill font-medium m-1" for="sunday">Sunday</label>
            </div>
    </div>
</div>
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError } from 'rxjs';
import { JwtService } from '../../services/jwt.service';
import { AuthenticationService } from '../../services/authentication.service';
import { InternalUserClaimTypes, InternalUserRoles } from '@mya/models';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate, OnDestroy {
  subscriptions: Subs[] = [];

  constructor(private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(observer => {
      this.subscriptions.push(this.authenticationService.getToken().pipe(catchError((error) => {
        this.router.navigate(['/forbidden']);
        throw error;
      })).subscribe(token => {
        const decodedToken: any = this.jwtService.DecodeToken(token);
        const roles = decodedToken[InternalUserClaimTypes.Roles];
        if ((Array.isArray(roles)
          && roles.some(i => i === InternalUserRoles.Admin)) || roles === InternalUserRoles.Admin) {
          observer.next(true);
        } else {
          this.router.navigate(['/forbidden']);
        }
      }));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from '../components/home/home.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MenteesComponent } from '../components/mentees/mentees.component';
import { ErrorForbiddenComponent } from '../components/errors/error-forbidden/error-forbidden.component';
import { ErrorLayoutComponent } from './error-layout/error-layout.component';
import { RoleGuard } from '../guards/role/role.guard';
import { MenteeDetailBaseComponent } from '../components/mentee-detail-base/mentee-detail-base.component';
import { CoachingHoursComponent } from '../components/coaching-hours/coaching-hours.component';
import { ReportsComponent } from '../components/pages/reports/reports.component';
import { SuperAdminGuard } from '../guards/admin/super-admin.guard';
import { DashboardComponent } from '../components/pages/dashboard/dashboard.component';
import { DateCheckerComponent } from '../components/date-checker/date-checker.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'date-checker', component: DateCheckerComponent },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'home', component:  HomeComponent },
      { path: 'dashboard', component:  DashboardComponent, canActivate: [MsalGuard, RoleGuard] },
      { path: 'mentees', component:  MenteesComponent, canActivate: [MsalGuard, RoleGuard] },
      { path: 'coaching-hours', component:  CoachingHoursComponent, canActivate: [MsalGuard, RoleGuard] },
      { path: 'reports', component:  ReportsComponent, canActivate: [MsalGuard, RoleGuard, SuperAdminGuard] },
      { path: 'mentee-detail', component:  MenteeDetailBaseComponent, canActivate: [MsalGuard, RoleGuard] },
    ]
  },
  {
    path: '',
    component: ErrorLayoutComponent,
    children: [
      { path: 'forbidden', component:  ErrorForbiddenComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }

<div class="container-fluid h-100 pt-3">
  <div class="row">
    <div class="col-sm-12 col-lg-4">
      <div class="card h-100">
        <div class="d-flex border-bottom title-part-padding align-items-center">
          <div>
            <h4 class="card-title mb-0">Select Users</h4>
          </div>
        </div>

        <div class="card-body">
          <div class="mb-3 overflow-y-auto">
            <ul class="list-group">
              <li class="list-group-item cursor-pointer"
                [ngClass]="{ 'active': isUserSelected(accountAccess.externalUser)}"
                *ngFor="let accountAccess of accountAccesses" (click)="selectUser(accountAccess.externalUser)">
                <i class="fas fa-user-circle fa-lg me-2"></i>
                {{accountAccess.externalUser?.firstName}} {{accountAccess.externalUser?.lastName}} &nbsp;&nbsp;
                <span class="badge bg-info text-dark">{{getUserType(accountAccess.externalUser)}}</span>
                <svg *ngIf="isUserSelected(accountAccess.externalUser)" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-check-circle feather-sm float-end">
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-8">

      <div class="card h-100">
        <div class="d-flex border-bottom title-part-padding align-items-center">
          <div class="d-flex w-100 justify-content-between">
            <h4 class="card-title mb-0 d-inline">Select Date and Time</h4>
            <h4 class="card-title mb-0 d-inline" [ngClass]="{'text-danger': subscriptionTokens.length == 0}">Tokens: {{subscriptionTokens.length}}</h4>
          </div>
        </div>

        <div class="card-body">
          <mya-appointment-datepicker class="dark-mode" *ngIf="mentor && showAppointmentDatepicker && currentDate"
            [currentDate]="currentDate" [serviceId]="serviceId" [center]="true"
            [bookingStaffId]="mentor.bookingStaffId"
            (appointmentSlotSelected)="appointmentSlotSelected($event)">
          </mya-appointment-datepicker>
        </div>
      </div>
    </div>

    <div class="col-md-12 d-flex flex-row-reverse mt-3">
      <button class="btn waves-effect waves-light btn-primary px-5"
        [disabled]="selectedUsers.length === 0 || !appointmentSlot || subscriptionTokens.length === 0"
        (click)="bookAppointment()">Book
        Appointment</button>
      <button type="button" class="btn waves-effect waves-light btn-dark px-5 me-3" (click)="back()">
        Back
      </button>
    </div>

  </div>
</div>
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { AppointmentBlock, GetAllMentorsResult, GetAppointmentBlocksByMentorResult, InternalUser } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { LoaderService } from './loader.service';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MentorService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];
  private readonly mentorAppointmentBlocks = new BehaviorSubject<AppointmentBlock[]>([]);
  public readonly MentorAppointmentBlocks$: Observable<AppointmentBlock[]> = this.mentorAppointmentBlocks;
  private readonly mentors = new BehaviorSubject<InternalUser[]>([]);
  public readonly Mentors$: Observable<InternalUser[]> = this.mentors;
  
  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.mentor}api/mentor`
  }

  getAllMentors(): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAllMentorsResult>(this.baseApiUrl + `/get-all-mentors`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.mentors.next(data.internalUsers);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  getAssignedMentees(params: HttpParams){
    return this.http.get(this.baseApiUrl + `/get-assigned-mentees`, { params });
  }

  getAppointmentBlocksByMentor(includePendingBlocks: boolean): void {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAppointmentBlocksByMentorResult>(`${this.baseApiUrl}/get-appointment-blocks-by-mentor/${includePendingBlocks}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.mentorAppointmentBlocks.next(data.appointmentBlocks);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

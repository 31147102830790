<div class="card mb-0">
    <div class="card-body pb-0">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="d-flex align-items-center">
                <div class="p-2 flex-grow-1 bd-highlight"></div>

                <div class="mb-3 me-5">
                    <label for="date">Date</label>
                    <div class="input-group">
                        <input id="date" class="form-control" placeholder="yyyy-mm-dd" name="dp" formControlName="date" readonly="true"
                            ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" />
                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()"
                            type="button"></button>
                    </div>
                </div>

                <div class="mb-3 me-5">
                    <label for="module">Module</label>
                    <select id="module" class="form-select" formControlName="module" (change)="moduleChanged()">
                        <ng-container *ngFor="let module of modules">
                            <option [value]="module">
                                {{ module }}
                            </option>
                        </ng-container>
                    </select>
                </div>

                <div class="mb-3 me-5">
                    <label for="lmp">LMP</label>
                    <select id="lmp" class="form-select" formControlName="lmp">
                        <ng-container *ngFor="let lmp of lmps">
                            <option [value]="lmp">
                                {{ lmp }}
                            </option>
                        </ng-container>
                    </select>
                </div>

                <div class="mb-3 me-5">
                    <label for="coaching-type">Duration</label>
                    <select id="coaching-type" class="form-select" formControlName="coachingType">
                        <ng-container *ngFor="let coachingHourType of coachingHourTypes">
                            <option [value]="coachingHourType">
                                {{ CoachingHourTypesLookup[coachingHourType] }}
                            </option>
                        </ng-container>
                    </select>
                </div>

                <div class="mb-3 me-3">
                    <label for="mit-first-name">MIT First Name</label>
                    <input class="form-control" type="text" id="mit-first-name" placeholder="Enter"
                        formControlName="mitFirstName" />
                </div>

                <div class="mb-3 me-3">
                    <label for="mit-last-name">MIT Last Name</label>
                    <input class="form-control" type="text" id="mit-last-name" placeholder="Enter"
                        formControlName="mitLastName" />
                </div>

                <button *ngIf="!selectedRecord;else elseBlock" class="mt-1 btn waves-effect waves-light btn-success"
                    [disabled]="!form.valid">
                    Save
                </button>

                <ng-template #elseBlock>
                    <div>
                        <button class="mt-1 me-3 btn waves-effect waves-light btn-warning" [disabled]="!form.valid">
                            Update
                        </button>
                        <button type="button" class="mt-1 btn btn-light" (click)="closeEditMode()">
                            Cancel
                        </button>
                    </div>
                </ng-template>

                <div class="p-2 flex-grow-1 bd-highlight"></div>
            </div>
        </form>
    </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { HomeComponent } from '../components/home/home.component';
import { MenteesComponent } from '../components/mentees/mentees.component';
import { ErrorLayoutComponent } from './error-layout/error-layout.component';
import { ErrorForbiddenComponent } from '../components/errors/error-forbidden/error-forbidden.component';
import { DxButtonModule, DxDataGridModule, DxPieChartModule } from 'devextreme-angular';
import { MenteeDetailComponent } from '../components/mentee-detail/mentee-detail.component';
import { MenteeDetailBaseComponent } from '../components/mentee-detail-base/mentee-detail-base.component';
import { ScheduleAppointmentComponent } from '../components/schedule-appointment/schedule-appointment.component';
import { BookingSharedModule } from '@mya/booking-shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockSchedulerComponent } from '../components/block-scheduler/block-scheduler.component';
import { ChangePlanComponent } from '../components/change-plan/change-plan.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MenteeAppointmentsComponent } from '../components/mentee-appointments/mentee-appointments.component';
import { NoteCardComponent } from '../components/note-card/note-card.component';
import { NotesEditorComponent } from '../components/notes-editor/notes-editor.component';
import { NotesViewComponent } from '../components/notes-view/notes-view.component';
import { NotesComponent } from '../components/notes/notes.component';
import { DailyRoutineModalComponent } from '../modals/daily-routine-modal/daily-routine-modal.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CancelPlanModalComponent } from '../modals/cancel-plan-modal/cancel-plan-modal.component';
import { CancelAppointmentModalComponent } from '../modals/cancel-appointment-modal/cancel-appointment-modal.component';
import { EditAppointmentModalComponent } from '../modals/edit-appointment-modal/edit-appointment-modal.component';
import { RescheduleAppointmentModalComponent } from '../modals/reschedule-appointment-modal/reschedule-appointment-modal.component';
import { BlockSchedulerModalComponent } from '../modals/block-scheduler-modal/block-scheduler-modal.component';
import { WwymDriveModalComponent } from '../modals/wwym-drive-modal/wwym-drive-modal.component';
import { CoachingHoursComponent } from '../components/coaching-hours/coaching-hours.component';
import { CoachingHoursEditorComponent } from '../components/coaching-hours-editor/coaching-hours-editor.component';
import { NgbDatepickerModule, NgbPopoverModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportsComponent } from '../components/pages/reports/reports.component';
import { ReportsSelectorComponent } from '../components/shared/reports-selector/reports-selector.component';
import { ComponentsSharedModule } from '@mya/components-shared';
import { ReportsGridComponent } from '../components/shared/reports-grid/reports-grid.component';
import { ReportMenteeAppointmentCancellationComponent } from '../components/shared/reports/report-mentee-appointment-cancellation/report-mentee-appointment-cancellation.component';
import { ReportAccountsPayableComponent } from '../components/shared/reports/report-accounts-payable/report-accounts-payable.component';
import { ReportMenteeAccountCancellationComponent } from '../components/shared/reports/report-mentee-account-cancellation/report-mentee-account-cancellation.component';
import { ReportFailedPaymentComponent } from '../components/shared/reports/report-failed-payment/report-failed-payment.component';
import { ReportMentorPerformanceComponent } from '../components/shared/reports/report-mentor-performance/report-mentor-performance.component';
import { ExternalUserModalComponent } from '../modals/external-user-modal/external-user-modal.component';
import { ReportConsultationFlowComponent } from '../components/shared/reports/report-consultation-flow/report-consultation-flow.component';
import { ChangePlanModalComponent } from '../modals/change-plan-modal/change-plan-modal.component';
import { BlockSchedulerDateRangePickerComponent } from '../components/shared/block-scheduler/block-scheduler-date-range-picker/block-scheduler-date-range-picker.component';
import { BlockSchedulerDaySelectorComponent } from '../components/shared/block-scheduler/block-scheduler-day-selector/block-scheduler-day-selector.component';
import { BlockSchedulerTimeSelectorComponent } from '../components/shared/block-scheduler/block-scheduler-time-selector/block-scheduler-time-selector.component';
import { BlockSchedulerAppointmentBlockCardComponent } from '../components/shared/block-scheduler/block-scheduler-appointment-block-card/block-scheduler-appointment-block-card.component';
import { ClientNotesUrlModalComponent } from '../modals/client-notes-url-modal/client-notes-url-modal.component';
import { DashboardComponent } from '../components/pages/dashboard/dashboard.component';
import { DateCheckerComponent } from '../components/date-checker/date-checker.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    HomeComponent,
    DateCheckerComponent,
    DashboardComponent,
    MenteesComponent,
    MenteeDetailComponent,
    ExternalUserModalComponent,
    ErrorLayoutComponent,
    ErrorForbiddenComponent,
    MenteeDetailBaseComponent,
    ScheduleAppointmentComponent,
    BlockSchedulerComponent,
    MenteeAppointmentsComponent,
    NotesViewComponent,
    NotesComponent,
    NoteCardComponent,
    NotesEditorComponent,
    DailyRoutineModalComponent,
    CancelPlanModalComponent,
    ChangePlanComponent,
    BlockSchedulerModalComponent,
    CancelAppointmentModalComponent,
    EditAppointmentModalComponent,
    RescheduleAppointmentModalComponent,
    WwymDriveModalComponent,
    CoachingHoursComponent,
    CoachingHoursEditorComponent,
    ReportsComponent,
    ReportsSelectorComponent,
    ReportsGridComponent,
    ReportAccountsPayableComponent,
    ReportMenteeAppointmentCancellationComponent,
    ReportMenteeAccountCancellationComponent,
    ReportFailedPaymentComponent,
    ReportConsultationFlowComponent,
    ReportMentorPerformanceComponent,
    ChangePlanModalComponent,
    BlockSchedulerDateRangePickerComponent,
    BlockSchedulerDaySelectorComponent,
    BlockSchedulerTimeSelectorComponent,
    BlockSchedulerAppointmentBlockCardComponent,
    ClientNotesUrlModalComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    ReactiveFormsModule,
    IMaskModule,
    DxDataGridModule,
    DxPieChartModule,
    DxButtonModule,
    BookingSharedModule,
    BrowserAnimationsModule,
    FormsModule,
    FullCalendarModule,
    AngularEditorModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    ComponentsSharedModule],
  exports: [
    MenteeDetailComponent,
    ScheduleAppointmentComponent
  ]
})
export class LayoutModule { }

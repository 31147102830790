<div class="card text-white w-100 card-hover my-1" *ngIf="appointmentBlock"
    [ngClass]="!selectedAppointmentBlock ? 'bg-warning' : (selectedAppointmentBlock.id === appointmentBlock.id ? 'bg-primary' : 'bg-warning dimmed')">
    <div class="card-body">
        <div class="row">
            <div class="col-10">
                <h4 class="card-title mb-1 single-line">
                    {{mentee?.firstName}}
                    {{mentee?.lastName}}'s Appointment
                </h4>
            </div>

            <div class="col-2" *ngIf="!selectedAppointmentBlock && !readonly">
                <div class="d-flex flex-row-reverse align-items-center">
                    <a href="javascript:void(0)" class="link text-danger ms-2" (click)="delete.emit(appointmentBlock)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-trash-2 feather-sm">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                            </path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </a>
                    <a href="javascript:void(0)" class="link me-1" (click)="edit.emit(appointmentBlock)"><svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-edit feather-sm">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                            </path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                            </path>
                        </svg></a>
                </div>

            </div>
        </div>

        <div class="mt-2">
            <div class="d-flex justify-content-between">
                <h5 class="card-text capitalize">
                    {{getAppointmentBlockDay(appointmentBlock)}}
                </h5>
                <h5>
                    {{getAppointmentIntervalStartDate(appointmentBlock)}}
                </h5>
            </div>
        </div>

        <div class="mt-1">
            <div class="d-flex justify-content-between">
                <h6 class="card-text fw-normal text-white-50 mb-0">
                    {{getAppointmentBlockTime(appointmentBlock)}}
                </h6>
                <h6 class="card-text fw-normal text-white-50 mb-0">
                    Duration: {{appointmentBlock.duration}} mins
                </h6>
            </div>
        </div>
    </div>
</div>
import { AfterContentChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Account, AddAccountHistoryRequest, ExternalUser, HistoryAccess, HistoryAttachment, HistoryItem, HistoryTypeEnum, HistoryTypeLookup, InternalUser, getAccountCreator, getMentee, removeFile, selectFile } from '@mya/models';
import { HttpClient } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { AccountService } from '../../services/account.service';
import { HistoryService } from '../../services/history.service';
import { DailyRoutineModalComponent } from '../../modals/daily-routine-modal/daily-routine-modal.component';
import { LoaderService } from '../../services/loader.service';
import { InternalUserService } from '../../services/internal-user.service';
import { ModalService } from '../../services/modal.service';
import { defaultEditorConfig } from '../../common/configs/angular-editor-configs';
import { ModalConstant } from '../../common/constants/modal.constant';
import { WwymDriveModalComponent } from '../../modals/wwym-drive-modal/wwym-drive-modal.component';
import { ClientNotesUrlModalComponent } from '../../modals/client-notes-url-modal/client-notes-url-modal.component';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-notes-editor',
  templateUrl: './notes-editor.component.html',
  styleUrls: ['./notes-editor.component.scss'],
})
export class NotesEditorComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild(DailyRoutineModalComponent) DailyRoutineModalComponent!: DailyRoutineModalComponent;
  @ViewChild(WwymDriveModalComponent) WwymDriveModalComponent!: WwymDriveModalComponent;
  @ViewChild(ClientNotesUrlModalComponent) ClientNotesUrlModalComponent!: ClientNotesUrlModalComponent;
  @ViewChild('fileInput') fileInputRef: ElementRef | null = null;
  @Input() accountId: string | null = null;

  overriddenDate: Date | null = null;
  account: Account | null = null;
  accountCreator: ExternalUser | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  subscriptions: Subs[] = [];
  editorConfig = defaultEditorConfig;
  selectedFiles: File[] = [];
  handleFileInput = selectFile;
  removeFile = removeFile;

  historyTypes = (Object.values(HistoryTypeEnum).filter(value => typeof value === 'number') as HistoryTypeEnum[])
    .sort((one, two) => (HistoryTypeLookup[one] > HistoryTypeLookup[two] ? 1 : -1));
  notesForm = this.formBuilder.group({
    notes: [null as null | string, [Validators.required]],
    notesType: [HistoryTypeEnum.Gcs, [Validators.required]],
    confidentiality: [true, []],
    visibleToParent: [false, []],
    visibleToMentee: [false, []],
    dailyRoutineUrl: [null as null | string, []],
    wwymDriveUrl: [null as null | string, []],
    clientNotesUrl: [null as null | string, []],
  });

  get HistoryTypeLookup() {
    return HistoryTypeLookup;
  }

  get HistoryTypeEnum() {
    return HistoryTypeEnum;
  }

  get dailyRoutineUrl() {
    return this.notesForm.controls.dailyRoutineUrl.value;
  }

  get wwymDriveUrl() {
    return this.notesForm.controls.wwymDriveUrl.value;
  }

  get clientNotesUrl() {
    return this.notesForm.controls.clientNotesUrl.value;
  }

  get dailyRoutineRedirectUrl() {
    return (this.account?.dailyRoutineUrl?.trim().startsWith('http://') || this.account?.dailyRoutineUrl?.trim().startsWith('https://')) ? this.account?.dailyRoutineUrl?.trim() : `http://${this.account?.dailyRoutineUrl?.trim()}`;
  }

  get wwymDriveRedirectUrl() {
    return (this.account?.wwymDriveUrl?.trim().startsWith('http://') || this.account?.wwymDriveUrl?.trim().startsWith('https://')) ? this.account?.wwymDriveUrl?.trim() : `http://${this.account?.wwymDriveUrl?.trim()}`;
  }

  get clientNotesRedirectUrl() {
    return (this.account?.clientNotesUrl?.trim().startsWith('http://') || this.account?.clientNotesUrl?.trim().startsWith('https://')) ? this.account?.clientNotesUrl?.trim() : `http://${this.account?.clientNotesUrl?.trim()}`;
  }

  get confidentiality() {
    return this.notesForm.controls.confidentiality.value;
  }

  get isNoteSharable() {
    return this.notesForm.controls.notesType.value == HistoryTypeEnum.Parent || this.notesForm.controls.notesType.value == HistoryTypeEnum.Quote ||
      this.notesForm.controls.notesType.value == HistoryTypeEnum.Gcs || this.notesForm.controls.notesType.value == HistoryTypeEnum.Som || this.notesForm.controls.notesType.value == HistoryTypeEnum.Col;
  }
  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private historyService: HistoryService,
    private accountService: AccountService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private internalUserService: InternalUserService,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.internalUserService.Mentor$.subscribe(user => {
      this.mentor = user;
    }));

    if (this.accountId) {
      this.subscriptions.push(this.accountService.getAccount(this.accountId).subscribe((account) => {
        this.account = account;
        this.mentee = getMentee(account);
        this.accountCreator = getAccountCreator(account);

        if (account) {
          this.notesForm.controls.dailyRoutineUrl.setValue(account.dailyRoutineUrl);
          this.notesForm.controls.wwymDriveUrl.setValue(account.wwymDriveUrl);
          this.notesForm.controls.clientNotesUrl.setValue(account.clientNotesUrl);
        }
      }));
    }
  }

  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.DAILY_ROUTINE_MODEL);
    this.modalService.initialize(ModalConstant.WWYM_DRIVE_MODAL);
    this.modalService.initialize(ModalConstant.CLIENT_NOTES_MODAL);
  }

  openDailyRoutineModal() {
    if (this.account) {
      this.DailyRoutineModalComponent.showDailyRoutineModal(this.account.dailyRoutineUrl);
    }
  }

  openWwymDriveModal() {
    if (this.account) {
      this.WwymDriveModalComponent.showWwymDriveModal(this.account.wwymDriveUrl);
    }
  }

  openClientNotesUrlModal() {
    if (this.account) {
      this.ClientNotesUrlModalComponent.showClientNotesModal(this.account.clientNotesUrl);
    }
  }

  changeNotesType(event: Event) {
    const value = parseInt((event.target as HTMLInputElement).value);
    this.noteSelectionChanged(value);
  }

  onConfidentialityChanged(value: boolean) {
    if (this.notesForm) {
      this.notesForm.controls.confidentiality.setValue(value);

      if (value) {
        this.notesForm.controls.visibleToMentee.setValue(false);
      }
    }
  }

  isConfidential(historyType: HistoryTypeEnum): boolean {
    switch (historyType) {
      case HistoryTypeEnum.Parent:
      case HistoryTypeEnum.Quote:
      case HistoryTypeEnum.Gcs:
      case HistoryTypeEnum.Som:
      case HistoryTypeEnum.Col:
        return this.notesForm.controls.confidentiality.value ?? true;
      case HistoryTypeEnum.System:
      case HistoryTypeEnum.Consultant:
        return false;
      case HistoryTypeEnum.Office:
        return true;
    }
  }

  noteSelectionChanged(value: HistoryTypeEnum) {
    this.notesForm.controls.notesType?.setValue(value, {
      onlySelf: true,
    });

    switch (value) {
      case HistoryTypeEnum.Office:
        this.notesForm.controls.notes.setValue("");
        break;
      case HistoryTypeEnum.Parent:
        this.notesForm.controls.confidentiality?.setValue(true);
        this.notesForm.controls.visibleToParent?.setValue(false);
        this.notesForm.controls.visibleToMentee?.setValue(false);
        this.notesForm.controls.notes.setValue("");
        break;
      case HistoryTypeEnum.Quote:
        this.notesForm.controls.confidentiality?.setValue(false);
        this.notesForm.controls.visibleToParent?.setValue(false);
        this.notesForm.controls.visibleToMentee?.setValue(true);
        this.notesForm.controls.notes.setValue("");
        break;
      case HistoryTypeEnum.Gcs:
        this.notesForm.controls.confidentiality?.setValue(false);
        this.notesForm.controls.visibleToParent?.setValue(false);
        this.notesForm.controls.visibleToMentee?.setValue(false);

        this.subscriptions.push(this.http.get('/mentor/assets/templates/gcs.txt', { responseType: 'text' }).subscribe(res => {
          this.notesForm.controls.notes.setValue(res as string);
        }));
        break;
      case HistoryTypeEnum.Som:
        this.notesForm.controls.confidentiality?.setValue(true);
        this.notesForm.controls.visibleToParent?.setValue(false);
        this.notesForm.controls.visibleToMentee?.setValue(false);

        this.subscriptions.push(this.http.get('/mentor/assets/templates/som.txt', { responseType: 'text' }).subscribe(res => {
          this.notesForm.controls.notes.setValue(res as string);
        }));
        break;
      case HistoryTypeEnum.Col:
        this.notesForm.controls.confidentiality?.setValue(false);
        this.notesForm.controls.visibleToParent?.setValue(false);
        this.notesForm.controls.visibleToMentee?.setValue(false);

        this.subscriptions.push(this.http.get('/mentor/assets/templates/col.txt', { responseType: 'text' }).subscribe(res => {
          this.notesForm.controls.notes.setValue(res as string);
        }));
        break;
    }
  }

  saveNotes() {
    const historyType = this.notesForm.controls.notesType.value ?? null;
    if (this.account?.id == null || this.notesForm.controls.notes.value == null || historyType == null) return;

    const historyItemId = uuid();
    const historyAttachments: HistoryAttachment[] = [];
    const historyAccess: HistoryAccess[] = [];

    if (this.isNoteSharable) {
      if (this.notesForm.controls.visibleToParent && this.accountCreator) {
        historyAccess.push({
          id: uuid(),
          historyItemId: historyItemId,
          externalUserId: this.accountCreator.id
        })
      }

      if (this.notesForm.controls.visibleToMentee && this.mentee) {
        historyAccess.push({
          id: uuid(),
          historyItemId: historyItemId,
          externalUserId: this.mentee.id
        })
      }
    }

    if (this.selectedFiles.length == 0) {
      const request: AddAccountHistoryRequest = {
        historyItem: <HistoryItem>{
          id: historyItemId,
          note: this.notesForm.controls.notes.value,
          historyTypeId: historyType,
          creationDate: this.currentDate,
          accountId: this.account?.id,
          createdById: this.mentor?.id ?? null,
          isConfidential: this.isConfidential(historyType)
        },
        historyAccess: historyAccess
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.historyService.addNotes(request, loaderIdentifier)
        .subscribe(() => {
          this.loaderService.hide(loaderIdentifier);
          this.selectedFiles = [];
          if (this.fileInputRef)
            this.fileInputRef.nativeElement.value = null;
          this.notesForm.controls.notes.reset();
          if (this.accountId) {
            this.historyService.getHistoryItemsByAccount(this.accountId);
          }
        }));

    } else {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
          const fileContent = fileReader.result as string;
          const base64String = fileContent.split(',')[1];

          historyAttachments.push(<HistoryAttachment>{
            id: uuid(),
            content: base64String,
            filename: file.name,
            historyItemId: historyItemId
          });

          if (i === this.selectedFiles.length - 1) {
            const request: AddAccountHistoryRequest = {
              historyItem: <HistoryItem>{
                id: historyItemId,
                note: this.notesForm.controls.notes.value,
                historyTypeId: historyType,
                creationDate: this.currentDate,
                accountId: this.account?.id,
                createdById: this.mentor?.id ?? null,
                historyAttachments: historyAttachments,
                isConfidential: this.isConfidential(historyType)
              },
              historyAccess: historyAccess
            };

            const loaderIdentifier = uuid();
            this.subscriptions.push(this.historyService.addNotes(request, loaderIdentifier)
              .subscribe(() => {
                this.loaderService.hide(loaderIdentifier);
                this.selectedFiles = [];
                if (this.fileInputRef)
                  this.fileInputRef.nativeElement.value = null;
                this.notesForm.controls.notes.reset();
                if (this.accountId) {
                  this.historyService.getHistoryItemsByAccount(this.accountId);
                }
              }));
          }
        };
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { InternalUserService } from '../../services/internal-user.service';
import { InternalUser, InternalUserClaimTypes, InternalUserRoles } from '@mya/models';
import { AuthenticationService } from '../../services/authentication.service';
import { JwtService } from '../../services/jwt.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { initializeDarkTheme } from '@mya/xtreme-template';
import { PlanPricingService } from '../../services/plan-pricing.service';
import { BookingService } from '@mya/booking-shared';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  loginDisplay = false;
  overriddenDate: Date | null = null;
  mentor: InternalUser | null = null;
  subscriptions: Subs[] = [];
  url: string | null = null;
  roles: any;

  get name() {
    return this.mentor ? `${this.mentor.firstName} ${this.mentor.lastName}` : '';
  }

  get email() {
    return this.mentor?.emailAddress ?? '';
  }
  get isSuperAdmin() {
    return (Array.isArray(this.roles) && this.roles.some(i => i === InternalUserRoles.Admin)) || this.roles === InternalUserRoles.Admin;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  get isOverridden() {
    return this.overriddenDate != null;
  }

  constructor(private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private internalUserService: InternalUserService,
    private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private referenceDataService: ReferenceDataService,
    private planPricingService: PlanPricingService,
    private bookingService: BookingService,
    dateTimeService: DateTimeService,
    public router: Router) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.subscriptions.push(router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.url = value.url;
      }
    }));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.internalUserService.Mentor$.subscribe(user => {
      this.mentor = user
      if (this.mentor?.bookingStaffId) {
        this.bookingService.GetStaffMember(this.mentor.bookingStaffId);
      }
    }));

    this.subscriptions.push(this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      }));
  }

  ngAfterViewInit(): void {
    initializeDarkTheme();
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
      this.subscriptions.push(this.authenticationService.getToken().subscribe(token => {
        const decodedToken: any = this.jwtService.DecodeToken(token);
        this.roles = decodedToken[InternalUserClaimTypes.Roles];
        const userId = decodedToken[InternalUserClaimTypes.UserId] as string;
        this.internalUserService.getMentor(userId);
        this.referenceDataService.fetchReferenceData();
        this.referenceDataService.getTimeZoneInfo(Intl.DateTimeFormat().resolvedOptions().timeZone);
        this.planPricingService.fetchPlanPricings();
      }));
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { CalendarEvent, GetCalendarEventsByUserResult, GetInternalUserByEmailResult, GetInternalUserByIdResult, InternalUser } from '@mya/models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG } from "@mya/configuration";
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { DatePipe } from '@angular/common';
import { Subscription as Subs } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InternalUserService implements OnDestroy {
    baseApiUrl = '';
    subscriptions: Subs[] = [];

    private readonly mentor = new BehaviorSubject<InternalUser | null>(null);
    public readonly Mentor$: Observable<InternalUser | null> = this.mentor;

    private readonly calendarEvents = new BehaviorSubject<CalendarEvent[]>([]);
    public readonly CalendarEvents$: Observable<CalendarEvent[]> = this.calendarEvents;

    constructor(private http: HttpClient, private loaderService: LoaderService, private datePipe: DatePipe,
        @Inject(APP_CONFIG) appConfig: any) {
        this.baseApiUrl = `${appConfig.apiUrls.internalUser}api/internalUser`
    }

    getMentor(userId: string) {
        const loaderIdentifier = uuid();
        this.subscriptions.push(this.GetUserById(userId, loaderIdentifier)
            .subscribe(data => {
                this.mentor.next(data.user);
                this.loaderService.hide(loaderIdentifier);
            }));
    }

    private GetUserById(userId: string, loaderIdentifier: string): Observable<GetInternalUserByIdResult> {
        this.loaderService.show(loaderIdentifier);
        return this.http.get<GetInternalUserByIdResult>(`${this.baseApiUrl}/${userId}`)
            .pipe(catchError((error: HttpErrorResponse) => {
                this.loaderService.hide(loaderIdentifier);
                throw error;
            }));
    }

    getCalendarEventsByUser(emailAddress: string, startDate: Date, endDate: Date): void {
        const loaderIdentifier = uuid();
        const formattedStartDate = this.datePipe.transform(new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000), 'YYYY-MM-ddTHH:mm:ss');
        const formatterdEndDate = this.datePipe.transform(new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000), 'YYYY-MM-ddTHH:mm:ss')
        this.loaderService.show(loaderIdentifier);
        this.subscriptions.push(this.http.get<GetCalendarEventsByUserResult>(`${this.baseApiUrl}/get-calendar-events-by-user/${formattedStartDate}/${formatterdEndDate}`, { params: { email: encodeURIComponent(emailAddress) } })
            .pipe(catchError((error: HttpErrorResponse) => {
                this.loaderService.hide(loaderIdentifier);
                throw error;
            }))
            .subscribe(data => {
                this.calendarEvents.next(data.events);
                this.loaderService.hide(loaderIdentifier);
            }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}

<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex border-bottom title-part-padding align-items-center">
          <div>
            <h4 class="card-title mb-0">MIT Coaching Hours</h4>
          </div>
        </div>

        <mya-coaching-hours-editor (refreshRecords)="loadDataSource()"
          (removeSelection)="removeSelection()"></mya-coaching-hours-editor>

        <dx-data-grid #dataGrid class="m-2" [dataSource]="dataSource" [showBorders]="true" [remoteOperations]="true"
          [rowAlternationEnabled]="true" [hoverStateEnabled]="true" (selectedRowKeysChange)="selectionChanged($event)">

          <dxi-column dataField="date" caption="Date" dataType="date"> </dxi-column>
          <dxi-column dataField="mitFirstName" caption="MIT First Name" dataType="string"> </dxi-column>
          <dxi-column dataField="mitLastName" caption="MIT Last Name" dataType="string"> </dxi-column>
          <dxi-column dataField="module" caption="Module" dataType="number"> </dxi-column>
          <dxi-column dataField="lmp" caption="LMP" dataType="number"> </dxi-column>
          <dxi-column [calculateCellValue]="fetchCoachingHourType" caption="Coaching Type"
            dataType="string"></dxi-column>

          <dxo-editing mode="row" [allowDeleting]="true">
          </dxo-editing>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
          <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
          <dxo-paging [pageSize]="10"> </dxo-paging>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
        </dx-data-grid>
      </div>
    </div>
  </div>
</div>
<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Appointment Cancellation
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ng-container *ngIf="!appointmentCancelReason; else cancelReason">
                <div class="modal-body pt-0 p-4">
                    <h5 class="mt-0">Please select a reason</h5>
                    <div class="row">
                        <div class="col-6 text-center py-2">
                            <button type="button" class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                                (click)="onAppointmentCancelReasonSelected('No call, no show, charged', false)">No
                                call,
                                no show, charged</button>
                        </div>
                        <div class="col-6 text-center py-2">
                            <button type="button" class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                                (click)="onAppointmentCancelReasonSelected('No call, no show, not charged', true)">No
                                call, no show, not charged</button>
                        </div>
                        <div class="col-6 text-center py-2">
                            <button type="button" class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                                (click)="onAppointmentCancelReasonSelected('Cancel same day, charged', false)">Cancel
                                same day, charged</button>
                        </div>
                        <div class="col-6 text-center py-2">
                            <button type="button" class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                                (click)="onAppointmentCancelReasonSelected('Cancel same day, not charged', true)">Cancel
                                same day, not charged</button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #cancelReason>
                <form [formGroup]="appointmentCancelForm" (ngSubmit)="cancelAppointment()">
                    <div class="modal-body pt-0 p-4">
                        <h5 class="mt-0">Please select a reason</h5>
                        <div class="row">
                            <div class="col-12 mb-3" formGroupName="appointmentCancelReason">
                                <div class="form-check"
                                    *ngFor="let appointmentCancelReason of appointmentCancelReasons">
                                    <input class="form-check-input" type="checkbox" [id]="appointmentCancelReason.id"
                                        [formControlName]="appointmentCancelReason.id"
                                        (change)="onAppointmentCancelReasonChanged($event, appointmentCancelReason)">
                                    <label class="form-check-label" [for]="appointmentCancelReason.id">
                                        {{appointmentCancelReason.label}}
                                    </label>
                                </div>
                            </div>

                            <div class="col-12 mb-3" *ngIf="showAppointmentCancelComments">
                                <textarea class="form-control" rows="3" placeholder="Please enter reason."
                                    formControlName="appointmentCancelReasonOther"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="submit" class="btn btn-light-primary text-primary font-medium"
                            [disabled]="appointmentCancelForm.invalid">
                            Submit
                        </button>
                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</div>
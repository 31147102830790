import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportTypes, ReportTypesLookup } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Subscription as Subs } from 'rxjs';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import * as IMask from 'imask';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';

@Component({
  selector: 'mya-report-failed-payment',
  templateUrl: './report-failed-payment.component.html',
  styleUrls: ['./report-failed-payment.component.scss'],
})
export class ReportFailedPaymentComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid!: DxDataGridComponent;
  @Input() dateRange: [Date, Date];
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  dataSource: any[] | null = null;
  phoneMask = IMask.createMask({
    mask: '(000) 000-0000'
  })

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private reportsService: ReportsService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.dateRange = [this.currentDate, this.currentDate];
  }

  ngOnInit(): void {
    this.loadDataSource(this.dateRange[0], this.dateRange[1]);
  }

  loadDataSource(startDate: Date, endDate: Date) {
    this.subscriptions.push(this.reportsService.getReports(ReportTypes.FailedPayment, startDate, endDate, null)
      .subscribe((result: any) => {
        this.dataSource = result.result.data;
      }));
  }

  fetchParentPhoneNumber = (data: any) => {
    return data.parentCountryCode == 1 ? `+${data.parentCountryCode} ${this.phoneMask.resolve(data.parentPhoneNumber)}` : `+${data.parentCountryCode} ${data.parentPhoneNumber}`;
  }

  onExporting() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet');

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.grid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${ReportTypesLookup[ReportTypes.FailedPayment]} Report.xlsx`);
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

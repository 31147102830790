import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { UpdateDailyRoutineForAccountRequest } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../services/loader.service';
import { ModalConstant } from '../../common/constants/modal.constant';
import { ModalService } from '../../services/modal.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-daily-routine-modal',
  templateUrl: './daily-routine-modal.component.html',
  styleUrls: ['./daily-routine-modal.component.scss'],
})
export class DailyRoutineModalComponent implements OnDestroy {
  @Input() accountId: string | null = null;
  subscriptions: Subs[] = [];
  elementId = ModalConstant.DAILY_ROUTINE_MODEL;
  isEdit = false;

  dailyRoutineForm = this.formBuilder.group({
    link: [null as null | string, [Validators.required]]
  });

  constructor(private accountService: AccountService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private formBuilder: FormBuilder) { }

  public showDailyRoutineModal(link: string | null) {
    this.isEdit = link != null;
    this.dailyRoutineForm.controls.link.setValue(link);
    this.modalService.show(ModalConstant.DAILY_ROUTINE_MODEL);
  }

  onSubmit() {
    if (this.dailyRoutineForm.controls.link.value && this.accountId) {
      const request: UpdateDailyRoutineForAccountRequest = {
        accountId: this.accountId,
        dailyRoutine: this.dailyRoutineForm.controls.link.value
      }

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.accountService.updateDailyRoutine(request, loaderIdentifier).subscribe(() => {
        if (this.accountId)
          this.accountService.getAccount(this.accountId, true);
        this.loaderService.hide(loaderIdentifier);
        this.modalService.hide(ModalConstant.DAILY_ROUTINE_MODEL);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AppointmentBlock, DayOfWeek, DayOfWeekLookup, ExternalUser } from '@mya/models';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-block-scheduler-appointment-block-card',
  templateUrl: './block-scheduler-appointment-block-card.component.html',
  styleUrls: ['./block-scheduler-appointment-block-card.component.scss'],
})
export class BlockSchedulerAppointmentBlockCardComponent implements OnDestroy {
  @Output() edit = new EventEmitter<AppointmentBlock>();
  @Output() delete = new EventEmitter<AppointmentBlock>();
  @Input() appointmentBlock: AppointmentBlock | null = null
  @Input() selectedAppointmentBlock: AppointmentBlock | null = null
  @Input() mentee: ExternalUser | null = null;
  @Input() utcTimeZoneDiff = 0;
  @Input() readonly = false;
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private datePipe: DatePipe,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  getAppointmentIntervalStartDate(appointmentBlock: AppointmentBlock) {
    const intervalStartDate = appointmentBlock.intervalStartDate != null ? new Date(appointmentBlock.intervalStartDate).addMinutes(this.utcTimeZoneDiff) : null;
    return intervalStartDate != null ? `Start date: ${this.datePipe.transform(intervalStartDate, 'shortDate')}` : '';
  }

  getAppointmentBlockDay(appointmentBlock: AppointmentBlock) {
    let date = this.currentDate;
    let selectedDate: Date | null = null;

    while (selectedDate == null) {
      if (date.getDay() == appointmentBlock.day) {
        selectedDate = date
        break;
      }

      date = date.addDays(1);
    }

    selectedDate = selectedDate.generateDate(parseInt(appointmentBlock.startTime.split(":")[0]), parseInt(appointmentBlock.startTime.split(":")[1]), parseInt(appointmentBlock.startTime.split(":")[2]));
    selectedDate = selectedDate.addMinutes(this.utcTimeZoneDiff);
    return DayOfWeekLookup[selectedDate.getDay() as DayOfWeek];
  }

  getAppointmentBlockTime(appointmentBlock: AppointmentBlock) {
    let date = this.currentDate;
    let selectedDate: Date | null = null;

    while (selectedDate == null) {
      if (date.getDay() == appointmentBlock.day) {
        selectedDate = date
        break;
      }

      date = date.addDays(1);
    }

    selectedDate = selectedDate.generateDate(parseInt(appointmentBlock.startTime.split(":")[0]), parseInt(appointmentBlock.startTime.split(":")[1]), parseInt(appointmentBlock.startTime.split(":")[2]));
    selectedDate = selectedDate.addMinutes(this.utcTimeZoneDiff);
    return `${this.datePipe.transform(selectedDate, 'shortTime')} - ${this.datePipe.transform(selectedDate.addMinutes(appointmentBlock.duration), 'shortTime')}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import { Component, OnInit } from '@angular/core';
import { MentorService } from '../../services/mentor.service';
import { Account, AccountStatus, AccountStatusLookup, getDataSourceLoaderParams } from '@mya/models';
import CustomStore from 'devextreme/data/custom_store';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'mya-mentees',
  templateUrl: './mentees.component.html',
  styleUrls: ['./mentees.component.scss'],
})
export class MenteesComponent implements OnInit {
  assignedAccounts: Account[] = [];
  dataSource: CustomStore | null = null;
  applyFilterTypes: any;
  currentFilter: any;
  showFilterRow: boolean;
  showHeaderFilter: boolean;

  get AccountStatusLookup() {
    return AccountStatusLookup;
  }

  constructor(private router: Router, private mentorService: MentorService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;
    this.showFilterRow = true;
    this.showHeaderFilter = true;
  }
  
  ngOnInit(): void {
    this.loadDataSource();
  }

  loadDataSource() {
    const mentorService = this.mentorService;
    this.dataSource = new CustomStore({
      key: 'accountId',
      load(loadOptions: any) {
        return lastValueFrom(mentorService.getAssignedMentees(getDataSourceLoaderParams(loadOptions)))
          .then((result: any) => ({
            data: result.data.data,
            totalCount: result.data.totalCount,
            summary: result.data.summary,
            groupCount: result.data.groupCount,
          }))
          .catch((error: any) => { throw error; });
      },
    });
  }

  rowSelected(event: any) {
    if (event.selectedRowKeys.length > 0) {
      this.router.navigate(['/mentee-detail'], { queryParams: { id: event.selectedRowKeys[0] } });
    }
  }

  getAccountStatus(accountStatus: AccountStatus | null) {
    return accountStatus == null ? null : AccountStatusLookup[accountStatus];
  }

  fetchAccountStatus(data: any) {
    return AccountStatusLookup[data.accountStatus as AccountStatus];
  }

  fetchParentName(data: any) {
    return `${data.parentFirstName} ${data.parentLastName}`;
  }
}
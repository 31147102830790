<div class="d-flex align-items-end m-2">
    <h3 class="mt-4 flex-grow-1">Client</h3>

    <button type="button" class="mt-1 btn waves-effect waves-light btn-info btn-export" (click)="onExporting()">
        Export
    </button>
</div>
<dx-data-grid #grid class="m-2" [dataSource]="dataSource" [showBorders]="true" [rowAlternationEnabled]="true"
    [hoverStateEnabled]="true">

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxi-column dataField="menteeName" caption="Mentee Name" dataType="string"> </dxi-column>
    <dxi-column dataField="parentName" caption="Parent Name" dataType="string"> </dxi-column>
    <dxi-column dataField="mentorName" caption="Mentor Name" dataType="string"> </dxi-column>
    <dxi-column dataField="planName" caption="Plan Name" dataType="string"> </dxi-column>
    <dxi-column dataField="paymentDueDate" caption="Payment Due Date" dataType="date"> </dxi-column>
    <dxi-column dataField="paymentAttempts" caption="Payment Attempts" dataType="string"> </dxi-column>
    <dxi-column [calculateCellValue]="fetchParentPhoneNumber" caption="Parent Phone Number" dataType="string">
    </dxi-column>
    <dxi-column dataField="parentEmail" caption="Parent Email" dataType="string"> </dxi-column>
    <dxi-column dataField="mentorEmail" caption="Mentor Email" dataType="string"> </dxi-column>
    <dxi-column dataField="menteeEmail" caption="Mentee Email" dataType="string"> </dxi-column>

    <dxo-summary>
        <dxi-total-item column="parentEmail" summaryType="count" displayFormat="Accounts: {0}" showInColumn="menteeName"> </dxi-total-item>
        <dxi-total-item column="paymentAttempts" summaryType="sum" showInColumn="parentName"
            displayFormat="Payment Attempts: {0}"></dxi-total-item>
    </dxo-summary>
</dx-data-grid>
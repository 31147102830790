<div class="card mb-0">
    <div class="card-body pb-0">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="d-flex align-items-center">
                <div class="p-2 flex-grow-1 bd-highlight"></div>

                <div class="mb-3 me-5">
                    <label for="coaching-type">Report Type</label>
                    <select id="coaching-type" class="form-select" formControlName="reportType"
                        (change)="changeReportType($event)">
                        <ng-container *ngFor="let reportType of reportTypes">
                            <option [value]="reportType">
                                {{ ReportTypesLookup[reportType] }}
                            </option>
                        </ng-container>
                    </select>
                </div>

                <div class="mb-3 me-5" *ngIf="form.controls.reportType.value == ReportTypes.MentorPerformance">
                    <label for="mentor">Mentor</label>
                    <select id="mentor" class="form-select" formControlName="mentor">
                        <ng-container *ngFor="let mentor of mentors">
                            <option [value]="mentor.id">
                                {{ mentor.firstName }} {{ mentor.lastName }}
                            </option>
                        </ng-container>
                    </select>
                </div>

                <div class="mb-3 me-5">
                    <label for="date">Date Range</label>
                    <mya-date-range-picker formControlName="dateRange"></mya-date-range-picker>
                </div>

                <button class="mt-1 btn waves-effect waves-light btn-success" [disabled]="!form.valid">
                    Generate
                </button>

                <div class="p-2 flex-grow-1 bd-highlight"></div>
            </div>
        </form>
    </div>
</div>
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportTypes, ReportTypesLookup } from '@mya/models';
import { ReportsService } from '../../../../services/reports.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { Workbook, Worksheet } from 'exceljs';
import * as saveAs from 'file-saver';
import { DateTimeService } from 'apps/mentor/src/app/services/date-time.service';
import { Subscription as Subs } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'mya-report-mentee-appointment-cancellation',
  templateUrl: './report-mentee-appointment-cancellation.component.html',
  styleUrls: ['./report-mentee-appointment-cancellation.component.scss'],
})
export class ReportMenteeAppointmentCancellationComponent
  implements OnInit, OnDestroy
{
  @ViewChild('grid') grid!: DxDataGridComponent;
  @ViewChild('summaryGrid') summaryGrid!: DxDataGridComponent;
  @Input() dateRange: [Date, Date];

  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  dataSource: any[] | null = null;
  summaryDataSource: any[] | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(
    private reportsService: ReportsService,
    dateTimeService: DateTimeService,
    private datePipe: DatePipe) {
    this.subscriptions.push(
      dateTimeService.OverriddenDate$.subscribe(
        (date) => (this.overriddenDate = date)
      )
    );
    this.dateRange = [this.currentDate, this.currentDate];
  }

  ngOnInit(): void {
    this.loadDataSource(this.dateRange[0], this.dateRange[1]);
  }

  loadDataSource(startDate: Date, endDate: Date) {
    this.subscriptions.push(
      this.reportsService
        .getReports(
          ReportTypes.MenteeAppointmentCancellation,
          startDate,
          endDate,
          null
        )
        .subscribe((result: any) => {
          this.dataSource = result.result.data;
          this.summaryDataSource = this.loadSummaryDataSource(
            result.result.data
          );
        })
    );
  }

  loadSummaryDataSource(records: any[]) {
    const filteredRecords: any[] = [];
    for (const record of records) {
      if (
        filteredRecords.findIndex(
          (i) =>
            i.mentorName == record.mentorName &&
            i.cancellationType == record.cancellationType
        ) == -1
      ) {
        filteredRecords.push({
          mentorName: record.mentorName,
          cancellationType: record.cancellationType,
          total: records.filter(
            (i) =>
              i.cancellationType == record.cancellationType &&
              i.mentorName == record.mentorName
          ).length,
        });
      }
    }
    return filteredRecords;
  }

  onExporting() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet');
    const summaryRow = worksheet.insertRow(1, [
      'Cancellations & Rescheduling By Mentee',
    ]);
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.grid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 },
    }).then(() => {
      this.exportSummaryGrid(worksheet, workbook);
    });
  }

  exportSummaryGrid(worksheet: Worksheet, workbook: Workbook) {
    const summaryRow = worksheet.insertRow(
      (worksheet.lastRow?.number ?? 1) + 2,
      ['Cancellation & Rescheduling Summary']
    );
    summaryRow.getCell(1).font = { size: 15, bold: true };
    summaryRow.height = 20;

    exportDataGrid({
      worksheet,
      autoFilterEnabled: false,
      component: this.summaryGrid.instance,
      topLeftCell: { row: (worksheet.lastRow?.number ?? 1) + 1, column: 1 },
    }).then(() => {
      worksheet.columns.forEach((column) => {
        column.width = 30;
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${
            ReportTypesLookup[ReportTypes.MenteeAppointmentCancellation]
          } Report.xlsx`
        );
      });
    });
  }

  calculateTimeZoneOffset = (data: any) => {
    return this.datePipe.transform(new Date(data.appointmentDate).getUTCFullDateInverse(), 'M/d/yyyy, h:mm a');
  };

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

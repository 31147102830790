<div class="mya-change-plan" *ngIf="!isAppointmentBlockWindow">

    <div class="row mya-change-plan-container">

        <ng-container *ngIf="pendingSubscription && !editChanges; else elseBlock">
            <form [formGroup]="timeZoneForm">
                <div class="row">
                    <div class="col-4 mb-2 align-self-center" [ngClass]="isTimeZoneSame ? 'd-none' : 'd-block'">
                        <h4>Time zone</h4>
                    </div>
                    <div class="col-8 mb-2 text-center" [ngClass]="isTimeZoneSame ? 'd-none' : 'd-block'">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input primary check-outline outline-primary" type="radio"
                                id="mentee" name="timeZone" formControlName="timeZone" value="mentee">
                            <label class="form-check-label" for="mentee">Mentee</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input primary check-outline outline-primary" type="radio"
                                id="mentor" name="timeZone" formControlName="timeZone" value="mentor">
                            <label class="form-check-label" for="mentor">Mentor</label>
                        </div>
                    </div>

                    <ng-container *ngIf="activeSubscription">
                        <div class="col-md-12">
                            <div class="card mb-2">
                                <div class="card-body">
                                    <h4>Current Plan</h4>
                                    <h5 class="card-title mb-0">{{currentPlan}}</h5>
                                </div>
                            </div>
                        </div>

                        <div class="col-12" *ngIf="sortedCurrentAppointmentBlocks.length > 0">
                            <ng-container *ngFor="let appointmentBlock of sortedCurrentAppointmentBlocks">
                                <mya-block-scheduler-appointment-block-card [appointmentBlock]="appointmentBlock"
                                    [utcTimeZoneDiff]="utcTimeZoneDiff" [readonly]="true"
                                    [mentee]="mentee"></mya-block-scheduler-appointment-block-card>
                            </ng-container>
                        </div>

                        <div class="col-md-12">
                            <hr />
                        </div>
                    </ng-container>

                    <div class="col-md-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4>Next Plan</h4>
                                <h5 class="card-title">{{pendingPlan}}</h5>
                                <h6 class="card-subtitle mb-0 text-muted d-flex align-items-center">Effective from:
                                    {{PendingPlanEffectiveDate | date: 'shortDate'}}</h6>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="sortedPendingAppointmentBlocks.length > 0">
                        <ng-container *ngFor="let appointmentBlock of sortedPendingAppointmentBlocks">
                            <mya-block-scheduler-appointment-block-card [appointmentBlock]="appointmentBlock"
                                [utcTimeZoneDiff]="utcTimeZoneDiff" [readonly]="true"
                                [mentee]="mentee"></mya-block-scheduler-appointment-block-card>
                        </ng-container>
                    </div>
                </div>
            </form>


        </ng-container>

        <ng-template #elseBlock>
            <p>{{isAppointmentBlockWindow ? 'Select Appointment Block' : 'Select Plan'}}</p>
            <div class="row overflow-y-auto">
                <div class="col-12">
                    <form [formGroup]="planForm">
                        <select class="form-select" formControlName="mentoringPlan">
                            <option value="" disabled selected>Select Plan</option>
                            <ng-container *ngFor="let mentoringSystem of plansAvailable">
                                <option [value]="mentoringSystem.id">
                                    {{ mentoringSystem.label }}
                                </option>
                            </ng-container>
                        </select>
                    </form>
                </div>
            </div>
        </ng-template>
    </div>


    <div class="mt-auto">
        <div class="col-12 mt-3"
            [ngClass]="!pendingSubscription || editChanges  ? 'bottom-cancel-button' : 'bottom-cancel-button-edit'">
            <button type="button" class="btn waves-effect waves-light btn-primary w-100 "
                data-bs-dismiss="offcanvas">Cancel</button>
        </div>
        <div class="col-12 mt-3">
            <button type="button" class="btn waves-effect waves-light btn-success w-100" [disabled]="!planForm.valid"
                *ngIf="!pendingSubscription || editChanges; else elseButtonBlock"
                (click)="selectAppointmentBlock()">Next</button>
            <ng-template #elseButtonBlock>
                <button type="button" class="btn waves-effect waves-light btn-danger w-100 mb-3"
                    (click)="deletePendingPlan()">Delete
                    Changes</button>
                <button type="button" class="btn waves-effect waves-light btn-success w-100"
                    (click)="editPendingPlan()">Edit
                    Changes</button>
            </ng-template>
        </div>
    </div>
</div>

<mya-block-scheduler [accountId]="accountId"
    *ngIf="accountId && isAppointmentBlockWindow && planForm.controls.mentoringPlan.value"
    [mentoringPlanId]="planForm.controls.mentoringPlan.value" [subscription]="pendingSubscription"
    (save)="saveChanges($event)"></mya-block-scheduler>
<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex border-bottom title-part-padding align-items-center">
          <div>
            <h4 class="card-title mb-0">Mentees</h4>
          </div>
        </div>

        <dx-data-grid class="m-2" [dataSource]="dataSource" [showBorders]="true"
          [rowAlternationEnabled]="true" [hoverStateEnabled]="true" (onSelectionChanged)="rowSelected($event)">

          <dxi-column dataField="firstName" dataType="string"> </dxi-column>
          <dxi-column dataField="lastName" dataType="string"> </dxi-column>
          <dxi-column dataField="emailAddress" dataType="string"> </dxi-column>
          <dxi-column dataField="dateOfBirth" dataType="date"> </dxi-column>
          <dxi-column caption="Parent Name" [calculateCellValue]="fetchParentName"> </dxi-column>
          <dxi-column caption="Account Status" [calculateCellValue]="fetchAccountStatus"></dxi-column>

          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
          <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
          <dxo-paging [pageSize]="50"> </dxo-paging>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
        </dx-data-grid>

      </div>
    </div>
  </div>
</div>
<div class="modal fade mya-daily-routine-modal" [id]="elementId" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <form [formGroup]="wwymDriveForm" (ngSubmit)="onSubmit()">
                <div class="modal-header modal-colored-header bg-primary text-white">
                    <h4 class="modal-title">
                        Wwym Drive
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="mt-1">
                        {{isEdit ? 'Edit' : 'Add'}} your Google drive link for wwym drive
                    </p>
                    <div class="input-group">
                        <span class="input-group-text"><i class="fas fa-link"></i></span>
                        <input type="text" class="form-control" placeholder="Wwym Drive URL" formControlName="link">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="submit" class="btn btn-light-primary text-primary font-medium"
                        [disabled]="wwymDriveForm.invalid">
                        Save changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>